'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var getErrorMessage = exports.getErrorMessage = function getErrorMessage(error) {
  if (error === null || error === undefined) {
    return '';
  }
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Ogiltig mailadress.';
    case 'auth/weak-password':
      return 'Lösenordet är för svagt.';
    case 'auth/wrong-password':
      return 'Felaktig emailadress och/eller lösenord.';
    case 'auth/user-not-found':
      return 'Användaren kunde inte hittas.';
    case 'auth/invalid-api-key':
      return 'Fel inträffade. Kontakta support.';
    case 'auth/invalid-user-token':
      return 'Sessionen har gått ut. Vänligen logga in igen.';
    case 'auth/network-request-failed':
      return 'Servern kunde inte nås. Vänligen kontrollera din internetanslutning.';
    case 'auth/operation-not-allowed':
      return 'Du saknar behörighet för att utföra dessa ändringar.';
    case 'auth/requires-recent-login':
      return 'Din session behöver förnyas. Vänligen logga ut och in igen.';
    case 'auth/too-many-requests':
      return 'Oj, nu gick det lite fort.. Vänligen vänta och försök igen.';
    case 'auth/unauthorized-domain':
      return 'Domänen saknar behörighet. Kontakta support.';
    case 'auth/user-disabled':
      return 'Ditt konto har blivit avaktiverat.';
    case 'auth/user-token-expired':
      return 'Sessionen har gått ut. Vänligen logga in igen.';
    case 'auth/web-storage-unsupported':
      return 'Vi har inte tillåtelse att lagra data lokalt på din enhet.';
    case 'auth/email-already-in-use':
      return 'Kontot finns redan';
    case 'auth/user-not-verified':
      return 'Du måste verifiera ditt konto.';
    default:
      return 'Okänt fel inträffade.';
  }
};