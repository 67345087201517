'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TSForm = require('../ui/TSForm');

var _Toaster = require('../ui/Toaster');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Company = function (_Component) {
  (0, _inherits3.default)(Company, _Component);

  function Company() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Company);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Company.__proto__ || Object.getPrototypeOf(Company)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: _this.props.data || {}
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.data[prop] = target.value;
      _this.setState(_this.state);
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      _this.props.handleSubmit(_this.props.user.uid, _this.state.data).then(function () {
        return (0, _Toaster.showSuccess)('Uppdatering lyckad.');
      }).catch(function () {
        return (0, _Toaster.showError)('Uppdateringen kunde inte genomföras.');
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Company, [{
    key: 'getForm',
    value: function getForm(type) {
      switch (type) {
        case 'profile':
          return this.profileForm;
        case 'settings':
          return this.settingsForm;
        default:
          return null;
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'form',
        { className: 'form form--settings',
          onSubmit: this.handleSubmit
        },
        this.getForm(this.props.formType),
        _react2.default.createElement(
          'div',
          { className: 'form__footer' },
          _react2.default.createElement(_TSForm.ButtonPrimary, { type: 'submit', text: 'Spara' })
        )
      );
    }
  }, {
    key: 'profileForm',
    get: function get() {
      var _this2 = this;

      var data = this.state.data;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: 'form__section form__section--settings' },
          _react2.default.createElement(_TSForm.TextField, { label: 'F\xF6retagsnamn',
            value: data.companyName,
            onChange: function onChange(e) {
              return _this2.setField('companyName', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Org. nummer',
            value: data.orgNumber,
            onChange: function onChange(e) {
              return _this2.setField('orgNumber', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Adress',
            value: data.streetAdress,
            onChange: function onChange(e) {
              return _this2.setField('streetAdress', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Postnummer',
            value: data.zipcode,
            onChange: function onChange(e) {
              return _this2.setField('zipcode', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Postort',
            value: data.city,
            onChange: function onChange(e) {
              return _this2.setField('city', e);
            }
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form__divider' },
          _react2.default.createElement(
            'h3',
            { className: 'form__divider-label form__divider-label--settings' },
            'Kontaktperson'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form__section form__section--settings' },
          _react2.default.createElement(_TSForm.TextField, { label: 'F\xF6rnamn',
            value: data.firstName,
            onChange: function onChange(e) {
              return _this2.setField('firstName', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Efternamn',
            value: data.lastName,
            onChange: function onChange(e) {
              return _this2.setField('lastName', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Telefon',
            value: data.phone,
            onChange: function onChange(e) {
              return _this2.setField('phone', e);
            }
          }),
          _react2.default.createElement(_TSForm.TextField, { label: 'Epostadress',
            value: data.email,
            onChange: function onChange(e) {
              return _this2.setField('email', e);
            }
          })
        )
      );
    }
  }, {
    key: 'settingsForm',
    get: function get() {
      var _this3 = this;

      var data = this.state.data;

      return _react2.default.createElement(
        'div',
        { className: 'form__section form__section--settings' },
        _react2.default.createElement(_TSForm.TextField, { label: '\xD6vertidspris',
          value: data.overtimePrice,
          onChange: function onChange(e) {
            return _this3.setField('overtimePrice', e);
          }
        })
      );
    }
  }]);
  return Company;
}(_react.Component);

exports.default = Company;
module.exports = exports['default'];