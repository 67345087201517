'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioButton = function RadioButton(_ref) {
  var inputType = _ref.inputType,
      error = _ref.error,
      checked = _ref.checked,
      labelText = _ref.labelText,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['inputType', 'error', 'checked', 'labelText']);
  return _react2.default.createElement(
    'label',
    { className: 'radio-button' },
    _react2.default.createElement('input', (0, _extends3.default)({
      className: error ? 'invalid' : '',
      checked: checked,
      type: 'radio',
      onChange: rest.onChange || function () {}
    }, rest)),
    _react2.default.createElement(
      'span',
      { className: 'radio' },
      labelText === null ? '' : labelText
    )
  );
};

exports.default = RadioButton;
module.exports = exports['default'];