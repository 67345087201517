'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactRouterDom = require('react-router-dom');

var _ShipmentList = require('../shipments/ShipmentList');

var _ShipmentList2 = _interopRequireDefault(_ShipmentList);

var _Dropdown = require('../ui/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

var _shipment = require('../../actions/shipment');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var shipment = _ref.shipment,
      user = _ref.user,
      company = _ref.company;
  return {
    shipment: shipment, user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    setSelectedShipment: function setSelectedShipment(shipment) {
      return (0, _shipment.setSelectedShipment)(shipment, dispatch);
    }
  };
};

var Shipments = function (_Component) {
  (0, _inherits3.default)(Shipments, _Component);

  function Shipments() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Shipments);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Shipments.__proto__ || Object.getPrototypeOf(Shipments)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      showState: '',
      dataLimit: 20,
      searchText: ''
    }, _this.getUrlParameter = function (name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(_this.props.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }, _this.newShipment = function () {
      _this.props.history.push('/new-shipment');
    }, _this.toggle = function (item) {
      _this.setState({ showState: item.value });
      _this.setState({ dataLimit: 20 });
    }, _this.getMoreShipments = function () {
      _this.setState({ dataLimit: _this.state.dataLimit + 20 });
    }, _this.updateSearchText = function (e) {
      _this.setState({
        searchText: e.target.value
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Shipments, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var filter = this.getUrlParameter('filter') !== '' ? this.getUrlParameter('filter') : 'created';
      this.setState({ showState: filter });
    }
  }, {
    key: 'getTitle',
    value: function getTitle() {
      var title = '';
      switch (this.state.showState) {
        case 'all':
          title = 'Alla';
          break;
        case 'assigned':
          title = 'Tilldelade';
          break;
        case 'approved':
          title = 'Godkända';
          break;
        case 'signed':
          title = 'Signerade';
          break;
        case 'invoiced':
          title = 'Fakturerade';
          break;
        default:
          title = 'Skapade';
          break;
      }
      return title;
    }
  }, {
    key: 'filterShipments',
    value: function filterShipments(shipments, state) {
      if (state === 'all') {
        return shipments;
      }

      var filtered = shipments.filter(function (item) {
        return item.state === state;
      });

      return filtered;
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/' });
      }

      // refresh the page the first time after sign in
      var pageIsRefreshed = localStorage.getItem('pageIsRefreshed') || 'false';
      if (pageIsRefreshed == 'false') {
        localStorage.setItem('pageIsRefreshed', 'true');
        window.location.reload();
      }

      var _props = this.props,
          _props$shipment = _props.shipment,
          shipmentList = _props$shipment.shipmentList,
          companyShipmentList = _props$shipment.companyShipmentList,
          assignedShipmentList = _props$shipment.assignedShipmentList,
          companies = _props.company.companies;

      // TODO paging is done after all shipments are loaded from firebase, better to limit the loading

      var combinedList = [].concat((0, _toConsumableArray3.default)(companyShipmentList), (0, _toConsumableArray3.default)(assignedShipmentList)).filter(function (arr, index, self) {
        return index === self.findIndex(function (t) {
          return t.tag === arr.tag;
        });
      }).sort(function (a, b) {
        return b.createdAt > a.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0;
      });

      var slicedList = this.filterShipments(combinedList, this.state.showState).slice(0, this.state.dataLimit);

      var loadMoreButton = null;
      if (combinedList && this.filterShipments(combinedList, this.state.showState).length > slicedList.length) {
        loadMoreButton = _react2.default.createElement(_Button2.default, {
          text: 'Ladda in fler sedlar',
          type: _Button.TYPES.SECONDARY,
          onClick: this.getMoreShipments
        });
      }
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_Header2.default, (0, _extends3.default)({}, this.props, { active: 'shipments', showState: this.state.showState })),
        _react2.default.createElement(
          'main',
          null,
          _react2.default.createElement(
            'div',
            { className: 'card-container' },
            _react2.default.createElement(
              'div',
              { className: 'card' },
              _react2.default.createElement(
                'h1',
                null,
                'Sedellista'
              ),
              _react2.default.createElement(
                'div',
                { className: 'shipment-filterbar' },
                this.switch,
                _react2.default.createElement(
                  'div',
                  { className: 'shipment-filterbar__search' },
                  _react2.default.createElement(_Input2.default, {
                    onChange: this.updateSearchText,
                    value: this.state.searchText,
                    inputType: 'text',
                    placeholder: 'S\xF6k Sedel'
                  })
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'filterbar__button' },
                  _react2.default.createElement(_Button2.default, {
                    text: 'Ny Sedel',
                    type: _Button.TYPES.PRIMARY,
                    onClick: this.newShipment,
                    disabled: this.props.company.companies && this.props.company.companies.length === 0
                  })
                )
              ),
              _react2.default.createElement(_ShipmentList2.default, (0, _extends3.default)({ showState: this.state.showState, filter: this.state.searchText.toLowerCase() }, this.props, {
                shipments: slicedList
              })),
              loadMoreButton
            )
          )
        )
      );
    }
  }, {
    key: 'switch',
    get: function get() {
      return _react2.default.createElement(
        'div',
        { className: 'shipment-filterbar__filter' },
        _react2.default.createElement(_Dropdown2.default, {
          title: this.getTitle(),
          onSelect: this.toggle,
          items: [{ label: 'Alla', value: 'all' }, { label: 'Skapade', value: 'created' }, { label: 'Tilldelade', value: 'assigned' }, { label: 'Godkända', value: 'approved' }, { label: 'Signerade', value: 'signed' }, { label: 'Fakturerade', value: 'invoiced' }]
        })
      );
    }
  }]);
  return Shipments;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(Shipments);
module.exports = exports['default'];