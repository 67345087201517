'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Privacy = function Privacy() {
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_Header2.default, undefined.props),
    _react2.default.createElement(
      'div',
      { id: 'start' },
      _react2.default.createElement(
        'div',
        { id: 'home' },
        _react2.default.createElement(
          'section',
          { className: 'home' },
          _react2.default.createElement(
            'div',
            { className: 'privacy-box' },
            _react2.default.createElement(
              'h1',
              null,
              'Integritetspolicy'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Denna policy beskriver hur Transportsedeln.se samlar in, anv\xE4nder, bearbetar och delar information om dig.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Genom att ladda ner, komma \xE5t och / eller anv\xE4nda Transportsedeln, samtycker du till insamling och anv\xE4ndning av din information i enlighet med denna sekretesspolicy.',
              _react2.default.createElement('br', null),
              'Dessa avsnitt f\xF6rklarar ocks\xE5 hur du kan h\xE5lla tillbaka eller \xE5terkalla ditt samtycke till anv\xE4ndningen av dina uppgifter f\xF6r riktade reklam\xE4ndam\xE5l. Om du har n\xE5gra andra problem med att l\xE4mna information till oss eller det anv\xE4nds som beskrivet i denna sekretesspolicy ska du inte anv\xE4nda v\xE5ra tj\xE4nster.'
            ),
            _react2.default.createElement(
              'h2',
              null,
              'Anv\xE4nda v\xE5ra tj\xE4nster och mobila appar'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Genom att ladda ner, komma \xE5t och / eller anv\xE4nda v\xE5ra tj\xE4nster eller mobila appar, samtycker du till insamling av information om dig av oss. De flesta av dessa data kommer direkt fr\xE5n dig eller din enhet, t.ex. enhetsinformation, land och region. Vi anv\xE4nder dessa data f\xF6r att tillhandah\xE5lla v\xE5ra tj\xE4nster till dig, inklusive i syfte att optimera, f\xF6rhindra bedr\xE4geri, anpassa erbjudanden och verifiera ink\xF6p. Vi kan ocks\xE5 anv\xE4nda den f\xF6r andra \xE4ndam\xE5l som anges i denna sekretesspolicy, inklusive f\xF6r marknadsf\xF6ring och annonsering.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Merparten av den information vi samlar om dig kommer direkt fr\xE5n dig n\xE4r du anv\xE4nder v\xE5ra tj\xE4nster, eller interagerar med v\xE5ra webbplatser eller andra tj\xE4nster. Den information som vi samlar om dig g\xE4ller i stor utstr\xE4ckning vilken typ av enhet du anv\xE4nder, hur du anv\xE4nder webbplatsen och mobila apparna (t.ex. f\xF6rs\xF6k och ink\xF6p) och kan inneh\xE5lla information som du skickar n\xE4r du skapar ett konto hos oss. Vi kan ocks\xE5 samla information fr\xE5n reklamplattformar och partners och andra tredje parter, s\xE5som information om ink\xF6p och intressen.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Om du v\xE4ljer att avsluta ett konto eller \xF6nskar att radera det kommer vi att radera all anv\xE4ndarinformation, inkluderat information om ditt f\xF6retag.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Denna policy uppdaterades den 2019 05 16.'
            )
          )
        )
      )
    )
  );
};
exports.default = Privacy;
module.exports = exports['default'];