'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toaster = require('../ui/Toaster');

var _ModalBox = require('../ui/ModalBox');

var _ModalBox2 = _interopRequireDefault(_ModalBox);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CompanyConnections = function (_Component) {
  (0, _inherits3.default)(CompanyConnections, _Component);

  function CompanyConnections() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompanyConnections);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CompanyConnections.__proto__ || Object.getPrototypeOf(CompanyConnections)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: { companies: [] },
      companyConnections: [],
      infoDialogVisible: false,
      removeInviteDialogVisible: false,
      inviteToBeRemoved: [],
      inviteIsAccepted: false
      // domMounted: false
    }, _this.acceptInvite = function (invite) {
      _this.props.acceptInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har blivit accepterad!');
        // this.setState({ domMounted: false })
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade vid acceptans av inbjudan');
      });
    }, _this.acceptEmployeeInvite = function (invite) {
      _this.props.acceptEmployeeInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har blivit accepterad!');
        // this.setState({ domMounted: false })
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade vid acceptans av inbjudan');
      });
    }, _this.rejectInvite = function (invite) {
      _this.props.rejectInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har tagits bort!');
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade vid avböjning av inbjudan');
      });
    }, _this.cancelInvite = function (invite) {
      _this.props.cancelInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har blivit avböjd!');
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade vid avböjning av inbjudan');
      });
    }, _this.getCompanyName = function (email) {
      var company = _this.props.companyNames.find(function (e) {
        return e.email === email;
      });

      return company === undefined ? email : company.companyName;
    }, _this.handleInputChange = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.data[prop] = target.value;
      _this.setState(_this.state);
    }, _this.toggleRemoveDialog = function (invite, isAccepted) {
      _this.setState({
        removeInviteDialogVisible: !_this.state.removeInviteDialogVisible
      });
      _this.setState({
        inviteToBeRemoved: invite,
        inviteIsAccepted: isAccepted
      });
    }, _this.toggleInfoDialog = function (e) {
      e.preventDefault();
      _this.setState({
        infoDialogVisible: !_this.state.infoDialogVisible
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  // removeConnection = (user, company) => {
  //   // this.props.history.push('/settings')   
  //   this.props.removeEmployee(user, company).then(() => {
  //     showSuccess('Anslutning till företaget har blivit borttaget!')
  //     this.setState({ domMounted: false })
  //   })
  //     .catch(() => {
  //       showError('Ett fel inträffade vid borttagande av anslutning')
  //     })
  // }

  (0, _createClass3.default)(CompanyConnections, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      // TODO remove inline styles
      var divStyle = {
        marginRight: 10,
        fontFamily: 'Arial'
      };
      return _react2.default.createElement(
        'div',
        { className: 'form-row' },
        _react2.default.createElement(
          'ul',
          { className: 'list list--color list--right delete-list' },
          this.props.acceptedInvitesFromMyCompany ? this.props.acceptedInvitesFromMyCompany.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { key: key },
              _react2.default.createElement(
                'label',
                { htmlFor: key + 'c' },
                _react2.default.createElement(
                  'span',
                  null,
                  _this2.getCompanyName(item.receiver)
                )
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'Kontrakt\xF6r'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'delete-list__delete', onClick: function onClick() {
                      return _this2.toggleRemoveDialog(item, true);
                    } },
                  'x'
                )
              )
            );
          }) : '',
          this.props.acceptedCompanyInvites ? this.props.acceptedCompanyInvites.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { key: key },
              _react2.default.createElement(
                'span',
                null,
                item.company.companyName
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'Kund'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'delete-list__delete', onClick: function onClick() {
                      return _this2.toggleRemoveDialog(item, true);
                    } },
                  'x'
                )
              )
            );
          }) : '',
          this.props.pendingInvitesFromMyCompany ? this.props.pendingInvitesFromMyCompany.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { className: 'accordion__item', key: key },
              _react2.default.createElement(
                'label',
                { htmlFor: key + 'c' },
                _react2.default.createElement(
                  'span',
                  null,
                  _this2.getCompanyName(item.receiver)
                )
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'V\xE4ntande'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'delete-list__delete', onClick: function onClick() {
                      return _this2.toggleRemoveDialog(item, false);
                    } },
                  'x'
                )
              )
            );
          }) : '',
          this.props.pendingCompanyInvites ? this.props.pendingCompanyInvites.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { key: key },
              _react2.default.createElement(
                'span',
                null,
                item.company.companyName
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'Inbjudan'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'open-modal', onClick: function onClick() {
                      return _this2.acceptInvite(item);
                    } },
                  'ja'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'open-modal', onClick: function onClick() {
                      return _this2.toggleRemoveDialog(item, false);
                    } },
                  'nej'
                )
              )
            );
          }) : '',
          this.props.acceptedEmployeeInvites ? this.props.acceptedEmployeeInvites.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { key: key },
              _react2.default.createElement(
                'span',
                null,
                item.company.companyName
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'Aktiv'
                )
              )
            );
          }) : '',
          this.props.pendingEmployeeInvites ? this.props.pendingEmployeeInvites.map(function (item, key) {
            return _react2.default.createElement(
              'li',
              { key: key },
              _react2.default.createElement(
                'span',
                null,
                item.company.companyName
              ),
              _react2.default.createElement(
                'span',
                { className: 'list--right__right' },
                _react2.default.createElement(
                  'span',
                  { style: divStyle },
                  'V\xE4ntande'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'open-modal', onClick: function onClick() {
                      return _this2.acceptEmployeeInvite(item);
                    } },
                  'ja'
                ),
                _react2.default.createElement(
                  'a',
                  { className: 'open-modal', onClick: function onClick() {
                      return _this2.toggleRemoveDialog(item, false);
                    } },
                  'nej'
                )
              )
            );
          }) : ''
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Radera inbjudan',
            visibility: this.state.removeInviteDialogVisible,
            toggle: this.toggleRemoveDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Vill du radera inbjudan?'
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Nej',
              onClick: this.toggleRemoveDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Ja, radera inbjudan',
              onClick: function onClick() {
                return _this2.state.inviteIsAccepted ? _this2.cancelInvite(_this2.state.inviteToBeRemoved) : _this2.rejectInvite(_this2.state.inviteToBeRemoved);
              }
            })
          )
        )
      );
    }
  }]);
  return CompanyConnections;
}(_react.Component);

exports.default = CompanyConnections;
module.exports = exports['default'];