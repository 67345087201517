"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var flatten = exports.flatten = function flatten(arr) {
  try {
    if (!arr && arr.length === 0) return [];
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    });
  } catch (error) {
    return [];
  }
};