'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _helpers = require('../../helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Dropdown = function (_Component) {
  (0, _inherits3.default)(Dropdown, _Component);

  function Dropdown() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Dropdown);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Dropdown.__proto__ || Object.getPrototypeOf(Dropdown)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      open: false
    }, _this.toggle = function () {
      _this.setState({
        open: !_this.state.open
      });
    }, _this.select = function (item) {
      var onSelect = _this.props.onSelect;

      _this.setState({
        selected: item
      });

      if (typeof onSelect === 'function') {
        onSelect(item);
      }
      _this.toggle();
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Dropdown, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          open = _state.open,
          selected = _state.selected;
      var _props = this.props,
          label = _props.label,
          items = _props.items;

      label = this.props.title;

      return _react2.default.createElement(
        'div',
        { className: 'dropdown' },
        _react2.default.createElement(
          'button',
          { type: 'button',
            onClick: this.toggle,
            className: 'dropdown--btn btn--full-width'
          },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'span',
              { className: 'button-text--left' },
              selected ? (0, _helpers.truncateStr)(selected.label || selected, 26, '...') : label
            ),
            _react2.default.createElement('span', { className: 'arrow arrow--down button-text--right' })
          )
        ),
        open ? _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement('div', { onClick: this.toggle, className: 'dropdown__overlay' }),
          _react2.default.createElement(
            'div',
            { className: 'dropdown__content' },
            _react2.default.createElement(
              'ul',
              null,
              items.map(function (item, i) {
                return _react2.default.createElement(
                  'li',
                  { key: i },
                  _react2.default.createElement(
                    'a',
                    { onClick: _this2.select.bind(_this2, item) },
                    item.label || item
                  )
                );
              })
            )
          )
        ) : null
      );
    }
  }]);
  return Dropdown;
}(_react.Component);

Dropdown.defaultProps = {
  label: 'Dropdown',
  items: ['Status 1', 'Status 2', 'Status 3']
};
exports.default = Dropdown;
module.exports = exports['default'];