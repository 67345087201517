'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.upload = undefined;

var _firestore = require('../store/firestore');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _uuid = require('uuid');

var _uuid2 = _interopRequireDefault(_uuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.locale('sv');

var upload = exports.upload = function upload(file) {
  var progress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'images/';
  return new Promise(function (resolve, reject) {
    var fileName = (0, _moment2.default)().format('x') + '-' + _uuid2.default.v4();
    var task = _firestore.storage.ref().child('' + path + fileName).put(new File([file], fileName));
    task.on('state_changed', function (snap) {
      var percent = snap.bytesTransferred / snap.totalBytes * 100;
      progress(percent);
    }, function (error) {
      reject(error);
    }, function () {
      resolve(task.snapshot.ref.getDownloadURL());
    });
  });
};

var remove = exports.remove = function remove(url) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'images/';

  var fileName = url.split('images%2F')[1].split('?')[0];
  return _firestore.storage.ref().child('' + path + fileName).delete();
};