'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _Toaster = require('../ui/Toaster');

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _TextArea = require('../ui/TextArea');

var _TextArea2 = _interopRequireDefault(_TextArea);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditShipmentForm = function (_Component) {
  (0, _inherits3.default)(EditShipmentForm, _Component);

  function EditShipmentForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditShipmentForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = EditShipmentForm.__proto__ || Object.getPrototypeOf(EditShipmentForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: {},
      employees: [],
      forCompany: true,
      loading: false,
      removeIsLoading: false,
      acceptShipmentIsLoading: false,
      sendShipmentIsLoading: false,
      linkedComps: {},
      hasContractor: false,
      redirectToShipmentList: false,
      errors: {},
      fields: []
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.data[prop] = target.value;
      _this.setState(_this.state);
    }, _this.setFieldAndReplace = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state.data[prop] = target.value.replace(',', '.');
      _this.setState(_this.state);
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      var data = _this.state.data;

      data.creator = _this.props.user.email;
      data.removed = false;
      data.state = data.state === null ? 'created' : data.state;

      if (!data.assignedTo || data.assignedTo === null) {
        data.assignedTo = {
          uid: _this.props.user.uid,
          email: _this.props.user.email
        };
      }

      data.companyId = _this.state.forCompany ? 'users/' + _this.props.user.uid + '/companies/' + _this.props.company.companies[0].uid : _this.props.user.uid;

      _this.setState({ data: data, loading: true });

      _this.props.update(data).then(function (value) {
        (0, _Toaster.showSuccess)('Sedeln uppdaterad.');
        _this.setState({ loading: false });
        _this.props.redirectToShipment();
      }).catch(function () {
        (0, _Toaster.showError)('Gick ej att uppdatera sedel.');
        _this.setState({ loading: false });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditShipmentForm, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      if (this.props.shipmentId) {
        this.props.getShipmentById(this.props.shipmentId).then(function (shipment) {
          _this2.setState({ data: shipment.data });
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      if (this.state.data === undefined) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/' });
      }

      var _state$data = this.state.data,
          workplace = _state$data.workplace,
          vehicleType = _state$data.vehicleType,
          licenseNumber = _state$data.licenseNumber,
          customer = _state$data.customer,
          customerPhone = _state$data.customerPhone,
          overtimePrice = _state$data.overtimePrice,
          littra = _state$data.littra,
          reference = _state$data.reference,
          fees = _state$data.fees,
          fromAddress = _state$data.fromAddress,
          toAddress = _state$data.toAddress,
          description = _state$data.description;
      var loading = this.state.loading;


      return _react2.default.createElement(
        'div',
        { className: 'l-two-col__form' },
        _react2.default.createElement(
          'form',
          { onSubmit: function onSubmit() {
              return _this3.handleSubmit();
            },
            className: 'form-item form-text' },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'h1',
              null,
              'Uppdatera sedel'
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Fr\xE5n adress'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'fromAddress',
                inputType: 'text',
                value: fromAddress,
                onChange: function onChange(e) {
                  return _this3.setField('fromAddress', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Till adress'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'toAddress',
                inputType: 'text',
                value: toAddress,
                onChange: function onChange(e) {
                  return _this3.setField('toAddress', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Arbetsplats'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'workplace',
                inputType: 'text',
                value: workplace,
                onChange: function onChange(e) {
                  return _this3.setField('workplace', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Fordonstyp'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'vehicleType',
                inputType: 'text',
                value: vehicleType,
                onChange: function onChange(e) {
                  return _this3.setField('vehicleType', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Registreringsnummer'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'licenseNumber',
                inputType: 'text',
                value: licenseNumber,
                onChange: function onChange(e) {
                  return _this3.setField('licenseNumber', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Kund'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'customer',
                inputType: 'text',
                value: customer,
                onChange: function onChange(e) {
                  return _this3.setField('customer', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Kund telefonnummer '
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'customerPhone',
                inputType: 'text',
                value: customerPhone,
                onChange: function onChange(e) {
                  return _this3.setField('customerPhone', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Referens'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'reference',
                inputType: 'text',
                value: reference,
                onChange: function onChange(e) {
                  return _this3.setField('reference', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                '\xD6vertidspris'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'overtimePrice',
                inputType: 'text',
                value: overtimePrice,
                onChange: function onChange(e) {
                  return _this3.setFieldAndReplace('overtimePrice', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Littera'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'littra',
                inputType: 'text',
                value: littra,
                onChange: function onChange(e) {
                  return _this3.setFieldAndReplace('littra', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Avgift'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'fees',
                inputType: 'text',
                value: fees,
                onChange: function onChange(e) {
                  return _this3.setFieldAndReplace('fees', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'Beskrivning'
              ),
              _react2.default.createElement(_TextArea2.default, {
                name: 'description',
                inputType: 'text',
                value: description,
                rows: '5',
                onChange: function onChange(e) {
                  return _this3.setField('description', e);
                }
              })
            )
          ),
          this.companyFormSettings,
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__btns' },
            _react2.default.createElement(_Button2.default, {
              text: 'Avbryt',
              type: _Button.TYPES.SECONDARY,
              size: _Button.SIZES.SMALL,
              onClick: this.props.redirectToShipment
            }),
            _react2.default.createElement(_Button2.default, {
              text: loading ? 'Uppdaterar sedeln...' : 'Uppdatera sedeln',
              type: _Button.TYPES.PRIMARY,
              onClick: this.handleSubmit
            })
          )
        )
      );
    }
  }]);
  return EditShipmentForm;
}(_react.Component);

exports.default = EditShipmentForm;
module.exports = exports['default'];