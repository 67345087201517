'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAcceptedInvites = exports.getCompanyInvites = exports.getUserInvites = exports.cancelInvite = exports.rejectInvite = exports.acceptEmployeeInvite = exports.acceptInvite = exports.removeInvite = exports.createInviteWithStateAccepted = exports.createInvite = exports.GET_ACCEPTED_INVITES = exports.RESPOND_INVITATION = exports.GET_USER_INVITES = exports.GET_COMPANY_INVITES = exports.DELETE_INVITE = exports.CREATE_INVITE_ERROR = exports.CREATE_INVITE_SUCCESS = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _firestore = require('../store/firestore');

var _Toaster = require('../components/ui/Toaster');

var _array = require('../components/utils/array');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CREATE_INVITE_SUCCESS = exports.CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';
var CREATE_INVITE_ERROR = exports.CREATE_INVITE_ERROR = 'CREATE_INVITE_ERROR';
var DELETE_INVITE = exports.DELETE_INVITE = 'DELETE_INVITE';
var GET_COMPANY_INVITES = exports.GET_COMPANY_INVITES = 'GET_COMPANY_INVITES';
var GET_USER_INVITES = exports.GET_USER_INVITES = 'GET_USER_INVITES';
var RESPOND_INVITATION = exports.RESPOND_INVITATION = 'RESPOND_INVITATION';
var GET_ACCEPTED_INVITES = exports.GET_ACCEPTED_INVITES = 'GET_ACCEPTED_INVITES';

/**
 * Creates a company invitation for a specific email
 * @param{Object} company - The company that issues the invitation
 * @param{String} email - The email address to send the invitation to
 */
var createInvite = exports.createInvite = function createInvite(company, email) {
  var isContractor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!email || email.length === 0) {
    return Promise.reject((0, _Toaster.showError)('E-postaddress saknas'));
  }

  // very simple regex to validate email
  if (/^.+@.+\..+$/.test(email) === false) {
    return Promise.reject((0, _Toaster.showError)('E-postaddress ogiltig'));
  }

  email = email.toLowerCase();

  // check if user already is an employee
  var isEmployee = company.employees && company.employees.filter(function (e) {
    return e.email === email;
  }).length > 0;
  if (isEmployee) {
    return Promise.reject((0, _Toaster.showError)('Personen är redan en aktiv medarbetare'));
  }

  if (!isContractor) {
    // check for pending invite
    return _firestore.db.collection('invites').where('receiver', '==', email).where('company.uid', '==', company.uid).where('status', '==', 'pending').get().then(function (result) {
      if (result.empty) {
        // check for accepted invite
        return _firestore.db.collection('invites').where('receiver', '==', email).where('status', '==', 'accepted').get().then(function (result) {
          var promise = new Promise(function (resolve, reject) {
            if (result.empty) {
              var inviteRef = _firestore.db.collection('invites').doc();
              var payload = {
                receiver: email,
                status: 'pending',
                company: company,
                uid: inviteRef.id,
                contractor: isContractor
              };
              return resolve(inviteRef.set(payload).then(function () {
                return {
                  type: CREATE_INVITE_SUCCESS,
                  data: payload
                };
              }));
            }
            return Promise.reject((0, _Toaster.showError)('Personen är redan en medarbetare på ett annat företag.'));
          });
          return promise;
        }).then(function (result) {
          return {
            type: CREATE_INVITE_SUCCESS,
            data: result
          };
        }).catch(function (err) {
          return {
            type: CREATE_INVITE_ERROR,
            data: err
          };
        });
      }
      return Promise.reject((0, _Toaster.showError)('Personen har redan väntande inbjudan.'));
    });
  } else {
    // check if user exists
    return _firestore.db.collection('users').where('email', '==', email).get().then(function (result) {
      if (!result.empty) {
        var user = (0, _array.flatten)(result.docs.map(function (s) {
          return (0, _extends3.default)({}, s.data());
        }));
        // check if user has a company
        return _firestore.db.collection('users/' + user.uid + '/companies').get().then(function (result) {
          if (!result.empty) {
            // check for pending invite
            return _firestore.db.collection('invites').where('receiver', '==', email).where('company.uid', '==', company.uid).where('status', '==', 'pending').get().then(function (result) {
              if (result.empty) {
                // check for accepted invite
                return _firestore.db.collection('invites').where('receiver', '==', email).where('company.uid', '==', company.uid).where('status', '==', 'accepted').get().then(function (result) {
                  var promise = new Promise(function (resolve, reject) {
                    if (result.empty) {
                      var inviteRef = _firestore.db.collection('invites').doc();
                      var payload = {
                        receiver: email,
                        status: 'pending',
                        company: company,
                        uid: inviteRef.id,
                        contractor: isContractor
                      };
                      return resolve(inviteRef.set(payload).then(function () {
                        return {
                          type: CREATE_INVITE_SUCCESS,
                          data: payload
                        };
                      }));
                    }
                    return Promise.reject((0, _Toaster.showError)('Personen har redan en accepterad inbjudan'));
                  });
                  return promise;
                }).then(function (result) {
                  return {
                    type: CREATE_INVITE_SUCCESS,
                    data: result
                  };
                }).catch(function (err) {
                  return {
                    type: CREATE_INVITE_ERROR,
                    data: err
                  };
                });
              }
              return Promise.reject((0, _Toaster.showError)('Personen har redan en vilande inbjudan'));
            });
          }
          return Promise.reject((0, _Toaster.showError)('Personen har inget företag'));
        });
      }
      return Promise.reject((0, _Toaster.showError)('Det finns ingen som har denna e-post'));
    });
  }
};

/**
 * 
 * Create invite with state accepted (used when creating a company and adding self as employee)
 */
var createInviteWithStateAccepted = exports.createInviteWithStateAccepted = function createInviteWithStateAccepted(company, email) {
  var promise = new Promise(function (resolve, reject) {
    var inviteRef = _firestore.db.collection('invites').doc();
    var payload = {
      receiver: email,
      status: 'accepted',
      company: company,
      uid: inviteRef.id,
      contractor: false
    };
    return resolve(inviteRef.set(payload).then(function () {
      return {
        type: CREATE_INVITE_SUCCESS,
        data: payload
      };
    }));
  });
  return promise;
};
/**
 * Delete invitation
 */
var removeInvite = exports.removeInvite = function removeInvite(invite) {
  return _firestore.db.collection('invites').doc(invite.uid).delete().then(function () {
    return {
      type: DELETE_INVITE,
      data: invite
    };
  });
};

var respondToInvite = function respondToInvite(invite, status) {
  var accepted = status === 'accepted';
  invite.status = status;
  return _firestore.db.collection('invites').doc(invite.uid).set(invite, { merge: true }).then(function () {
    var action = { type: RESPOND_INVITATION, data: { accepted: accepted, invite: invite } };
    return action;
  });
};

/**
 * Accepts a pending invitation
 */
var acceptInvite = exports.acceptInvite = function acceptInvite(invite) {
  return respondToInvite(invite, 'accepted');
};

/**
* Accepts a pending employee invitation as employee
*/
var acceptEmployeeInvite = exports.acceptEmployeeInvite = function acceptEmployeeInvite(invite) {
  // accept the invite  
  respondToInvite(invite, 'accepted');

  // reject any pending invites
  _firestore.db.collection('invites').where('receiver', '==', invite.receiver).where('status', '==', 'pending').get().then(function (result) {
    if (!result.empty) {
      var invs = result.docs.map(function (s) {
        return (0, _extends3.default)({}, s.data());
      });
      invs.forEach(function (inv) {
        respondToInvite(inv, 'rejected');
      });
    }
  });

  // send back info
  var accepted = true;
  return _firestore.db.collection('invites').doc(invite.uid).set(invite, { merge: true }).then(function () {
    var action = { type: RESPOND_INVITATION, data: { accepted: accepted, invite: invite } };
    return action;
  });
};

/**
 * Rejects a pending invitation
 */
var rejectInvite = exports.rejectInvite = function rejectInvite(invite) {
  return respondToInvite(invite, 'rejected');
};

/**
 * Declines an accepted invitation
 */
var cancelInvite = exports.cancelInvite = function cancelInvite(invite) {
  return respondToInvite(invite, 'canceled');
};

/**
 * Retrieves all user invitations
 * @param{String} company - The company reference
 */
var getUserInvites = exports.getUserInvites = function getUserInvites(company, callback, dispatch) {
  var sub = _firestore.db.collection('invites').where('company.uid', '==', company.uid).onSnapshot(function (snapshot) {
    var invites = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({}, s.data());
    });

    dispatch({
      type: GET_USER_INVITES,
      data: { invites: invites }
    });
    if (callback) {
      callback(invites);
    }
  });
  return _firestore.subscriptions.add('invites-user', sub);
};

/**
 * Retrieves all company ivitations
 * @param{Object} - user
 */
var getCompanyInvites = exports.getCompanyInvites = function getCompanyInvites(user, dispatch) {
  var sub = _firestore.db.collection('invites').where('receiver', '==', user.email || '').where('status', '==', 'pending').onSnapshot(function (snapshot) {
    var invites = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({}, s.data());
    });

    return dispatch({
      type: GET_COMPANY_INVITES,
      data: invites
    });
  });
  return _firestore.subscriptions.add('invites-company', sub);
};

/**
 * Retrieves all accepted invites
 * @param{Object} - user
 */
var getAcceptedInvites = exports.getAcceptedInvites = function getAcceptedInvites(user, dispatch) {
  var sub = _firestore.db.collection('invites').where('receiver', '==', user.email || '').where('status', '==', 'accepted').onSnapshot(function (result) {
    if (!result.empty) {
      return dispatch({
        type: GET_ACCEPTED_INVITES,
        data: result
      });
    }
  });
  return _firestore.subscriptions.add('invites-accepted', sub);
};