'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subscriptions = exports.auth = exports.storage = exports.db = undefined;

var _app = require('firebase/app');

var _app2 = _interopRequireDefault(_app);

require('firebase/auth');

require('firebase/database');

require('firebase/firestore');

require('firebase/storage');

var _subscriptions = require('./subscriptions');

var _subscriptions2 = _interopRequireDefault(_subscriptions);

var _webpackVariables = require('webpack-variables');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var firebaseConfig = {
  apiKey: _webpackVariables.FS_API_KEY,
  authDomain: _webpackVariables.FS_AUTH_DOMAIN,
  databaseURL: _webpackVariables.FS_DATABASE_URL,
  projectId: _webpackVariables.FS_PROJECT_ID,
  messagingSenderId: _webpackVariables.FS_MESSAGING_SENDER_ID,
  storageBucket: _webpackVariables.FS_STORAGE_BUCKET
};

_app2.default.initializeApp(firebaseConfig);

var db = exports.db = _app2.default.firestore();

var storage = exports.storage = _app2.default.storage();

var auth = exports.auth = _app2.default.auth;

var subscriptions = exports.subscriptions = _subscriptions2.default;