'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeEmployee = exports.addEmployee = exports.getLinkedCompanies = exports.getCompanies = exports.updateCompany = exports.createCompany = exports.REMOVE_EMPLOYEE = exports.ADD_EMPLOYEE = exports.GET_LINKED_COMPANIES = exports.GET_COMPANIES = exports.UPDATE_COMPANY = exports.CREATE_COMPANY = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _firestore = require('../store/firestore');

var _Toaster = require('../components/ui/Toaster');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CREATE_COMPANY = exports.CREATE_COMPANY = 'CREATE_COMPANY';
var UPDATE_COMPANY = exports.UPDATE_COMPANY = 'UPDATE_COMPANY';
var GET_COMPANIES = exports.GET_COMPANIES = 'GET_COMPANIES';
var GET_LINKED_COMPANIES = exports.GET_LINKED_COMPANIES = 'GET_LINKED_COMPANIES';
var ADD_EMPLOYEE = exports.ADD_EMPLOYEE = 'ADD_EMPLOYEE';
var REMOVE_EMPLOYEE = exports.REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';

var createCompany = exports.createCompany = function createCompany(uid, data) {
  var comps = _firestore.db.collectionGroup('companies').where('orgNumber', '==', data.orgNumber);
  return comps.get().then(function (result) {
    // check if company already exists
    if (result.empty) {
      var companyRef = _firestore.db.collection('users/' + uid + '/companies').doc();
      return companyRef.set(data).then(function (doc) {
        return {
          type: CREATE_COMPANY,
          data: data
        };
      });
    }
    return Promise.reject((0, _Toaster.showError)('Företaget finns redan'));
  });
};

var updateCompany = exports.updateCompany = function updateCompany(uid, company) {
  return _firestore.db.collection('users/' + uid + '/companies').doc(company.uid).set(company).then(function () {
    return {
      type: UPDATE_COMPANY,
      data: company
    };
  });
};

var getCompanies = exports.getCompanies = function getCompanies(uid, callback, dispatch) {
  var sub = _firestore.db.collection('users/' + uid + '/companies').onSnapshot(function (snapshot) {
    var companies = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({ uid: s.id }, s.data());
    });

    dispatch({
      type: GET_COMPANIES,
      data: { companies: companies, uid: uid }
    });
    callback(companies);
  });
  return _firestore.subscriptions.add('companies', sub);
};

var getLinkedCompanies = exports.getLinkedCompanies = function getLinkedCompanies(companyRefs) {
  if (companyRefs && companyRefs.length > 0) {
    return Promise.all(companyRefs.map(function (c) {
      var url;
      c ? url = c.split('companies/', 2) : url = '';
      url[0] += 'companies/';
      return _firestore.db.collection(url[0]).get();
    })).then(function (snapshots) {
      return snapshots.map(function (s) {
        return s.docs[0].data();
      });
    }).then(function (companies) {
      return {
        type: GET_LINKED_COMPANIES,
        companies: companies
      };
    });
  } else {
    return Promise.resolve(function () {
      return {
        type: GET_LINKED_COMPANIES,
        companies: null
      };
    });
  }

  // version 1.0
  // return Promise.all(companyRefs.map(c => {
  //   var url
  //   c ? url = c.split('companies/', 2) : url = ''
  //   url[0] += 'companies/'
  //   console.log('STEG 1', url)
  //   return db.collection(url[0]).get()
  // })).then((snapshots) => snapshots.map(s => ({ ref: s.ref, ...s.data() })))
  //   .then((companies) => ({
  //     type: GET_LINKED_COMPANIES,
  //     data: companies
  //   }))

  // Original
  // return Promise.all(companyRefs.map(c => c ? db.collection(c).get() : false))
  // .then((snapshots) => snapshots.map(s => ({ ref: s.ref, ...s.data() })))
  // .then((companies) => ({
  //   type: GET_LINKED_COMPANIES,
  //   data: companies
  // }))
};

var addEmployee = exports.addEmployee = function addEmployee(email, company) {
  var status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'active';

  return _firestore.db.collection('users').where('email', '==', email).limit(1).get().then(function (snapshot) {
    if (snapshot.empty) return null;
    var user = snapshot.docs[0].data();
    return _firestore.db.collection('users/' + company.owner + '/companies/' + company.uid + '/employees').doc().set({ user: user, status: status }).then(function () {
      return {
        type: ADD_EMPLOYEE,
        data: {
          user: user,
          company: company
        }
      };
    });
  });
};

var removeEmployee = exports.removeEmployee = function removeEmployee(user, company) {
  return _firestore.db.collection('invites').where('receiver', '==', user.email).where('status', '==', 'accepted').where('company.uid', '==', company.uid).limit(1).get().then(function (snapshot) {
    if (snapshot.empty) return null;

    var invite = snapshot.docs[0].data();
    invite.status = 'canceled';
    var accepted = false;
    return _firestore.db.collection('invites').doc(invite.uid).set(invite, { merge: true }).then(function () {
      var action = { type: 'RESPOND_INVITATION', data: { accepted: accepted, invite: invite } };
      return action;
    });
  });
};