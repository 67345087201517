'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _shipment = require('../actions/shipment');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialState = {
  shipmentList: [],
  assignedShipmentList: [],
  companyShipmentList: [],
  selected: {},
  acceptedInvites: [],
  toggleTesting: false
};

var sorted = function sorted(shipments) {
  return shipments.sort(function (a, b) {
    return b.createdAt - a.createdAt;
  });
};

var filtered = function filtered(shipments) {
  var filtered = shipments.filter(function (shipment) {
    return shipment.removed === false;
  });

  return filtered;
};

exports.default = function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case _shipment.SIGN_SHIPMENT:
      {
        return (0, _extends3.default)({}, state, {
          selected: (0, _extends3.default)({}, state.selected, {
            signedAt: action.data.signedAt,
            state: 'signed'
          })
        });
      }
    case _shipment.SEND_SHIPMENT_PDF_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          selected: (0, _extends3.default)({}, state.selected, {
            mailedAt: action.data.mailedAt,
            mailedTo: action.data.mailedTo
          })
        });
      }
    case _shipment.SEND_SHIPMENT_PDF_ERROR:
      {
        return (0, _extends3.default)({}, state);
      }
    case _shipment.SET_REMOVED_SUCCESS:
      {
        return (0, _extends3.default)({}, state);
      }
    case _shipment.SET_REMOVED_ERROR:
      {
        return (0, _extends3.default)({}, state);
      }
    case _shipment.GET_SHIPMENTS:
      {
        var shipmentList = sorted(filtered(action.data));
        return (0, _extends3.default)({}, state, {
          shipmentList: shipmentList
        });
      }
    case _shipment.GET_ASSIGNED_SHIPMENTS:
      {
        var assignedShipmentList = sorted(filtered(action.data));
        return (0, _extends3.default)({}, state, {
          assignedShipmentList: assignedShipmentList
        });
      }
    case _shipment.GET_COMPANY_SHIPMENTS:
      {
        var companyShipmentList = sorted(filtered(action.data));
        return (0, _extends3.default)({}, state, {
          companyShipmentList: companyShipmentList
        });
      }
    case _shipment.SET_SELECTED_SHIPMENT:
      {
        return (0, _extends3.default)({}, state, {
          selected: action.data
        });
      }
    case _shipment.UPDATE_SHIPMENT:
      {
        return (0, _extends3.default)({}, state, {
          selected: action.data || state.selected
        });
      }
    default:
      return state;
  }
};

module.exports = exports['default'];