'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _EmployeeListItem = require('./EmployeeListItem');

var _EmployeeListItem2 = _interopRequireDefault(_EmployeeListItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmployeeList = function EmployeeList(props) {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      'ul',
      { className: 'employees' },
      props.data.map(function (s, i) {
        return _react2.default.createElement(
          'li',
          { key: i },
          _react2.default.createElement(_EmployeeListItem2.default, {
            type: props.type,
            company: props.company,
            remove: props.remove,
            triggerModal: props.triggerModal,
            data: s
          })
        );
      })
    )
  );
};

exports.default = EmployeeList;
module.exports = exports['default'];