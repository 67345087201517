'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactRouterDom = require('react-router-dom');

var _company = require('../../actions/company');

var _invite = require('../../actions/invite');

var _EditCompanyForm = require('../company/EditCompanyForm');

var _EditCompanyForm2 = _interopRequireDefault(_EditCompanyForm);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.locale('sv');

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user,
      company = _ref.company;
  return {
    user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    createCompany: function createCompany(uid, data) {
      return (0, _company.createCompany)(uid, data).then(dispatch);
    },
    updateCompany: function updateCompany(uid, data) {
      return (0, _company.updateCompany)(uid, data).then(dispatch);
    },
    createInvite: function createInvite(company, email) {
      return (0, _invite.createInvite)(company, email).then(dispatch).catch(dispatch);
    },
    removeInvite: function removeInvite(invite) {
      return (0, _invite.removeInvite)(invite).then(dispatch);
    },
    removeEmployee: function removeEmployee(user, company) {
      return (0, _company.removeEmployee)(user, company).then(dispatch);
    }
  };
};

var EditCompany = function (_Component) {
  (0, _inherits3.default)(EditCompany, _Component);

  function EditCompany() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditCompany);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = EditCompany.__proto__ || Object.getPrototypeOf(EditCompany)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      user: [],
      company: []
    }, _this.goToSettings = function (e) {
      e.preventDefault();
      _this.props.history.push('/settings');
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditCompany, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.user && this.props.company.companies[0]) {
        this.setState({
          // TODO: check to use local variables
          user: this.props.user,
          company: this.props.company.companies[0]
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      var _props = this.props,
          user = _props.user,
          company = _props.company,
          updateCompany = _props.updateCompany;


      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { href: '/#/settings' },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'container l-two-col' },
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__form' },
            _react2.default.createElement(
              'h1',
              null,
              '\xC4ndra F\xF6retagsinfo'
            ),
            _react2.default.createElement(_EditCompanyForm2.default, (0, _defineProperty3.default)({ user: user,
              history: this.props.history,
              updateCompany: updateCompany,
              goToSettings: this.goToSettings,
              company: company
            }, 'user', user))
          ),
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__img' },
            _react2.default.createElement('img', { src: 'images/illustration-sign.svg', alt: 'Shipmentbills' })
          )
        )
      );
    }
  }]);
  return EditCompany;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(EditCompany);
module.exports = exports['default'];