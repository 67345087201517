'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _reactRedux = require('react-redux');

var _user = require('./actions/user');

var _company = require('./actions/company');

var _invite = require('./actions/invite');

var _shipment = require('./actions/shipment');

var _firestore = require('./store/firestore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user;
  return { user: user };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    getUser: function getUser(user) {
      return (0, _user.getUser)(user).then(dispatch);
    },
    getCompanies: function getCompanies(uid, cb) {
      return (0, _company.getCompanies)(uid, cb, dispatch);
    },
    getUserInvites: function getUserInvites(company, cb) {
      return (0, _invite.getUserInvites)(company, cb, dispatch);
    },
    getCompanyInvites: function getCompanyInvites(user) {
      return (0, _invite.getCompanyInvites)(user, dispatch);
    },
    getAcceptedInvites: function getAcceptedInvites(user) {
      return (0, _invite.getAcceptedInvites)(user, dispatch);
    },
    addEmployee: function addEmployee(email, company, status) {
      return (0, _company.addEmployee)(email, company, status).then(dispatch);
    },
    removeInvite: function removeInvite(invite) {
      return (0, _invite.removeInvite)(invite).then(dispatch);
    },
    signOut: function signOut() {
      return (0, _user.signOut)(dispatch);
    },
    setAuthUser: function setAuthUser(user) {
      return (0, _user.setAuthUser)(user, dispatch);
    },
    getShipments: function getShipments(user) {
      return (0, _shipment.getShipments)(user, dispatch);
    },
    getAssignedShipments: function getAssignedShipments(user, company) {
      return (0, _shipment.getAssignedShipments)(user, company, dispatch);
    },
    getCompanyShipments: function getCompanyShipments(user, company) {
      return (0, _shipment.getCompanyShipments)(user, company, dispatch);
    }
  };
};

var Auth = function (_Component) {
  (0, _inherits3.default)(Auth, _Component);

  function Auth() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Auth);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Auth.__proto__ || Object.getPrototypeOf(Auth)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {}, _this.initializeData = function (user) {
      _firestore.subscriptions.unsubscribeAll();
      _this.props.getUser(user).then(function (_ref3) {
        var data = _ref3.data;

        _this.props.getShipments(user);
        _this.props.getAssignedShipments(user);
        _this.props.getCompanyInvites(user);
        _this.props.getAcceptedInvites(user);
      });
      _this.props.getCompanies(user.uid, function (companies) {
        companies.forEach(function (company) {
          _this.props.getCompanyShipments(user, company);
          // Get all user invites to the company
          _this.props.getUserInvites(company);
        });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Auth, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      (0, _firestore.auth)().onAuthStateChanged(function (user) {
        _this2.props.setAuthUser(user);
        if (!user || user === null) {
          _this2.setState({ isLoggedIn: false });
        } else {
          if (user.emailVerified === true) {
            _this2.initializeData(user);
            _this2.setState({ isLoggedIn: true });
          }
        }
      });
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      _firestore.subscriptions.unsubscribeAll();
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.state.isLoggedIn === false && (window.location.hash !== '#/privacy' || window.location.hash !== '#/cookies' || window.location.hash !== '#/contact')) {
        // return <Redirect to='/' />
      }
      return null;
    }
  }]);
  return Auth;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(Auth);
module.exports = exports['default'];