'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _RadioButton = require('../ui/RadioButton');

var _RadioButton2 = _interopRequireDefault(_RadioButton);

var _TextArea = require('../ui/TextArea');

var _TextArea2 = _interopRequireDefault(_TextArea);

var _Checkbox = require('../ui/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _Dropdown = require('../ui/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _InputSwitch = require('../ui/InputSwitch');

var _InputSwitch2 = _interopRequireDefault(_InputSwitch);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleGuide = function (_Component) {
  (0, _inherits3.default)(StyleGuide, _Component);

  function StyleGuide() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, StyleGuide);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = StyleGuide.__proto__ || Object.getPrototypeOf(StyleGuide)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      form: 'sign-in',
      email: '',
      password: '',
      loading: false,
      error: null,
      inputTextArea: ''
    }, _this.updateInputTextArea = function (e) {
      _this.setState({
        inputTextArea: e.target.value
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(StyleGuide, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_Header2.default, this.props),
        _react2.default.createElement(
          'div',
          { className: 'container' },
          _react2.default.createElement(
            'h1',
            null,
            'Transportsedeln'
          ),
          _react2.default.createElement(
            'h2',
            null,
            'Style Guide'
          ),
          _react2.default.createElement(
            'ul',
            null,
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Buttons'
              ),
              _react2.default.createElement(_Button2.default, {
                text: 'Primary',
                type: _Button.TYPES.PRIMARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL
              }),
              _react2.default.createElement(_Button2.default, {
                text: 'Primary disabled',
                type: _Button.TYPES.PRIMARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL,
                disabled: true
              })
            ),
            _react2.default.createElement('div', null),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Buttons'
              ),
              _react2.default.createElement(_Button2.default, {
                text: 'Secondary',
                type: _Button.TYPES.SECONDARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL
              }),
              _react2.default.createElement(_Button2.default, {
                text: 'Secondary disabled',
                type: _Button.TYPES.SECONDARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL,
                disabled: true
              })
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Buttons'
              ),
              _react2.default.createElement(_Button2.default, {
                text: 'Tertiary',
                type: _Button.TYPES.TERTIARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL
              }),
              _react2.default.createElement(_Button2.default, {
                text: 'Tertiary disabled',
                type: _Button.TYPES.TERTIARY,
                size: _Button.SIZES.LARGE,
                width: _Button.WIDTH.NORMAL,
                disabled: true
              })
            ),
            _react2.default.createElement('div', null),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Radio Buttons'
              ),
              _react2.default.createElement(_RadioButton2.default, null),
              _react2.default.createElement(_RadioButton2.default, {
                disabled: true
              })
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Text Inputs'
              ),
              _react2.default.createElement(_TextArea2.default, {
                value: this.state.inputTextArea,
                onChange: this.updateInputTextArea
              })
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Checkboxes'
              ),
              _react2.default.createElement(_Checkbox2.default, {
                labelText: 'testing' }),
              _react2.default.createElement(_Checkbox2.default, {
                disabled: true,
                labelText: 'testing' })
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Selects'
              ),
              _react2.default.createElement(_Dropdown2.default, null)
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'p',
                { style: { display: 'inline' } },
                'Switches'
              ),
              _react2.default.createElement(_InputSwitch2.default, null)
            )
          )
        )
      );
    }
  }]);
  return StyleGuide;
}(_react.Component);

exports.default = StyleGuide;
module.exports = exports['default'];