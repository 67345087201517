'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Expandable = require('../ui/Expandable');

var _Expandable2 = _interopRequireDefault(_Expandable);

var _Icon = require('../ui/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EmployeeListItem = function (_Component) {
  (0, _inherits3.default)(EmployeeListItem, _Component);

  function EmployeeListItem() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EmployeeListItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = EmployeeListItem.__proto__ || Object.getPrototypeOf(EmployeeListItem)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      open: false
    }, _this.toggle = function () {
      _this.setState({ open: !_this.state.open });
    }, _this.removeInvite = function () {
      var cb = _this.props.remove.bind(null, _this.props.data);
      cb.data = _this.props.data;
      _this.props.triggerModal(true, 'delete-invite', cb);
    }, _this.removeEmployee = function () {
      var cb = _this.props.remove.bind(null, _this.props.data, _this.props.company);
      cb.data = _this.props.data;
      cb.company = _this.props.company;
      _this.props.triggerModal(true, 'remove-employee', cb);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EmployeeListItem, [{
    key: 'employee',
    value: function employee(open) {
      var email = this.props.data.email;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'p',
          null,
          email
        ),
        _react2.default.createElement(
          'p',
          null,
          'Aktiv'
        ),
        _react2.default.createElement(_Icon2.default, { name: 'stroke-chevre-' + (open ? 'up' : 'down') })
      );
    }
  }, {
    key: 'statusText',
    value: function statusText(status) {
      switch (status) {
        case 'pending':
          return 'Inbjuden';
        default:
          return '';
      }
    }
  }, {
    key: 'invite',
    value: function invite(open) {
      var _props$data = this.props.data,
          receiver = _props$data.receiver,
          status = _props$data.status;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'p',
          { className: 'cursive' },
          receiver
        ),
        _react2.default.createElement(
          'p',
          { className: 'cursive' },
          this.statusText(status)
        ),
        _react2.default.createElement(
          'a',
          { onClick: this.removeInvite },
          _react2.default.createElement(_Icon2.default, { name: 'stroke-close-cross' })
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var open = this.state.open;
      var type = this.props.type;
      var _props$data2 = this.props.data,
          firstName = _props$data2.firstName,
          lastName = _props$data2.lastName,
          phone = _props$data2.phone;

      var clazz = open ? 'employees__item employees__item--' + type + ' employees__item--active' : 'employees__item employees__item--' + type;
      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { onClick: type === 'employee' ? this.toggle : function () {},
            className: clazz },
          this.content
        ),
        _react2.default.createElement(
          _Expandable2.default,
          { open: open },
          _react2.default.createElement(
            'div',
            { className: 'employees__info' },
            _react2.default.createElement(
              'p',
              null,
              'Namn: ',
              _react2.default.createElement(
                'span',
                null,
                firstName
              ),
              ' ',
              _react2.default.createElement(
                'span',
                null,
                lastName
              )
            ),
            _react2.default.createElement(
              'p',
              null,
              'Telefon: ',
              _react2.default.createElement(
                'span',
                null,
                phone
              )
            ),
            _react2.default.createElement(
              'a',
              { onClick: this.removeEmployee },
              _react2.default.createElement(_Icon2.default, { name: 'stroke-trashbin' })
            )
          )
        )
      );
    }
  }, {
    key: 'content',
    get: function get() {
      var open = this.state.open;

      switch (this.props.type) {
        case 'employee':
          {
            return this.employee(open);
          }
        case 'invite':
          {
            return this.invite(open);
          }
        default:
          return null;
      }
    }
  }]);
  return EmployeeListItem;
}(_react.Component);

exports.default = EmployeeListItem;
module.exports = exports['default'];