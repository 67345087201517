'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = exports.NumberField = exports.DatePicker = exports.DateField = exports.SearchForm = exports.TextArea = exports.TextField = exports.ButtonError = exports.ButtonPrimary = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactNumberFormat = require('react-number-format');

var _reactNumberFormat2 = _interopRequireDefault(_reactNumberFormat);

var _reactAutosizeTextarea = require('react-autosize-textarea');

var _reactAutosizeTextarea2 = _interopRequireDefault(_reactAutosizeTextarea);

var _reactDatepicker = require('react-datepicker');

var _reactDatepicker2 = _interopRequireDefault(_reactDatepicker);

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonPrimary = function ButtonPrimary(_ref) {
  var text = _ref.text,
      onClick = _ref.onClick,
      props = (0, _objectWithoutProperties3.default)(_ref, ['text', 'onClick']);
  return _react2.default.createElement(
    'button',
    (0, _extends3.default)({}, props, {
      className: 'btn btn--green',
      onClick: onClick }),
    text
  );
};

exports.ButtonPrimary = ButtonPrimary;
var ButtonError = function ButtonError(_ref2) {
  var text = _ref2.text,
      onClick = _ref2.onClick,
      props = (0, _objectWithoutProperties3.default)(_ref2, ['text', 'onClick']);
  return _react2.default.createElement(
    'button',
    (0, _extends3.default)({}, props, {
      className: 'btn btn--red',
      onClick: onClick }),
    text
  );
};

exports.ButtonError = ButtonError;
var TextField = function TextField(_ref3) {
  var icon = _ref3.icon,
      label = _ref3.label,
      value = _ref3.value,
      props = (0, _objectWithoutProperties3.default)(_ref3, ['icon', 'label', 'value']);

  return _react2.default.createElement(
    'div',
    { className: 'input-group' },
    icon ? _react2.default.createElement(_Icon2.default, { name: icon }) : null,
    label ? _react2.default.createElement(
      'label',
      null,
      label
    ) : null,
    _react2.default.createElement('input', (0, _extends3.default)({ className: 'input'
    }, props, {
      value: value,
      onChange: props.onChange
    }))
  );
};

exports.TextField = TextField;
var TextArea = function TextArea(_ref4) {
  var label = _ref4.label,
      props = (0, _objectWithoutProperties3.default)(_ref4, ['label']);

  return _react2.default.createElement(
    'div',
    { className: 'input-group input-group--area' },
    label ? _react2.default.createElement(
      'label',
      null,
      label
    ) : null,
    _react2.default.createElement(_reactAutosizeTextarea2.default, (0, _extends3.default)({
      className: 'input',
      style: { resize: 'vertical' },
      rows: props.rows || 3,
      onChange: props.onChange || function () {}
    }, props))
  );
};

exports.TextArea = TextArea;
var SearchForm = function SearchForm(_ref5) {
  var icon = _ref5.icon,
      buttonText = _ref5.buttonText,
      onSearch = _ref5.onSearch,
      props = (0, _objectWithoutProperties3.default)(_ref5, ['icon', 'buttonText', 'onSearch']);
  return _react2.default.createElement(
    'form',
    { onSubmit: onSearch, className: 'search-form' },
    _react2.default.createElement('input', (0, _extends3.default)({ className: 'input' }, props, { type: 'text' })),
    _react2.default.createElement(ButtonPrimary, { text: buttonText, type: 'submit' })
  );
};

exports.SearchForm = SearchForm;
var DateField = function DateField(_ref6) {
  var value = _ref6.value,
      label = _ref6.label,
      props = (0, _objectWithoutProperties3.default)(_ref6, ['value', 'label']);
  return _react2.default.createElement(
    'div',
    { className: 'input-group' },
    _react2.default.createElement(
      'label',
      null,
      label
    ),
    _react2.default.createElement(_reactNumberFormat2.default, (0, _extends3.default)({}, props, {
      format: '####-##-## ##:##',
      placeholder: 'YYYY-MM-DD HH:MM',
      mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D', 'H', 'H', 'M', 'M'],
      value: value,
      className: 'input',
      onChange: props.onChange
    }))
  );
};

exports.DateField = DateField;
var DatePicker = function DatePicker(_ref7) {
  var icon = _ref7.icon,
      label = _ref7.label,
      value = _ref7.value,
      props = (0, _objectWithoutProperties3.default)(_ref7, ['icon', 'label', 'value']);
  return _react2.default.createElement(
    'div',
    { className: 'input-group' },
    icon ? _react2.default.createElement(_Icon2.default, { name: icon }) : null,
    label ? _react2.default.createElement(
      'label',
      null,
      label
    ) : null,
    _react2.default.createElement(_reactDatepicker2.default, (0, _extends3.default)({ className: 'input'
    }, props, {
      value: value,
      selected: value ? new Date(value) : new Date(),
      onChange: props.onChange,
      showTimeSelect: true,
      timeFormat: 'HH:mm',
      timeIntervals: 15,
      dateFormat: 'yyyy-MM-dd hh:mm',
      timeCaption: 'time'
    }))
  );
};

exports.DatePicker = DatePicker;
var NumberField = function NumberField(_ref8) {
  var value = _ref8.value,
      label = _ref8.label,
      _ref8$decimalScale = _ref8.decimalScale,
      decimalScale = _ref8$decimalScale === undefined ? 1 : _ref8$decimalScale,
      props = (0, _objectWithoutProperties3.default)(_ref8, ['value', 'label', 'decimalScale']);
  return _react2.default.createElement(
    'div',
    { className: 'input-group' },
    _react2.default.createElement(
      'label',
      null,
      label
    ),
    _react2.default.createElement(_reactNumberFormat2.default, (0, _extends3.default)({
      className: 'input',
      decimalScale: decimalScale
    }, props, {
      value: "'" + value + "'" // hack, force value to string :)
      , onChange: props.onChange
    }))
  );
};

exports.NumberField = NumberField;
var Checkbox = function Checkbox(_ref9) {
  var checked = _ref9.checked,
      label = _ref9.label,
      id = _ref9.id,
      props = (0, _objectWithoutProperties3.default)(_ref9, ['checked', 'label', 'id']);
  return _react2.default.createElement(
    'div',
    { className: 'input-group input-group--checkbox' },
    _react2.default.createElement(
      'label',
      { htmlFor: id },
      label
    ),
    _react2.default.createElement('input', (0, _extends3.default)({}, props, {
      id: id,
      type: 'checkbox',
      checked: checked,
      onChange: props.onChange
    }))
  );
};
exports.Checkbox = Checkbox;