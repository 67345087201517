'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _redux = require('redux');

var _reduxThunk = require('redux-thunk');

var _reduxThunk2 = _interopRequireDefault(_reduxThunk);

var _reactRouterRedux = require('react-router-redux');

require('./firestore');

var _reducers = require('../reducers');

var _reducers2 = _interopRequireDefault(_reducers);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var reduxDevTool = function reduxDevTool() {
  return (typeof window === 'undefined' ? 'undefined' : (0, _typeof3.default)(window)) === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : function (f) {
    return f;
  };
};

exports.default = function () {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var history = arguments[1];

  var middleware = [(0, _reactRouterRedux.routerMiddleware)(history), _reduxThunk2.default];

  var enhancers = [_redux.applyMiddleware.apply(undefined, middleware)];

  if (process.env.NODE_ENV !== 'production') {
    enhancers = [].concat((0, _toConsumableArray3.default)(enhancers), [reduxDevTool()]);
  }

  var configureStore = _redux.compose.apply(undefined, (0, _toConsumableArray3.default)(enhancers))(_redux.createStore);

  var store = configureStore(_reducers2.default, initialState);
  return store;
};

module.exports = exports['default'];