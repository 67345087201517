'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputSwitch = function InputSwitch(_ref) {
  var inputType = _ref.inputType,
      title = _ref.title,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['inputType', 'title']);
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      'div',
      { className: 'onoffswitch' },
      _react2.default.createElement('input', { type: 'checkbox', name: 'onoffswitch',
        className: 'onoffswitch-checkbox', id: 'myonoffswitch' }),
      _react2.default.createElement(
        'label',
        { className: 'onoffswitch-label', htmlFor: 'myonoffswitch' },
        _react2.default.createElement('span', { className: 'onoffswitch-inner' }),
        _react2.default.createElement('span', { className: 'onoffswitch-switch' })
      )
    ),
    _react2.default.createElement(
      'span',
      { className: 'onoffswitch__title' },
      title
    )
  );
};

exports.default = InputSwitch;
module.exports = exports['default'];