'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactMotion = require('react-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var root = document.getElementById('modal-root');

var Modal = function (_Component) {
  (0, _inherits3.default)(Modal, _Component);

  function Modal(props) {
    (0, _classCallCheck3.default)(this, Modal);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Modal.__proto__ || Object.getPrototypeOf(Modal)).call(this, props));

    _this.el = document.createElement('div');
    return _this;
  }

  (0, _createClass3.default)(Modal, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      root.appendChild(this.el);
    }
  }, {
    key: 'componentWillUpdate',
    value: function componentWillUpdate() {
      this.scrollPos = { x: window.pageXOffset, y: window.pageYOffset };
      this.hasVScroll = { scroll: document.body.scrollHeight };
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prev) {
      if (prev.show !== this.props.show) {
        window.scroll(this.scrollPos.x, this.scrollPos.y);
        if (document.body.scrollHeight > this.hasVScroll.scroll) {
          document.body.style.paddingRight = '15px';
        }
      }

      if (!this.props.show) {
        document.body.style = undefined;
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      root.removeChild(this.el);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var show = this.props.show;

      return (0, _reactDom.createPortal)(_react2.default.createElement(
        'div',
        { className: 'modal', style: { zIndex: show ? 3 : -1 } },
        _react2.default.createElement(
          _reactMotion.Motion,
          { defaultStyle: { marginTop: -350, opacity: 0 },
            style: { marginTop: (0, _reactMotion.spring)(show ? 1 : -350, { stiffness: 200 }) } },
          function (styles) {
            return _react2.default.createElement(
              'div',
              {
                style: {
                  marginTop: styles.marginTop,
                  display: styles.marginTop < -230 ? 'none' : 'block',
                  opacity: styles.marginTop < -30 ? 0 : 1
                }
              },
              _react2.default.createElement(
                'div',
                { className: 'modal__content ' + _this2.props.className,
                  'aria-hidden': show === false },
                _this2.props.children
              )
            );
          }
        )
      ), this.el);
    }
  }]);
  return Modal;
}(_react.Component);

Modal.defaultProps = {
  className: ''
};
exports.default = Modal;
module.exports = exports['default'];