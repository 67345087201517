'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _ModalBox = require('../../ui/ModalBox');

var _ModalBox2 = _interopRequireDefault(_ModalBox);

var _dateformatter = require('../../utils/dateformatter');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GoodsList = function (_Component) {
  (0, _inherits3.default)(GoodsList, _Component);

  function GoodsList() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, GoodsList);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = GoodsList.__proto__ || Object.getPrototypeOf(GoodsList)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      removeGoodsDialogVisible: false,
      imageDetailsVisible: false,
      imageDetails: {}
    }, _this.handleAddButton = function () {
      _this.props.history.push('/newgoods/' + _this.props.shipment.uid);
    }, _this.editGoods = function (index) {
      _this.props.history.push('/editgoods/' + _this.props.shipment.uid + '/' + index);
    }, _this.remove = function (index) {
      // ('GoodsList remove')
      _this.props.onRemove(index);
      _this.toggleRemoveDialog();
    }, _this.toggleRemoveDialog = function (e) {
      if (e) {
        e.preventDefault();
      }
      _this.setState({
        removeGoodsDialogVisible: !_this.state.removeGoodsDialogVisible
      });
    }, _this.showImageDetails = function (image) {
      _this.setState({
        imageDetails: image,
        imageDetailsVisible: true
      });
    }, _this.hideImageDetails = function (e) {
      _this.setState({
        imageDetailsVisible: false
      });
    }, _this.item = function (goods, index) {
      // hide edit/remove if state is signed or invoiced
      var buttons = void 0;
      if (_this.props.showEditLinks) {
        buttons = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(_Button2.default, {
            type: _Button.TYPES.SECONDARY,
            size: _Button.SIZES.SMALL,
            text: '\xC4ndra',
            onClick: function onClick() {
              return _this.editGoods(index);
            } }),
          _react2.default.createElement(_Button2.default, {
            type: _Button.TYPES.TERTIARY,
            size: _Button.SIZES.SMALL,
            text: 'Ta bort',
            onClick: _this.toggleRemoveDialog }),
          _react2.default.createElement(
            _ModalBox2.default,
            {
              title: 'Radera gods',
              visibility: _this.state.removeGoodsDialogVisible,
              toggle: _this.toggleRemoveDialog
            },
            _react2.default.createElement(
              'p',
              null,
              'Vill du radera gods ',
              goods.typeOfGoods,
              '?'
            ),
            _react2.default.createElement(
              'div',
              { className: 'modal__btn-right' },
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.SECONDARY,
                text: 'Nej',
                onClick: _this.toggleRemoveDialog
              }),
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.PRIMARY,
                text: 'Ja, radera godset',
                onClick: function onClick() {
                  return _this.remove(index);
                }
              })
            )
          )
        );
      }

      var images = void 0;
      if (goods.images && goods.images.length > 0) {
        // TODO remove inline styles
        var styles = { width: '430px' };
        images = _react2.default.createElement(
          'div',
          { className: 'form-row', style: styles },
          _react2.default.createElement(
            'h4',
            null,
            'Bilder'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'img-list' },
            goods.images.map(function (image, key) {
              return _react2.default.createElement(
                'li',
                { key: key, className: 'img-list__item' },
                _react2.default.createElement('img', { src: image.urlMedium, height: '120px', onClick: function onClick() {
                    return _this.showImageDetails(image);
                  } })
              );
            })
          )
        );
      }

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement('input', { type: 'checkbox', name: 'panel', id: 'panel-' + index }),
        _react2.default.createElement(
          'label',
          { htmlFor: 'panel-' + index },
          _react2.default.createElement(
            'span',
            null,
            goods.typeOfGoods ? goods.typeOfGoods : 'Gods',
            ' '
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'accordion__content' },
          _react2.default.createElement(
            'ul',
            { className: 'list' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'P\xE5g\xE5r'
              ),
              _react2.default.createElement(
                'span',
                null,
                (0, _dateformatter.formatDateWithMinutes)(goods.startDate),
                ' - ',
                (0, _dateformatter.formatDateWithMinutes)(goods.endDate)
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                '\xD6vertidstimmar'
              ),
              _react2.default.createElement(
                'span',
                null,
                goods.overtimeHours
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Antal'
              ),
              _react2.default.createElement(
                'span',
                null,
                goods.quantity
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Enhetspris (kr)'
              ),
              _react2.default.createElement(
                'span',
                null,
                goods.unitPrice
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Avgift (kr)'
              ),
              _react2.default.createElement(
                'span',
                null,
                _this.props.fees
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Totalpris (kr)'
              ),
              _react2.default.createElement(
                'span',
                null,
                (goods.quantity ? goods.quantity : 0) * (goods.unitPrice ? goods.unitPrice : 0),
                ' + ',
                (goods.overtimeHours ? goods.overtimeHours : 0) * (_this.props.overtimePrice ? _this.props.overtimePrice : 0),
                ' + ',
                _this.props.fees ? _this.props.fees : 0,
                ' = ',
                (goods.quantity ? goods.quantity : 0) * (goods.unitPrice ? goods.unitPrice : 0) + (goods.overtimeHours ? goods.overtimeHours : 0) * (_this.props.overtimePrice ? _this.props.overtimePrice : 0) + (_this.props.fees ? _this.props.fees * 1 : 0)
              )
            )
          ),
          _react2.default.createElement(
            'h4',
            null,
            'Beskrivning'
          ),
          _react2.default.createElement(
            'p',
            null,
            goods.description
          ),
          images,
          buttons
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Bildinformation',
            visibility: _this.state.imageDetailsVisible,
            toggle: _this.hideImageDetails
          },
          _react2.default.createElement('img', { src: _this.state.imageDetails.urlMedium, width: '600' }),
          _react2.default.createElement(
            'a',
            { href: _this.state.imageDetails.urlMedium },
            'Ladda ned bilden'
          ),
          _this.state.imageDetails.coordLat && _this.state.imageDetails.coordLng ? _react2.default.createElement(
            'span',
            null,
            ', ',
            _react2.default.createElement(
              'a',
              { target: '_blank', href: 'https://www.google.com/maps?ll=' + _this.state.imageDetails.coordLat + ',' + _this.state.imageDetails.coordLng },
              'L\xE4nk till Google Maps'
            )
          ) : ''
        )
      );
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(GoodsList, [{
    key: 'render',
    value: function render() {
      // hide 'add goods' if state is signed or invoiced
      var link = void 0;
      if (this.props.showEditLinks) {
        link = _react2.default.createElement(
          'a',
          { className: 'link right', onClick: this.handleAddButton },
          'L\xE4gg till'
        );
      }

      return _react2.default.createElement(
        _react.Fragment,
        null,
        this.redirectNew,
        _react2.default.createElement(
          'div',
          { className: 'form__header' },
          link,
          _react2.default.createElement(
            'h2',
            null,
            'Gods'
          )
        ),
        this.list
      );
    }
  }, {
    key: 'list',
    get: function get() {
      var _this2 = this;

      var goods = this.props.goods;

      return _react2.default.createElement(
        'ul',
        { className: 'accordion' },
        goods.map(function (g, i) {
          return _react2.default.createElement(
            'li',
            { className: 'accordion__item', key: i },
            _this2.item(g, i)
          );
        })
      );
    }
  }]);
  return GoodsList;
}(_react.Component);

GoodsList.defaultProps = {
  goods: [],
  onAdd: function onAdd() {},
  onRemove: function onRemove() {},
  onChange: function onChange() {}
};
exports.default = GoodsList;
module.exports = exports['default'];