'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var formatDateToTimestamp = exports.formatDateToTimestamp = function formatDateToTimestamp(dateStr) {
  return dateStr !== '' ? Date.parse(dateStr) : null;
};

var formatDateWithSeconds = exports.formatDateWithSeconds = function formatDateWithSeconds(dateStr) {
  try {
    var mDate = new Date(dateStr);
    var offsetMs = mDate.getTimezoneOffset() * 60 * 1000;
    var dateLocal = new Date(mDate.getTime() - offsetMs);
    var formattedDate = dateLocal.toISOString().slice(0, 19).replace('T', ' ');

    return formattedDate;
  } catch (error) {
    console.log('Error formating date: ' + dateStr);
    return '';
  }
};

var formatDateWithMinutes = exports.formatDateWithMinutes = function formatDateWithMinutes(dateStr) {
  try {
    var mDate = new Date(dateStr);
    var offsetMs = mDate.getTimezoneOffset() * 60 * 1000;
    var dateLocal = new Date(mDate.getTime() - offsetMs);
    var formattedDate = dateLocal.toISOString().slice(0, 16).replace('T', ' ');

    return formattedDate;
  } catch (error) {
    console.log('Error formating date: ' + dateStr);
    return '';
  }
};

var formatDate = exports.formatDate = function formatDate(dateStr) {
  try {
    var mDate = new Date(dateStr);
    var offsetMs = mDate.getTimezoneOffset() * 60 * 1000;
    var dateLocal = new Date(mDate.getTime() - offsetMs);
    var formattedDate = dateLocal.toISOString().slice(0, 10).replace('T', ' ');

    return formattedDate;
  } catch (error) {
    console.log('Error formating date: ' + dateStr);
    return '';
  }
};