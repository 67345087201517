'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TabList = function (_Component) {
  (0, _inherits3.default)(TabList, _Component);

  function TabList() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, TabList);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = TabList.__proto__ || Object.getPrototypeOf(TabList)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      current: 0
    }, _this.select = function (index) {
      _this.setState({ current: index });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(TabList, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var current = this.state.current;
      var tabs = this.props.tabs;


      var tab = tabs.length === 0 ? null : tabs[current];

      return _react2.default.createElement(
        'div',
        { className: 'tabs' },
        _react2.default.createElement(
          'ul',
          { className: 'tabs__list' },
          tabs.map(function (t, i) {
            return _react2.default.createElement(
              'li',
              { className: i === current ? 'tabs__list-item tabs__list-item--active' : 'tabs__list-item', key: i },
              _react2.default.createElement(
                'a',
                { onClick: _this2.select.bind(_this2, i) },
                t.title
              )
            );
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'tabs__content' },
          tab !== null ? tab.component() : null
        )
      );
    }
  }]);
  return TabList;
}(_react.Component);

exports.default = TabList;
module.exports = exports['default'];