'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WIDTH = exports.TYPES = exports.SIZES = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SIZES = exports.SIZES = {
  MINI: 'btn--mini',
  SMALL: 'btn--small',
  LARGE: 'btn--large'
};

var TYPES = exports.TYPES = {
  PRIMARY: 'btn--primary',
  SECONDARY: 'btn--secondary',
  TERTIARY: 'btn--tertiary'
};

var WIDTH = exports.WIDTH = {
  NORMAL: 'btn--normal-width',
  FULL: 'btn--full-width'
};

var Button = function Button(_ref) {
  var type = _ref.type,
      size = _ref.size,
      text = _ref.text,
      width = _ref.width,
      hidden = _ref.hidden,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['type', 'size', 'text', 'width', 'hidden']);
  return _react2.default.createElement(
    'button',
    (0, _extends3.default)({
      className: ['btn', type || TYPES.PRIMARY, size || SIZES.LARGE, width || WIDTH.NORMAL, hidden === true ? 'hidden' : ''].join(' ') }, rest),
    text
  );
};

exports.default = Button;