'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSelectedShipment = exports.updateShipment = exports.setAsRemoved = exports.getShipmentById = exports.getCompanyShipments = exports.getAssignedShipments = exports.getShipments = exports.removeGoodsImage = exports.toggleTestingTwo = exports.toggleTesting = exports.invoiceShipment = exports.acceptShipment = exports.sendShipment = exports.INVOICE_SHIPMENT = exports.TOGGLE_TESTING_TWO = exports.TOGGLE_TESTING = exports.SEND_SHIPMENT_PDF_ERROR = exports.SEND_SHIPMENT_PDF_SUCCESS = exports.SIGN_SHIPMENT = exports.SET_REMOVED_ERROR = exports.SET_REMOVED_SUCCESS = exports.CREATE_SHIPMENT = exports.UPDATE_SHIPMENT = exports.SET_SELECTED_SHIPMENT = exports.GET_COMPANY_SHIPMENTS = exports.GET_ASSIGNED_SHIPMENTS = exports.GET_SHIPMENTS = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _firestore = require('../store/firestore');

var _filehandling = require('../api/filehandling');

var _index = require('../api/index');

var _webpackVariables = require('webpack-variables');

var _dateformatter = require('../components/utils/dateformatter');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GET_SHIPMENTS = exports.GET_SHIPMENTS = 'GET_SHIPMENTS';
var GET_ASSIGNED_SHIPMENTS = exports.GET_ASSIGNED_SHIPMENTS = 'GET_ASSIGNED_SHIPMENTS';
var GET_COMPANY_SHIPMENTS = exports.GET_COMPANY_SHIPMENTS = 'GET_COMPANY_SHIPMENTS';
var SET_SELECTED_SHIPMENT = exports.SET_SELECTED_SHIPMENT = 'SET_SELECTED_SHIPMENT';
var UPDATE_SHIPMENT = exports.UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
var CREATE_SHIPMENT = exports.CREATE_SHIPMENT = 'CREATE_SHIPMENT';
var SET_REMOVED_SUCCESS = exports.SET_REMOVED_SUCCESS = 'SET_REMOVED_SUCCESS';
var SET_REMOVED_ERROR = exports.SET_REMOVED_ERROR = 'SET_REMOVED_ERROR';
var SIGN_SHIPMENT = exports.SIGN_SHIPMENT = 'SIGN_SHIPMENT';
var SEND_SHIPMENT_PDF_SUCCESS = exports.SEND_SHIPMENT_PDF_SUCCESS = 'SEND_SHIPMENT_PDF_SUCCESS';
var SEND_SHIPMENT_PDF_ERROR = exports.SEND_SHIPMENT_PDF_ERROR = 'SEND_SHIPMENT_PDF_ERROR';
var TOGGLE_TESTING = exports.TOGGLE_TESTING = 'TOGGLE_TESTING';
var TOGGLE_TESTING_TWO = exports.TOGGLE_TESTING_TWO = 'TOGGLE_TESTING_TWO';
var INVOICE_SHIPMENT = exports.INVOICE_SHIPMENT = 'INVOICE_SHIPMENT';

var sendShipment = exports.sendShipment = function sendShipment(id, email) {
  return (0, _index.getUrl)('https://europe-west1-' + _webpackVariables.FS_PROJECT_ID + '.cloudfunctions.net/sendShipmentReport', { id: id, email: email }).then(function (res) {
    var updatedShipmentFields = {
      'mailedAt': (0, _dateformatter.formatDateToTimestamp)(new Date()),
      'mailedTo': email
    };
    return _firestore.db.collection('shipments').doc(id).update(updatedShipmentFields).then(function () {
      return {
        type: SEND_SHIPMENT_PDF_SUCCESS,
        data: updatedShipmentFields
      };
    });
  }).catch(function (error) {
    return {
      type: SEND_SHIPMENT_PDF_ERROR,
      data: error
    };
  });
};

var acceptShipment = exports.acceptShipment = function acceptShipment(ref) {
  var updatedShipmentFields = {
    'state': 'approved',
    'signature.signedAt': (0, _dateformatter.formatDateToTimestamp)(new Date())
  };
  return _firestore.db.collection('shipments').doc(ref).update(updatedShipmentFields).then(function (data) {
    return {
      type: SIGN_SHIPMENT,
      data: updatedShipmentFields
    };
  });
};

var invoiceShipment = exports.invoiceShipment = function invoiceShipment(ref) {
  var updatedShipmentFields = {
    'state': 'invoiced'
  };
  return _firestore.db.collection('shipments').doc(ref).update(updatedShipmentFields).then(function (data) {
    return {
      type: INVOICE_SHIPMENT,
      data: updatedShipmentFields
    };
  });
};

var toggleTesting = exports.toggleTesting = function toggleTesting(dispatch) {
  return dispatch({ type: TOGGLE_TESTING });
};

var toggleTestingTwo = exports.toggleTestingTwo = function toggleTestingTwo() {
  return { type: TOGGLE_TESTING_TWO };
};

var removeGoodsImage = exports.removeGoodsImage = function removeGoodsImage(url, shipment, dispatch) {
  return (0, _filehandling.remove)(url).then(function (success) {
    shipment.goods.forEach(function (goods) {
      var index = goods.images.indexOf(url);
      if (index !== -1) {
        goods.images.splice(index, 1);
      }
    });
    return update(shipment);
  }).catch(function (error) {
    console.log('RemoveGoodsImage Error', error);
  });
};

var getShipments = exports.getShipments = function getShipments(user, dispatch) {
  return _firestore.db.collection('shipments').where('assignedTo.uid', '==', user.uid).where('companyId', '==', user.uid).where('removed', '==', false).onSnapshot(function (snapshot) {
    var shipments = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({ uid: s.id }, s.data());
    });
    return dispatch({
      type: GET_SHIPMENTS,
      data: shipments
    });
  });
};

var getAssignedShipments = exports.getAssignedShipments = function getAssignedShipments(user, company, dispatch) {
  return _firestore.db.collection('shipments').where('assignedTo.uid', '==', user.uid).where('companyId', '<', user.uid).onSnapshot(function (snapshot) {
    var shipments1 = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({ uid: s.id }, s.data());
    });

    return _firestore.db.collection('shipments').where('assignedTo.uid', '==', user.uid).where('companyId', '>', user.uid).where('removed', '==', false).onSnapshot(function (snapshot) {
      var shipments2 = snapshot.docs.map(function (s) {
        return (0, _extends3.default)({ uid: s.id }, s.data());
      });
      var merged = shipments1.concat(shipments2);
      return dispatch({
        type: GET_ASSIGNED_SHIPMENTS,
        data: merged
      });
    });
  });
};

var getCompanyShipments = exports.getCompanyShipments = function getCompanyShipments(user, company, dispatch) {
  var refString = 'users/' + user.uid + '/companies/' + company.uid;
  return _firestore.db.collection('shipments').where('removed', '==', false).where('companyId', '==', refString).onSnapshot(function (snapshot) {
    var shipments1 = snapshot.docs.map(function (s) {
      return (0, _extends3.default)({ uid: s.id }, s.data());
    });

    return _firestore.db.collection('shipments').where('removed', '==', false).where('contractorCompany', '==', refString).onSnapshot(function (snapshot) {
      var shipments2 = snapshot.docs.map(function (s) {
        return (0, _extends3.default)({ uid: s.id }, s.data());
      });
      var merged = shipments1.concat(shipments2);
      return dispatch({
        type: GET_COMPANY_SHIPMENTS,
        data: merged
      });
    });
  });
};

var getShipmentById = exports.getShipmentById = function getShipmentById(uid) {
  return _firestore.db.collection('shipments').doc(uid).get().then(function (snap) {
    return {
      type: SET_SELECTED_SHIPMENT,
      data: (0, _extends3.default)({ uid: snap.id }, snap.data())
    };
  });
};

var create = function create(data) {
  var newShipment = (0, _extends3.default)({}, data);

  return _firestore.db.collection('shipments').add(newShipment).then(function (snap) {
    return {
      type: UPDATE_SHIPMENT,
      id: snap.id,
      data: newShipment
    };
  });
};

var update = function update(shipmentData) {
  return _firestore.db.collection('shipments').doc(shipmentData.uid).set((0, _extends3.default)({}, shipmentData), { merge: true }).then(function (data) {
    return {
      type: UPDATE_SHIPMENT,
      data: shipmentData
    };
  });
};

var setAsRemoved = exports.setAsRemoved = function setAsRemoved(uid) {
  return _firestore.db.collection('shipments').doc(uid).set({ removed: true }, { merge: true }).then(function (data) {
    return {
      type: SET_REMOVED_SUCCESS,
      data: data
    };
  }).catch(function (error) {
    return {
      type: SET_REMOVED_ERROR,
      data: error
    };
  });
};

var updateShipment = exports.updateShipment = function updateShipment() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  return data.uid === undefined ? create(data) : update(data);
};

var setSelectedShipment = exports.setSelectedShipment = function setSelectedShipment(shipment, dispatch) {
  return dispatch({
    type: SET_SELECTED_SHIPMENT,
    data: shipment
  });
};