'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ShipmentItem = require('./ShipmentItem');

var _ShipmentItem2 = _interopRequireDefault(_ShipmentItem);

var _ShipmentListHeader = require('./ShipmentListHeader');

var _ShipmentListHeader2 = _interopRequireDefault(_ShipmentListHeader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShipmentList = function ShipmentList(props) {
  return _react2.default.createElement(
    'ul',
    { className: 'shipment-list' },
    _react2.default.createElement(_ShipmentListHeader2.default, { orderBy: function orderBy() {} }),
    props.shipments.length > 0 ? props.shipments.map(function (s, i) {
      return _react2.default.createElement(_ShipmentItem2.default, (0, _extends3.default)({
        key: i,
        data: s
      }, props));
    }) : _react2.default.createElement(
      'div',
      { className: 'text-center shipment-img' },
      _react2.default.createElement('img', { src: 'images/illustration-aloneshipment.svg', alt: 'Shipment' }),
      _react2.default.createElement(
        'h4',
        null,
        'H\xE4r finns inga sedlar'
      )
    )
  );
};

exports.default = ShipmentList;
module.exports = exports['default'];