'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TSForm = require('../../ui/TSForm');

var _uidHelper = require('../../utils/uidHelper');

var _dateformatter = require('../../utils/dateformatter');

var _Button = require('../../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AddGoodsForm = function (_Component) {
  (0, _inherits3.default)(AddGoodsForm, _Component);

  function AddGoodsForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, AddGoodsForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = AddGoodsForm.__proto__ || Object.getPrototypeOf(AddGoodsForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      goods: {
        overtimeHours: '',
        startDate: '',
        endDate: '',
        typeOfGoods: '',
        quantity: '',
        unitPrice: '',
        description: '',
        unit: '',
        uid: ''
        // images: ''
      }
    }, _this.add = function () {
      var newGoods = {
        overtimeHours: _this.state.overtimeHours,
        typeOfGoods: _this.state.typeOfGoods,
        quantity: _this.state.quantity,
        unitPrice: _this.state.unitPrice,
        description: _this.state.description,
        unit: _this.state.unit,
        uid: (0, _uidHelper.createUid)()
      };

      if (_this.state.startDate !== '') {
        newGoods.startDate = (0, _dateformatter.formatDateToTimestamp)(_this.state.startDate);
      }

      if (_this.state.endDate !== '') {
        newGoods.endDate = (0, _dateformatter.formatDateToTimestamp)(_this.state.endDate);
      }

      _this.props.onAdd(newGoods);
    }, _this.addButton = function () {
      _this.add(_this.state.goods).then(function () {
        return _this.props.updateShipment();
      });
      //this.props.onAdd(this.state.goods).then(() => this.props.updateShipment())
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.goods[prop] = target.value;
      _this.setState(_this.state.goods);
    }, _this.setFieldAndReplace = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state.goods[prop] = target.value.replace(',', '.');
      _this.setState(_this.state.goods);
    }, _this.setDateField = function (prop, e) {
      _this.state.goods[prop] = (0, _dateformatter.formatDateWithMinutes)(e.toISOString());
      _this.setState(_this.state.goods);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(AddGoodsForm, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: 'l-two-col__form' },
          _react2.default.createElement(
            'h2',
            null,
            'Gods'
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Godstyp'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'typeOfGoods',
              inputType: 'text',
              value: this.state.goods.typeOfGoods,
              onChange: function onChange(e) {
                return _this2.setField('typeOfGoods', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Datum start'
            ),
            _react2.default.createElement(_TSForm.DatePicker, {
              name: 'startDate',
              value: this.state.goods.startDate,
              onChange: function onChange(e) {
                return _this2.setDateField('startDate', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Datum slut'
            ),
            _react2.default.createElement(_TSForm.DatePicker, {
              name: 'endDate',
              value: this.state.goods.endDate,
              onChange: function onChange(e) {
                return _this2.setDateField('endDate', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Antal'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'quantity',
              inputType: 'text',
              value: this.state.goods.quantity,
              onChange: function onChange(e) {
                return _this2.setFieldAndReplace('quantity', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Enhet'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'fromAddress',
              inputType: 'text',
              value: this.state.goods.unit,
              onChange: function onChange(e) {
                return _this2.setField('unit', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Enhetspris'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'unitPrice',
              inputType: 'text',
              value: this.state.goods.unitPrice,
              onChange: function onChange(e) {
                return _this2.setFieldAndReplace('unitPrice', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              '\xD6vertid'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: this.state.goods.overtimeHours,
              onChange: function onChange(e) {
                return _this2.setFieldAndReplace('overtimeHours', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Beskrivning'
            ),
            _react2.default.createElement(_TSForm.TextArea, {
              name: 'description',
              inputType: 'text',
              value: this.state.goods.description,
              rows: '5',
              onChange: function onChange(e) {
                return _this2.setField('description', e);
              }
            })
          ),
          _react2.default.createElement(_Button2.default, {
            onClick: this.addButton,
            type: _Button.TYPES.PRIMARY,
            text: 'L\xE4gg till nytt gods'
          })
        )
      );
    }
  }]);
  return AddGoodsForm;
}(_react.Component);

AddGoodsForm.defaultProps = {
  goods: [],
  onAdd: function onAdd() {},
  onRemove: function onRemove() {},
  onChange: function onChange() {}
};
exports.default = AddGoodsForm;
module.exports = exports['default'];