'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _redux = require('redux');

var _reactRouterRedux = require('react-router-redux');

var _user = require('./user');

var _user2 = _interopRequireDefault(_user);

var _shipment = require('./shipment');

var _shipment2 = _interopRequireDefault(_shipment);

var _company = require('./company');

var _company2 = _interopRequireDefault(_company);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _redux.combineReducers)({
  router: _reactRouterRedux.routerReducer,
  user: _user2.default,
  shipment: _shipment2.default,
  company: _company2.default
});
module.exports = exports['default'];