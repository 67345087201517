'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _EditShipmentForm = require('../shipments/EditShipmentForm');

var _EditShipmentForm2 = _interopRequireDefault(_EditShipmentForm);

var _shipment = require('../../actions/shipment');

var _company = require('../../actions/company');

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var shipment = _ref.shipment,
      user = _ref.user,
      company = _ref.company;
  return {
    shipment: shipment, user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    updateShipment: function updateShipment(data) {
      return (0, _shipment.updateShipment)(data).then(dispatch);
    },
    setAsRemoved: function setAsRemoved(id) {
      return (0, _shipment.setAsRemoved)(id).then(dispatch).catch(dispatch);
    },
    getShipmentById: function getShipmentById(uid) {
      return (0, _shipment.getShipmentById)(uid).then(dispatch);
    },
    getEmployeeUsers: function getEmployeeUsers(company) {
      return (0, _company.getEmployeeUsers)(company).then(dispatch);
    },
    setSelectedShipment: function setSelectedShipment(shipment) {
      return (0, _shipment.setSelectedShipment)(shipment, dispatch);
    },
    removeGoodsImage: function removeGoodsImage(ref, goods) {
      return (0, _shipment.removeGoodsImage)(ref, goods).then(dispatch);
    },
    acceptShipment: function acceptShipment(ref) {
      return (0, _shipment.acceptShipment)(ref).then(dispatch);
    },
    sendShipment: function sendShipment(id, email) {
      return (0, _shipment.sendShipment)(id, email).then(dispatch);
    }
  };
};

var EditShipment = function (_Component) {
  (0, _inherits3.default)(EditShipment, _Component);

  function EditShipment() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditShipment);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = EditShipment.__proto__ || Object.getPrototypeOf(EditShipment)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      edit: false
    }, _this.redirectToShipment = function () {
      _this.props.history.push('/shipment/' + _this.props.match.params.id);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditShipment, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var params = this.props.match.params;

      if (params.id) {
        this.props.getShipmentById(params.id);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      var _props = this.props,
          updateShipment = _props.updateShipment,
          history = _props.history,
          user = _props.user,
          company = _props.company,
          setAsRemoved = _props.setAsRemoved;


      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { onClick: this.redirectToShipment },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'container l-two-col' },
          _react2.default.createElement(_EditShipmentForm2.default, {
            update: updateShipment,
            remove: setAsRemoved,
            history: history,
            user: user,
            company: company,
            shipmentId: this.props.match.params.id,
            getShipmentById: this.props.getShipmentById,
            handleUpdateButton: this.handleUpdateButton,
            redirectToShipment: this.redirectToShipment
          }),
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__img' },
            _react2.default.createElement('img', { src: 'images/illustration-shipments2.svg', alt: 'Shipmentbills' })
          )
        )
      );
    }
  }]);
  return EditShipment;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(EditShipment);
module.exports = exports['default'];