"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Subscriptions = function () {
  function Subscriptions() {
    (0, _classCallCheck3.default)(this, Subscriptions);
    this.subs = [];
  }

  (0, _createClass3.default)(Subscriptions, [{
    key: "add",
    value: function add(name, sub) {
      this.subs.push({ name: name, unsubscribe: sub });
      return sub;
    }
  }, {
    key: "unsubscribe",
    value: function unsubscribe(name) {
      this.subs.forEach(function (s) {
        if (s.name === name) {
          s.unsubscribe();
        }
      });
      this.subs = this.subs.filter(function (s) {
        return s.name !== name;
      });
    }
  }, {
    key: "unsubscribeAll",
    value: function unsubscribeAll() {
      var _this = this;

      this.subs.forEach(function (s) {
        return _this.unsubscribe(s.name);
      });
      this.subs = [];
    }
  }]);
  return Subscriptions;
}();

var subscriptions = new Subscriptions();
exports.default = subscriptions;
module.exports = exports["default"];