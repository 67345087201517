'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toaster = require('../ui/Toaster');

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Dialog = require('../ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _firestore = require('../../store/firestore');

var _OrgNumber = require('../utils/OrgNumber');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CompanyForm = function (_Component) {
  (0, _inherits3.default)(CompanyForm, _Component);

  function CompanyForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompanyForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CompanyForm.__proto__ || Object.getPrototypeOf(CompanyForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: {},
      infoDialogVisible: false
    }, _this.GoToSettings = function () {
      _this.props.history.push('/settings');
    }, _this.handleInputChange = function (prop, _ref2) {
      var target = _ref2.target;

      if (prop === 'email') {
        _this.state.data[prop] = target.value.toLowerCase();
      } else if (prop === 'overtimePrice') {
        _this.state.company[prop] = target.value.replace(',', '.');
      } else {
        _this.state.data[prop] = target.value;
      }
      _this.setState(_this.state);
    }, _this.toggleInfoDialog = function (e) {
      e.preventDefault();
      _this.setState({
        infoDialogVisible: !_this.state.infoDialogVisible
      });
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      if (!_this.state.data.terms || _this.state.data.terms === 'off') {
        return (0, _Toaster.showError)('Du måste acceptera villkoren');
      }
      if (!_this.state.data.companyName || _this.state.data.terms === '') {
        return (0, _Toaster.showError)('Du måste ange ett företagsnamn');
      }
      if ((0, _OrgNumber.checkOrgNumber)(_this.state.data.orgNumber) === false) {
        return (0, _Toaster.showError)('Ogilitigt organisationsnummer');
      }

      var user = _this.props.user;
      var companyName = _this.state.data.companyName;

      var payload = (0, _extends3.default)({}, _this.state.data, {
        owner: user.uid,
        employees: [],
        status: 'active'
      });
      _this.props.createCompany(user.uid, payload).then(function (_ref3) {
        var data = _ref3.data;

        // TODO move this to createCompany ?
        // create invite for self
        var company = void 0;
        _firestore.db.collection('users/' + user.uid + '/companies').get().then(function (result) {
          if (!result.empty) {
            var companies = result.docs.map(function (s) {
              return (0, _extends3.default)({ uid: s.id }, s.data());
            });
            _this.props.createInviteWithStateAccepted(companies[0], user.email).then(function (_ref4) {
              var data = _ref4.data;

              (0, _Toaster.showSuccess)((companyName ? companyName : 'Företaget') + ' skapat.');
              _this.props.history.push('settings');
            }).catch(function () {
              return (0, _Toaster.showError)(companyName + ' kunde inte skapas.');
            });
          }
        });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CompanyForm, [{
    key: 'render',
    value: function render() {
      var _state$data = this.state.data,
          companyName = _state$data.companyName,
          orgNumber = _state$data.orgNumber,
          streetAdress = _state$data.streetAdress,
          zipcode = _state$data.zipcode,
          city = _state$data.city,
          firstName = _state$data.firstName,
          lastName = _state$data.lastName,
          phone = _state$data.phone,
          email = _state$data.email;


      return _react2.default.createElement(
        'form',
        { className: 'form form--settings',
          onSubmit: this.handleSubmit
        },
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'F\xF6retagsnamn'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: companyName,
              onChange: this.handleInputChange.bind(this, 'companyName')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Organisationsnummer'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: orgNumber,
              onChange: this.handleInputChange.bind(this, 'orgNumber')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Adress'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: streetAdress,
              onChange: this.handleInputChange.bind(this, 'streetAdress')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Postnummer'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: zipcode,
              onChange: this.handleInputChange.bind(this, 'zipcode')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Ort'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: city,
              onChange: this.handleInputChange.bind(this, 'city')
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'Kontaktperson'
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'F\xF6rnamn'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: firstName,
              onChange: this.handleInputChange.bind(this, 'firstName')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Efternamn'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: lastName,
              onChange: this.handleInputChange.bind(this, 'lastName')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Telefon'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: phone,
              onChange: this.handleInputChange.bind(this, 'phone')
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'E-post'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: email,
              onChange: this.handleInputChange.bind(this, 'email')
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'h3',
            null,
            'Villkor'
          ),
          _react2.default.createElement('input', { type: 'checkbox', onChange: this.handleInputChange.bind(this, 'terms') }),
          ' F\xF6r att kunna anv\xE4nda Transportsedeln beh\xF6ver du godk\xE4nna anv\xE4ndarvillkoren. ',
          _react2.default.createElement(
            'a',
            { onClick: this.toggleInfoDialog },
            'L\xE4s villkor'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'l-two-col__btns' },
          _react2.default.createElement(_Button2.default, {
            text: 'Avbryt',
            type: _Button.TYPES.SECONDARY,
            onClick: this.GoToSettings
          }),
          _react2.default.createElement(_Button2.default, {
            text: 'Skapa f\xF6retag',
            type: _Button.TYPES.PRIMARY
          })
        ),
        _react2.default.createElement(
          _Dialog2.default,
          {
            title: 'Anv\xE4ndarvillkor',
            visible: this.state.infoDialogVisible,
            close: this.toggleInfoDialog },
          _react2.default.createElement(
            'p',
            null,
            'Registrera ditt f\xF6retag och dra nytta av f\xF6rdelarna av att kunna arbeta gemensamt med transportsedlar.'
          ),
          _react2.default.createElement(
            'h3',
            null,
            'F\xF6rdelar med registrering av f\xF6retag'
          ),
          _react2.default.createElement(
            'p',
            null,
            _react2.default.createElement(
              'ul',
              null,
              _react2.default.createElement(
                'li',
                null,
                _react2.default.createElement(
                  'b',
                  null,
                  '1.'
                ),
                ' Tillg\xE5ng att arbeta i grupp med flera anst\xE4llda'
              ),
              _react2.default.createElement(
                'li',
                null,
                _react2.default.createElement(
                  'b',
                  null,
                  '2.'
                ),
                ' Tillg\xE5ng till att samarbeta med andra f\xF6retag'
              ),
              _react2.default.createElement(
                'li',
                null,
                _react2.default.createElement(
                  'b',
                  null,
                  '3.'
                ),
                ' Enkel administration av transportsedlar'
              )
            )
          ),
          _react2.default.createElement(
            'p',
            null,
            'F\xF6retagskontot har en grundkostnad p\xE5 150 kr per p\xE5b\xF6rjad m\xE5nad. D\xE4rtill debiteras 99 kr f\xF6r respektive kopplad medarbetare per p\xE5b\xF6rjad m\xE5nad.'
          ),
          _react2.default.createElement(
            'p',
            null,
            'Du kan n\xE4r som helst koppla bort en medarbetare utan n\xE5gra upps\xE4gningstider.'
          )
        )
      );
    }
  }]);
  return CompanyForm;
}(_react.Component);

exports.default = CompanyForm;
module.exports = exports['default'];