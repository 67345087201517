'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMotion = require('react-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WAITING = 'WAITING';
var RESIZING = 'RESIZING';
var RESTING = 'RESTING';
var IDLING = 'IDLING';

var Expandable = function (_PureComponent) {
  (0, _inherits3.default)(Expandable, _PureComponent);

  function Expandable(props) {
    (0, _classCallCheck3.default)(this, Expandable);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Expandable.__proto__ || Object.getPrototypeOf(Expandable)).call(this, props));

    _initialiseProps.call(_this);

    _this.state = {
      currentState: IDLING,
      from: 0,
      to: 0
    };
    return _this;
  }

  (0, _createClass3.default)(Expandable, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          open = _props.open,
          forceInitialAnimation = _props.forceInitialAnimation;

      if (open) {
        var to = this.content.clientHeight;
        if (forceInitialAnimation) {
          var from = this.wrapper.clientHeight;
          this.setState({ currentState: RESIZING, from: from, to: to });
        } else {
          this.setState({ currentState: IDLING, from: to, to: to });
        }
      }
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.hasNestedCollapse) {
        if (nextProps.open !== this.props.open) {
          this.setState({ currentState: WAITING });
        }
      } else if (this.state.currentState === IDLING && (nextProps.open || this.props.open)) {
        this.setState({ currentState: WAITING });
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(_, prevState) {
      var _props2 = this.props,
          open = _props2.open,
          fixedHeight = _props2.fixedHeight;


      if (this.state.currentState === IDLING) {
        return;
      }

      var from = this.wrapper.clientHeight;
      var to = open ? fixedHeight > -1 ? fixedHeight : this.content.clientHeight : 0;

      if (from !== to) {
        this.setState({ currentState: RESIZING, from: from, to: to });
        return;
      }

      if (this.state.currentState === RESTING || this.state.currentState === WAITING) {
        this.setState({ currentState: IDLING, from: from, to: to });
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      cancelAnimationFrame(this.raf);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _reactMotion.Motion,
        (0, _extends3.default)({}, this.getMotionProps(), { onRest: this.onRest }),
        this.renderContent
      );
    }
  }]);
  return Expandable;
}(_react.PureComponent);

Expandable.defaultProps = {
  config: {
    precision: 1,
    stiffness: 420,
    damping: 30
  },
  forceInitialAnimation: false,
  hasNestedCollapse: false,
  fixedHeight: -1
};

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  this.onContentRef = function (content) {
    _this2.content = content;
  };

  this.onWrapperRef = function (wrapper) {
    _this2.wrapper = wrapper;
  };

  this.onRest = function () {
    _this2.raf = requestAnimationFrame(_this2.setResting);
  };

  this.setResting = function () {
    _this2.setState({ currentState: RESTING });
  };

  this.getWrapperStyle = function (height) {
    if (_this2.state.currentState === IDLING && _this2.state.to) {
      var fixedHeight = _this2.props.fixedHeight;

      if (fixedHeight > -1) {
        return { overflow: 'hidden', height: fixedHeight };
      }
      return { height: 'auto' };
    }

    if (_this2.state.currentState === WAITING && !_this2.state.to) {
      return { overflow: 'hidden', height: 0 };
    }

    return { overflow: 'hidden', height: Math.max(0, height) };
  };

  this.getMotionProps = function () {
    var config = _this2.props.config;

    return _this2.state.currentState === IDLING ? {
      // When completely stable, instantly jump to the position
      defaultStyle: { height: _this2.state.to },
      style: { height: _this2.state.to }
    } : {
      defaultStyle: { height: _this2.state.from },
      style: { height: (0, _reactMotion.spring)(_this2.state.to, (0, _extends3.default)({}, config)) }
    };
  };

  this.renderContent = function (_ref) {
    var height = _ref.height;
    var _props3 = _this2.props,
        _open = _props3.open,
        _config = _props3.config,
        _forceInitialAnimation = _props3.forceInitialAnimation,
        _hasNestedCollapse = _props3.hasNestedCollapse,
        _fixedHeight = _props3.fixedHeight,
        children = _props3.children,
        props = (0, _objectWithoutProperties3.default)(_props3, ['open', 'config', 'forceInitialAnimation', 'hasNestedCollapse', 'fixedHeight', 'children']);


    return _react2.default.createElement(
      'div',
      (0, _extends3.default)({
        ref: _this2.onWrapperRef,
        className: 'expandable',
        style: (0, _extends3.default)({}, _this2.getWrapperStyle(Math.max(0, height)))
      }, props),
      _react2.default.createElement(
        'div',
        { ref: _this2.onContentRef },
        children
      )
    );
  };
};

exports.default = Expandable;
module.exports = exports['default'];