'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _authError = require('../../utils/auth-error');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SignUp = function (_Component) {
  (0, _inherits3.default)(SignUp, _Component);

  function SignUp() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SignUp);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SignUp.__proto__ || Object.getPrototypeOf(SignUp)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      complete: false,
      error: _this.props.error || null
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      _this.props.handleSubmit();
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SignUp, [{
    key: 'render',
    value: function render() {
      var error = this.props.user.error;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'h1',
          null,
          'Skapa nytt konto'
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          this.form
        )
      );
    }
  }, {
    key: 'form',
    get: function get() {
      var _props = this.props,
          loading = _props.loading,
          email = _props.email,
          password = _props.password,
          repeatPassword = _props.repeatPassword,
          setField = _props.setField,
          firstName = _props.firstName,
          lastName = _props.lastName,
          phone = _props.phone;

      return _react2.default.createElement(
        'form',
        { noValidate: true,
          method: 'post',
          className: 'form form--modal',
          onSubmit: this.handleSubmit },
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'E-postadress'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            name: 'email',
            value: email,
            onChange: setField.bind(null, 'email')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'L\xF6senord'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'password',
            name: 'password',
            value: password,
            onChange: setField.bind(null, 'password')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'Repetera l\xF6senord'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'password',
            name: 'repeatpassword',
            value: repeatPassword,
            onChange: setField.bind(null, 'repeatPassword')
          })
        ),
        _react2.default.createElement(_Button2.default, {
          disabled: loading,
          type: _Button.TYPES.PRIMARY,
          text: 'Registrera' }),
        _react2.default.createElement(
          'p',
          null,
          'Har du redan ett konto?',
          _react2.default.createElement(
            'a',
            { onClick: this.props.changeForm.bind(null, 'sign-in') },
            'Logga in'
          )
        )
      );
    }
  }]);
  return SignUp;
}(_react.Component);

exports.default = SignUp;
module.exports = exports['default'];