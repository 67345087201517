'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAutosizeTextarea = require('react-autosize-textarea');

var _reactAutosizeTextarea2 = _interopRequireDefault(_reactAutosizeTextarea);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextArea = function TextArea(_ref) {
  var error = _ref.error,
      value = _ref.value,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['error', 'value']);
  return _react2.default.createElement(
    'div',
    { className: 'input-wrapper' },
    _react2.default.createElement(_reactAutosizeTextarea2.default, (0, _extends3.default)({
      className: error ? 'invalid' : '',
      style: { resize: 'vertical' },
      value: value || '',
      onChange: rest.onChange || function () {}
    }, rest)),
    error ? _react2.default.createElement(
      'span',
      { className: 'input-error' },
      error
    ) : null
  );
};

exports.default = TextArea;
module.exports = exports['default'];