'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _company3 = require('../actions/company');

var _invite = require('../actions/invite');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialState = {
  data: null,
  error: null,
  companies: [],
  userInvites: [],
  companyInvites: [],
  acceptedInvites: []
};

exports.default = function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case _invite.GET_ACCEPTED_INVITES:
      {
        var invites = action.data.docs.map(function (s) {
          return (0, _extends3.default)({}, s.data());
        });
        return (0, _extends3.default)({}, state, {
          acceptedInvites: invites
        });
      }
    case _company3.GET_COMPANIES:
      {
        var companies = action.data.companies;

        return (0, _extends3.default)({}, state, {
          companies: companies
        });
      }
    case _company3.CREATE_COMPANY:
      {
        return (0, _extends3.default)({}, state, {
          companies: [].concat((0, _toConsumableArray3.default)(state.companies), [action.data])
        });
      }
    case _company3.UPDATE_COMPANY:
      {
        return (0, _extends3.default)({}, state, {
          companies: state.companies.map(function (c) {
            if (c.uid === action.data.uid) {
              return (0, _extends3.default)({}, c, action.data);
            }
            return c;
          })
        });
      }
    case _invite.GET_USER_INVITES:
      {
        var _invites = action.data.invites;

        return (0, _extends3.default)({}, state, {
          userInvites: _invites
        });
      }
    case _invite.GET_COMPANY_INVITES:
      {
        return (0, _extends3.default)({}, state, {
          companyInvites: action.data.filter(function (i) {
            return i.status !== 'canceled' && i.status !== 'accepted';
          })
        });
      }
    case _invite.CREATE_INVITE_SUCCESS:
      {
        return (0, _extends3.default)({}, state);
      }
    case _invite.CREATE_INVITE_ERROR:
      {
        return (0, _extends3.default)({}, state);
      }
    case _invite.DELETE_INVITE:
      {
        return (0, _extends3.default)({}, state, {
          userInvites: state.invites.filter(function (i) {
            return i.uid !== action.data.uid;
          })
        });
      }
    case _company3.ADD_EMPLOYEE:
      {
        var _action$data = action.data,
            user = _action$data.user,
            company = _action$data.company;

        return (0, _extends3.default)({}, state, {
          companies: state.companies.map(function (c) {
            if (c.uid === company.uid) {
              c.employees = [].concat((0, _toConsumableArray3.default)(c.employees || []), [user]);
            }
            return c;
          })
        });
      }
    case _company3.REMOVE_EMPLOYEE:
      {
        var _action$data2 = action.data,
            _user = _action$data2.user,
            _company = _action$data2.company;

        return (0, _extends3.default)({}, state, {
          companies: state.companies.map(function (c) {
            if (c.uid === _company.uid) {
              c.employees = c.employees.filter(function (e) {
                return e.uid !== _user.uid;
              });
            }
            return c;
          })
        });
      }
    case _company3.GET_EMPLOYEE_USERS:
      {
        var _action$data3 = action.data,
            users = _action$data3.users,
            _company2 = _action$data3.company;

        return (0, _extends3.default)({}, state, {
          companies: state.companies.map(function (c) {
            if (c.uid === _company2.uid) {
              c.employees = users;
            }
            return c;
          })
        });
      }
    default:
      return state;
  }
};

module.exports = exports['default'];