'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _Toaster = require('../ui/Toaster');

var _EditGoodsForm = require('../shipments/goods/EditGoodsForm');

var _EditGoodsForm2 = _interopRequireDefault(_EditGoodsForm);

var _Modal = require('../ui/Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _shipment = require('../../actions/shipment');

var _company = require('../../actions/company');

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var shipment = _ref.shipment,
      user = _ref.user,
      company = _ref.company;
  return {
    shipment: shipment, user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    updateShipment: function updateShipment(data) {
      return (0, _shipment.updateShipment)(data).then(dispatch);
    },
    setAsRemoved: function setAsRemoved(id) {
      return (0, _shipment.setAsRemoved)(id).then(dispatch).catch(dispatch);
    },
    getShipmentById: function getShipmentById(uid) {
      return (0, _shipment.getShipmentById)(uid).then(dispatch);
    },
    getEmployeeUsers: function getEmployeeUsers(company) {
      return (0, _company.getEmployeeUsers)(company).then(dispatch);
    },
    setSelectedShipment: function setSelectedShipment(shipment) {
      return (0, _shipment.setSelectedShipment)(shipment, dispatch);
    },
    removeGoodsImage: function removeGoodsImage(ref, goods) {
      return (0, _shipment.removeGoodsImage)(ref, goods).then(dispatch);
    },
    acceptShipment: function acceptShipment(ref) {
      return (0, _shipment.acceptShipment)(ref).then(dispatch);
    },
    sendShipment: function sendShipment(id, email) {
      return (0, _shipment.sendShipment)(id, email).then(dispatch);
    }
  };
};

var EditGoods = function (_Component) {
  (0, _inherits3.default)(EditGoods, _Component);

  function EditGoods() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditGoods);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = EditGoods.__proto__ || Object.getPrototypeOf(EditGoods)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      data: [],
      goods: [],
      goodsIndex: ''
    }, _this.handleBackButton = function () {
      _this.props.history.push('/shipment/' + _this.props.match.params.id);
    }, _this.handleUpdateButton = function (goods, index) {
      _this.setState(function (state) {
        var goodsList = state.data.goods.map(function (item, j) {
          if (j.toString() === index) {
            return goods;
          } else {
            return item;
          }
        });
        var data = state.data;
        data.goods = goodsList;
        _this.props.updateShipment(data).then(function () {
          (0, _Toaster.showSuccess)('Uppgifter sparade.');
          _this.handleBackButton();
        }).catch(function () {
          (0, _Toaster.showError)('Uppgifter sparades inte.');
        });
        return {
          data: data
        };
      });
    }, _this.setField = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state.goods[prop] = target.value;
      _this.setState(_this.state);
    }, _this.setImages = function (images) {
      var data = _this.state.data;

      data.goods[_this.state.goodsIndex].images = images;
      _this.setState({ data: data });
    }, _this.removeGoodsImage = function (url) {
      _this.props.removeGoodsImage(url, _this.props.shipment.selected);
    }, _this.setGoods = function (goods) {
      var data = _this.state.data;

      data.goods[_this.state.goodsIndex] = goods;
      _this.setState({ data: data });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditGoods, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      var params = this.props.match.params;

      if (params.id && params.index) {
        this.props.getShipmentById(params.id).then(function (shipment) {
          _this2.setState({ goods: shipment.data.goods[params.index], goodsIndex: params.index, data: shipment.data });
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { onClick: this.handleBackButton },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'container l-two-col' },
          _react2.default.createElement(_EditGoodsForm2.default, {
            shipmentId: this.props.match.params.id,
            goodsIndex: this.props.match.params.index,
            getShipmentById: this.props.getShipmentById,
            handleUpdateButton: this.handleUpdateButton
          }),
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__img' },
            _react2.default.createElement('img', { src: 'images/illustration-boxes.svg', alt: 'Shipmentbills' })
          )
        )
      );
    }
  }]);
  return EditGoods;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(EditGoods);
module.exports = exports['default'];