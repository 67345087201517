'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _user = require('../actions/user');

var _invite = require('../actions/invite');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialState = {
  error: null,
  linkedCompanies: []
};

exports.default = function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case _user.SIGN_IN:
      {
        return (0, _extends3.default)({}, state, {
          error: null,
          emailVerified: true
        });
      }
    case _user.CHECK_CODE_SUCCESS:
      {
        return (0, _extends3.default)({}, state);
      }
    case _user.CHECK_CODE_ERROR:
      {
        return (0, _extends3.default)({}, state);
      }
    case _user.VERIFY_ACCOUNT_SUCCESS:
      {
        return (0, _extends3.default)({}, state, {
          error: null,
          emailVerified: true
        });
      }
    case _user.VERIFY_ACCOUNT_ERROR:
      {
        return (0, _extends3.default)({}, state);
      }
    case _user.NOT_VERIFIED:
      {
        return (0, _extends3.default)({}, state, {
          error: null,
          emailVerified: false
        });
      }
    case _user.SIGN_IN_ERROR:
      {
        return (0, _extends3.default)({}, state, {
          error: action.data
        });
      }
    case _user.SIGN_UP_ERROR:
      {
        return (0, _extends3.default)({}, state, {
          error: action.data
        });
      }
    case _user.SET_USER:
      {
        return (0, _extends3.default)({}, state, action.data);
      }
    case _user.SET_AUTH_USER:
      {
        return (0, _extends3.default)({}, state, action.data);
      }
    case _user.UPDATE_USER:
      {
        return (0, _extends3.default)({}, state, action.data);
      }
    case _user.SIGN_OUT:
      {
        return {
          error: null
        };
      }
    case _user.VERIFICATION_EMAIL_SENT:
      {
        return (0, _extends3.default)({}, state, {
          emailSent: true
        });
      }
    case _invite.RESPOND_INVITATION:
      {
        var _action$data = action.data,
            accepted = _action$data.accepted,
            invite = _action$data.invite;

        if (accepted === false) {
          return state;
        }
        return (0, _extends3.default)({}, state, {
          linkedCompanies: [].concat((0, _toConsumableArray3.default)(state.linkedCompanies), [(0, _extends3.default)({}, invite.company)])
        });
      }
    case _user.RESET_PASSWORD_SENT:
      {
        return (0, _extends3.default)({}, state);
      }
    case _user.RESET_PASSWORD_ERROR:
      {
        return (0, _extends3.default)({}, state, {
          error: action.data
        });
      }
    default:
      return state;
  }
};

module.exports = exports['default'];