'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Cookies = function Cookies() {
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_Header2.default, undefined.props),
    _react2.default.createElement(
      'div',
      { id: 'start' },
      _react2.default.createElement(
        'div',
        { id: 'home' },
        _react2.default.createElement(
          'section',
          { className: 'home' },
          _react2.default.createElement(
            'div',
            { className: 'privacy-box' },
            _react2.default.createElement(
              'h1',
              null,
              'Cookies'
            ),
            _react2.default.createElement('p', null),
            _react2.default.createElement(
              'h2',
              null,
              'Hur anv\xE4nder Transportsedeln cookies?'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Enligt lagen (2003:389) om elektronisk kommunikation ska alla som bes\xF6ker en webbplats med cookies f\xE5 information om att: webbsidan inneh\xE5ller cookies, vad dessa cookies anv\xE4nds till och hur cookies kan undvikas. Du som bes\xF6kare m\xE5ste samtycka till att cookies anv\xE4nds samt ges information om hur du kan f\xF6rhindra anv\xE4ndandet av cookies. Genom att acceptera cookies i s\xE4kerhetsinst\xE4llningarna f\xF6r  din webbl\xE4sare ger du \xE4ven ditt samtycke till att dessa anv\xE4nds.'
            ),
            _react2.default.createElement(
              'h2',
              null,
              'Vad \xE4r en cookie och vad anv\xE4nds de p\xE5 denna webbplats?'
            ),
            _react2.default.createElement(
              'p',
              null,
              'En cookie \xE4r en textfil som v\xE5r webbplats beg\xE4r f\xE5 spara p\xE5 din dator under en kortare och l\xE4ngre tid. Dessa informationsfiler lagras i din dator men kan inte st\xE4lla till n\xE5gon skada. Vi g\xF6r det f\xF6r att l\xE4ra oss mer om hur v\xE5r webbplats anv\xE4nds och hur vi kan g\xF6ra den b\xE4ttre f\xF6r dig som anv\xE4ndare.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Det finns tv\xE5 typer av f\xF6rstapartscookie som Transportsedeln anv\xE4nder: ',
              _react2.default.createElement('br', null),
              '- Sessionscookies, f\xF6rsvinner n\xE4r man l\xE4mnar webbplatsen. ',
              _react2.default.createElement('br', null),
              '- Best\xE4ndiga cookies, finns kvar en viss tid eller tills anv\xE4ndaren tar bort dem.',
              _react2.default.createElement('br', null),
              'V\xE5ran cookies inneh\xE5ller inga personuppgifter. Nedan kan du l\xE4sa mer om vilka cookies vi anv\xE4nder.',
              _react2.default.createElement('br', null)
            ),
            _react2.default.createElement(
              'h2',
              null,
              'S\xE5 h\xE4r kan du sp\xE4rra cookies p\xE5 din dator'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Du kan tacka nej till cookies genom att g\xF6ra inst\xE4llningar i din webbl\xE4sares s\xE4kerhetsinst\xE4llningar. Du kan \xE4ven via din webbl\xE4sare st\xE4lla in vilka cookies som ska till\xE5tas, blockeras eller raderas. Om du v\xE4ljer att st\xE4nga av cookies kan upplevelsen av v\xE5r webbplats bli s\xE4mre.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Du kan l\xE4sa mer om cookies och lagen (2003:389) om elektronisk kommunikation p\xE5 Post & Telestyrelsens webbsida. Vid fr\xE5gor v\xE4nligen kontakta: ',
              _react2.default.createElement(
                'a',
                { href: 'mailto:info@transportsedeln.se' },
                'info@transportsedeln.se'
              )
            ),
            _react2.default.createElement(
              'h2',
              null,
              'Cookies som anv\xE4nds p\xE5 denna webbplats'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Transportsedeln anv\xE4nder verktyget Google Analytics f\xF6r att samla in information om beteende p\xE5 webbplatsen.Google analytics samlar information om hur ofta en anv\xE4ndare bes\xF6ker webbplatsen och vilka andra hemsidor som anv\xE4ndaren tidigare har bes\xF6kt. Transportsedeln anv\xE4nder information f\xF6r att optimera prestanda ac webbplatsens funktioner f\xF6r att: ',
              _react2.default.createElement('br', null),
              '- F\xF6rb\xE4ttra anv\xE4ndarupplevelsen',
              _react2.default.createElement('br', null),
              '- Anpassa webbplatsen till dina preferenser och behov',
              _react2.default.createElement('br', null),
              '- G\xF6ra webbplatsen enklare att anv\xE4nda',
              _react2.default.createElement('br', null)
            ),
            _react2.default.createElement(
              'p',
              null,
              'Sidan uppdaterades 2019-05-20.'
            )
          )
        )
      )
    )
  );
};
exports.default = Cookies;
module.exports = exports['default'];