'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _reactRedux = require('react-redux');

var _user = require('../actions/user');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user,
      company = _ref.company;
  return { user: user, company: company };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    signOut: function signOut() {
      return (0, _user.signOut)(dispatch);
    },
    signIn: function signIn(email, password) {
      return (0, _user.signIn)(email, password).then(dispatch);
    }
  };
};

var Header = function (_PureComponent) {
  (0, _inherits3.default)(Header, _PureComponent);

  function Header() {
    (0, _classCallCheck3.default)(this, Header);
    return (0, _possibleConstructorReturn3.default)(this, (Header.__proto__ || Object.getPrototypeOf(Header)).apply(this, arguments));
  }

  (0, _createClass3.default)(Header, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: 'header' },
        _react2.default.createElement(
          'div',
          { className: 'header__content' },
          _react2.default.createElement(
            'ul',
            { className: 'header__nav' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                _reactRouterDom.Link,
                { className: 'header__logo', to: '/shipments' },
                _react2.default.createElement('img', { src: '/images/transportsedeln-logo.svg' })
              )
            ),
            this.userInfo,
            _react2.default.createElement(
              'li',
              null,
              '\xA0'
            ),
            this.navigation,
            this.login
          )
        )
      );
    }
  }, {
    key: 'navigation',
    get: function get() {
      var _props = this.props,
          user = _props.user,
          company = _props.company;

      if (!user.emailVerified || user.emailVerified === false) return null;
      var shipmentsStyle = this.props.active == 'shipments' ? { textDecoration: 'underline' } : {};
      var settingsStyle = this.props.active == 'settings' ? { textDecoration: 'underline' } : {};
      var shipmentsUrl = this.props.showState !== undefined ? '/shipments?filter=' + this.props.showState : '/shipments';
      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'li',
          null,
          _react2.default.createElement(
            _reactRouterDom.Link,
            { className: 'nav-link', style: shipmentsStyle, to: shipmentsUrl },
            'Sedellista'
          )
        ),
        _react2.default.createElement(
          'li',
          null,
          company.companyInvites.length > 0 ? _react2.default.createElement(
            _reactRouterDom.Link,
            { className: 'nav-link', style: settingsStyle, to: '/settings' },
            'Inst\xE4llningar ',
            _react2.default.createElement(
              'div',
              { className: 'open-modal' },
              '!'
            )
          ) : _react2.default.createElement(
            _reactRouterDom.Link,
            { className: 'nav-link', style: settingsStyle, to: '/settings' },
            'Inst\xE4llningar'
          )
        )
      );
    }
  }, {
    key: 'login',
    get: function get() {
      var user = this.props.user;

      if (user.emailVerified || user.emailVerified === true) return null;

      return _react2.default.createElement(
        'li',
        { className: 'topbar-links' },
        _react2.default.createElement(
          _reactRouterDom.Link,
          { className: 'topbar-link', to: '/main' },
          'Logga in'
        ),
        _react2.default.createElement(
          _reactRouterDom.Link,
          { className: 'btn-green', to: '/main?sign-up' },
          'Registrera'
        )
      );
    }
  }, {
    key: 'userInfo',
    get: function get() {
      var _props2 = this.props,
          user = _props2.user,
          company = _props2.company;

      if (!user.emailVerified || user.emailVerified === false) return null;

      // TODO flytta inline styles till cssen
      var style = {
        color: '#FFFFFF',
        fontFamily: 'ttn-bold'
      };

      var companyName = '';
      if (company.companies[0] !== undefined && company.companies[0].companyName) {
        companyName = '(' + company.companies[0].companyName + ')';
      }

      return _react2.default.createElement(
        'li',
        null,
        _react2.default.createElement(
          'span',
          { style: style, className: 'header__loggedIn' },
          'Inloggad: ',
          user.firstName,
          ' ',
          user.lastName,
          ' ',
          companyName
        )
      );
    }
  }]);
  return Header;
}(_react.PureComponent);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(Header);
module.exports = exports['default'];