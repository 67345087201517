'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _webpackVariables = require('webpack-variables');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function () {
  var handleResponse = function handleResponse(res) {
    return (res.status / 100 | 0) === 2 ? res.data : Promise.reject(res);
  };

  var serialize = function serialize(params) {
    return Object.keys(params).map(function (key) {
      return key + '=' + encodeURIComponent(params[key]);
    }).join('&');
  };

  var instance = _axios2.default.create({
    timeout: 100000
  });

  var get = function get(uri, params) {
    if ((typeof params === 'undefined' ? 'undefined' : (0, _typeof3.default)(params)) === 'object') {
      uri += '?' + serialize(params);
    }
    return instance.get(_webpackVariables.BASE_URL + uri).then(handleResponse);
  };

  var getUrl = function getUrl(url, params) {
    if ((typeof params === 'undefined' ? 'undefined' : (0, _typeof3.default)(params)) === 'object') {
      url += '?' + serialize(params);
    }
    return instance.get(url).then(handleResponse);
  };

  return {
    get: get, getUrl: getUrl
  };
}();

module.exports = exports['default'];