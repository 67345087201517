'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user;
  return {
    user: user
  };
};

var Start = function (_Component) {
  (0, _inherits3.default)(Start, _Component);

  function Start() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Start);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Start.__proto__ || Object.getPrototypeOf(Start)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      form: 'sign-in',
      email: '',
      password: '',
      loading: false,
      error: null
    }, _this.handleTextFieldChange = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state[prop] = target.value;
      _this.setState(_this.state);
    }, _this.Login = function () {
      _this.props.history.push('/main');
    }, _this.scrollDown = function () {
      document.getElementById('more-info-start').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Start, [{
    key: 'render',
    value: function render() {
      // Redirect user if logged in
      if (this.props.user.email && this.props.user.emailVerified) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }
      return _react2.default.createElement(
        'div',
        { className: 'promoPage' },
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'header',
            null,
            _react2.default.createElement(
              'div',
              { className: 'logo' },
              _react2.default.createElement('img', { src: 'images/transportsedeln-logo.svg', alt: 'Transportsedeln logo' })
            ),
            _react2.default.createElement(
              'div',
              { className: 'login' },
              _react2.default.createElement(
                'button',
                { className: 'btn btn--ghost', onClick: this.Login },
                'Logga in'
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'launch' },
            _react2.default.createElement(
              'div',
              { className: 'block sectionHide' },
              _react2.default.createElement('img', { src: 'images/illustration-sign.svg', alt: 'Signera', className: 'launchImage' }),
              _react2.default.createElement('img', { src: 'images/illustration-boxes.svg', alt: 'Gods' }),
              _react2.default.createElement(
                'h1',
                null,
                'Signering och godshantering f\xF6r sm\xE5 och medelstora \xE5kerier'
              ),
              _react2.default.createElement(
                'button',
                { className: 'btn btn--green', onClick: this.scrollDown },
                'L\xE4s mer'
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'location', id: 'more-info-start' },
            _react2.default.createElement(
              'div',
              { className: 'block info' },
              _react2.default.createElement(
                'div',
                { className: 'left' },
                _react2.default.createElement(
                  'h2',
                  null,
                  'Koll p\xE5 godset'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Minska det administrativa hos b\xE5de kontorspersonal och chauff\xF6rer och f\xE5 tydliga och detaljerade rapporter p\xE5 utf\xF6rda arbeten.'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Transportsedeln togs fram f\xF6r att f\xF6renkla rapporteringen f\xF6r chauff\xF6rer och den administrativa personalen p\xE5 kontoret.'
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'right alignment' },
                _react2.default.createElement('img', { src: 'images/i-goods-location.svg', alt: 'Goods location' })
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'signage' },
            _react2.default.createElement(
              'div',
              { className: 'block info bg-signing' },
              _react2.default.createElement(
                'div',
                { className: 'left' },
                _react2.default.createElement('img', { src: 'images/i-signing.svg', alt: 'Signing' })
              ),
              _react2.default.createElement(
                'div',
                { className: 'right alignment' },
                _react2.default.createElement(
                  'h2',
                  null,
                  'Signera i appen'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Allt du beh\xF6ver \xE4r appen och en enkel signatur fr\xE5n kunden som registreras direkt i appen vid leverans.'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Du \xE4r klar p\xE5 mindre en minut och du erh\xE5ller en tydlig rapport med kundens signatur som underlag f\xF6r dina fakturor.'
                )
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'collaboration' },
            _react2.default.createElement(
              'div',
              { className: 'block info' },
              _react2.default.createElement(
                'div',
                { className: 'left' },
                _react2.default.createElement(
                  'h2',
                  null,
                  'Samarbeta'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Registrera ett f\xF6retag p\xE5 Transportsedeln och f\xE5 m\xF6jlighet att tilldela transportsedlar till chauff\xF6rer och \xE5kerier. '
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Du bjuder enkelt in medarbetare genom anv\xE4ndargr\xE4nssnittet och kan sedan tilldela och h\xE5lla dig uppdaterad f\xF6r respektiva sedlar.'
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'right' },
                _react2.default.createElement('img', { src: 'images/i-collaboration.svg', alt: 'Collaboration' })
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'Company' },
            _react2.default.createElement(
              'div',
              { className: 'block info bg-company' },
              _react2.default.createElement(
                'div',
                { className: 'left alignment' },
                _react2.default.createElement('img', { src: 'images/i-company.svg', alt: 'Company' })
              ),
              _react2.default.createElement(
                'div',
                { className: 'right' },
                _react2.default.createElement(
                  'h2',
                  null,
                  'F\xF6retagskonto'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'F\xF6retagskontot har en grundkostnad p\xE5 150 kr per p\xE5b\xF6rjad m\xE5nad. D\xE4rtill debiteras 99 kr f\xF6r respektive kopplad medarbetare per p\xE5b\xF6rjad m\xE5nad.'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Du kan n\xE4r som helst koppla bort en medarbetare utan n\xE5gra upps\xE4gningstider.'
                ),
                _react2.default.createElement(
                  'button',
                  { className: 'btn btn--green', onClick: this.Login },
                  'Registrera'
                )
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: 'apps' },
            _react2.default.createElement(
              'div',
              { className: 'block info' },
              _react2.default.createElement(
                'div',
                { className: 'left' },
                _react2.default.createElement(
                  'h2',
                  null,
                  'Mobilappar'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'V\xE5r mobilapp finns tillg\xE4ngliga f\xF6r iOS. Du laddar ner appen via Apples App Store.'
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  'Mobilappen \xE4r byggd f\xF6r hantering i fordon och det g\xE5r bra att registrera sitt konto via appen.'
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'right alignment' },
                _react2.default.createElement(
                  'p',
                  null,
                  _react2.default.createElement(
                    'a',
                    { href: 'https://apps.apple.com/se/app/transportsedeln-2-0/id1454484266' },
                    _react2.default.createElement('img', { src: 'images/appstore.svg', alt: 'App Store' })
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            'footer',
            null,
            _react2.default.createElement(
              'div',
              { className: 'footer-wrapper' },
              _react2.default.createElement(
                'div',
                { className: 'footer-col logo-footer' },
                _react2.default.createElement('img', { src: 'images/transportsedeln-logo.svg', alt: 'Transportsedeln logo' })
              ),
              _react2.default.createElement(
                'div',
                { className: 'footer-col' },
                _react2.default.createElement(
                  'h3',
                  null,
                  'Transportsedeln'
                ),
                _react2.default.createElement('hr', { className: 'solid' }),
                _react2.default.createElement(
                  'ol',
                  null,
                  _react2.default.createElement(
                    'li',
                    null,
                    _react2.default.createElement(
                      'a',
                      { href: '#' },
                      'Om'
                    )
                  ),
                  _react2.default.createElement(
                    'li',
                    null,
                    _react2.default.createElement(
                      'a',
                      { href: '#' },
                      'Cookies'
                    )
                  ),
                  _react2.default.createElement(
                    'li',
                    null,
                    _react2.default.createElement(
                      'a',
                      { href: '#' },
                      'Integritet och sekretess'
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'footer-col' },
                _react2.default.createElement(
                  'h3',
                  null,
                  'V\xE5ra tj\xE4nster'
                ),
                _react2.default.createElement('hr', { className: 'solid' }),
                _react2.default.createElement(
                  'ol',
                  null,
                  _react2.default.createElement(
                    'li',
                    null,
                    _react2.default.createElement(
                      'a',
                      { href: '#' },
                      'Desktop'
                    )
                  ),
                  _react2.default.createElement(
                    'li',
                    null,
                    _react2.default.createElement(
                      'a',
                      { href: 'https://apps.apple.com/se/app/transportsedeln-2-0/id1454484266' },
                      'Ios'
                    )
                  )
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: 'footer-bottom' },
              _react2.default.createElement(
                'span',
                null,
                '\xA9 2019 Transportsedeln'
              )
            )
          )
        )
      );
    }
  }]);
  return Start;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps)(Start);
module.exports = exports['default'];