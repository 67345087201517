'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Dialog = function (_Component) {
  (0, _inherits3.default)(Dialog, _Component);

  function Dialog() {
    (0, _classCallCheck3.default)(this, Dialog);
    return (0, _possibleConstructorReturn3.default)(this, (Dialog.__proto__ || Object.getPrototypeOf(Dialog)).apply(this, arguments));
  }

  (0, _createClass3.default)(Dialog, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          visible = _props.visible,
          children = _props.children,
          close = _props.close;

      return _react2.default.createElement(
        'div',
        { className: 'dialog \n      ' + (visible === true ? ' dialog--visible' : '') + ' ' },
        _react2.default.createElement(
          'div',
          { className: 'dialog__box' },
          _react2.default.createElement(
            'div',
            { className: 'dialog__titlebar' },
            _react2.default.createElement(
              'div',
              { className: 'titlebar__title' },
              title
            ),
            _react2.default.createElement(
              'div',
              { className: 'titlebar__close', onClick: close.bind(this) },
              _react2.default.createElement(
                'svg',
                { width: '24', height: '24', viewBox: '0 0 24 24' },
                _react2.default.createElement('use', { xlinkHref: '/images/defs.xml#icon-stroke-close-cross' })
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: 'dialog__content' },
            children
          )
        )
      );
    }
  }]);
  return Dialog;
}(_react.Component); /* eslint-disable react/prefer-stateless-function */


exports.default = Dialog;
module.exports = exports['default'];