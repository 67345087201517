'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactRouterDom = require('react-router-dom');

var _queryString = require('query-string');

var _queryString2 = _interopRequireDefault(_queryString);

var _user = require('../../actions/user');

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user;
  return {
    user: user
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    verifyAccount: function verifyAccount(oobCode) {
      return (0, _user.verifyAccount)(oobCode).then(dispatch).catch(dispatch);
    },
    checkVerificationCode: function checkVerificationCode(oobCode) {
      return (0, _user.checkVerificationCode)(oobCode).then(dispatch).catch(dispatch);
    }
  };
};

var VerifyAccount = function (_Component) {
  (0, _inherits3.default)(VerifyAccount, _Component);

  function VerifyAccount() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, VerifyAccount);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = VerifyAccount.__proto__ || Object.getPrototypeOf(VerifyAccount)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      verificationFailed: false,
      isVerified: false,
      codeIsValid: false
    }, _this.componentDidMount = function () {
      var values = _queryString2.default.parse(_this.props.history.location.search);
      _this.checkCode(values.oobCode);
    }, _this.checkCode = function (oobCode) {
      _this.props.checkVerificationCode(oobCode).then(function (result) {
        if (result.type === 'CHECK_CODE_ERROR') {
          _this.setState({
            codeIsValid: false,
            verificationFailed: true
          });
        } else {
          _this.setState({ codeIsValid: true });
          _this.runVerifyAccount(oobCode);
        }
      }).catch(function () {
        _this.setState({ verificationFailed: true });
      });
    }, _this.runVerifyAccount = function (oobCode) {
      _this.props.verifyAccount(oobCode).then(function (result) {
        _this.setState({ isVerified: true });
      }).catch(function () {
        _this.setState({ verificationFailed: true });
      });
    }, _this.codeCheckElements = function () {
      return _react2.default.createElement(
        'div',
        null,
        _this.state.verificationFailed === true ? _react2.default.createElement(
          'p',
          null,
          'Ogiltig verifieringskod. Koden som anv\xE4nds f\xF6r att verifiera ditt konto \xE4r troligen redan f\xF6rbrukad.'
        ) : _react2.default.createElement(
          'p',
          null,
          'Verifieringskod kontrolleras...'
        )
      );
    }, _this.verificationElements = function () {
      return _react2.default.createElement(
        'div',
        null,
        _this.state.verificationFailed === true ? _react2.default.createElement(
          'p',
          null,
          'Ditt konto kunde inte verifieras. F\xF6rs\xF6k igen senare. Kontakta oss om felet skulle best\xE5.'
        ) : _react2.default.createElement(
          'p',
          null,
          'Ditt konto verifieras...'
        )
      );
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(VerifyAccount, [{
    key: 'render',
    value: function render() {
      if (this.state.isVerified) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_Header2.default, this.props),
        _react2.default.createElement(
          'div',
          { className: 'main' },
          _react2.default.createElement(
            'h2',
            null,
            'Verifiera konto'
          ),
          this.state.codeIsValid === true ? this.verificationElements() : this.codeCheckElements()
        )
      );
    }
  }]);
  return VerifyAccount;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(VerifyAccount);
module.exports = exports['default'];