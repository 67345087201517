"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var truncateStr = exports.truncateStr = function truncateStr(text, maxLength, separator) {
  if (text.length <= maxLength) return text;

  var sepLength = separator.length;
  var charsToShow = maxLength - sepLength;
  var frontChars = Math.ceil(charsToShow / 2);
  var backChars = Math.floor(charsToShow / 2);

  return text.substr(0, frontChars) + separator + text.substr(text.length - backChars);
};