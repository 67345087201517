'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TSForm = require('../../ui/TSForm');

var _dateformatter = require('../../utils/dateformatter');

var _Button = require('../../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _uidHelper = require('../../utils/uidHelper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditGoodsForm = function (_Component) {
  (0, _inherits3.default)(EditGoodsForm, _Component);

  function EditGoodsForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditGoodsForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = EditGoodsForm.__proto__ || Object.getPrototypeOf(EditGoodsForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      shipment: [],
      goods: {
        overtimeHours: '',
        startDate: '',
        endDate: '',
        typeOfGoods: '',
        quantity: '',
        unitPrice: '',
        description: '',
        unit: '',
        images: [],
        uid: ''
      },
      goodsIndex: ''
    }, _this.updateButton = function (goods, index) {
      if (goods && index) {
        var editGoods = {
          overtimeHours: goods.overtimeHours !== undefined ? goods.overtimeHours : '',
          typeOfGoods: goods.typeOfGoods !== undefined ? goods.typeOfGoods : '',
          quantity: goods.quantity !== undefined ? goods.quantity : '',
          unitPrice: goods.unitPrice !== undefined ? goods.unitPrice : '',
          description: goods.description !== undefined ? goods.description : '',
          unit: goods.unit !== undefined ? goods.unit : '',
          images: goods.images !== undefined ? goods.images : [],
          uid: goods.uid !== undefined ? goods.uid : (0, _uidHelper.createUid)()
        };

        if (goods.startDate !== '') {
          editGoods.startDate = (0, _dateformatter.formatDateToTimestamp)(goods.startDate);
        }

        if (goods.endDate !== '') {
          editGoods.endDate = (0, _dateformatter.formatDateToTimestamp)(goods.endDate);
        }

        _this.props.handleUpdateButton(editGoods, index);
      }
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.goods[prop] = target.value;
      _this.setState(_this.state.goods);
    }, _this.setFieldAndReplace = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state.goods[prop] = target.value.replace(',', '.');
      _this.setState(_this.state.goods);
    }, _this.setDateField = function (prop, e) {
      var value = e != null ? (0, _dateformatter.formatDateWithMinutes)(e.toISOString()) : '';
      _this.state.goods[prop] = value;
      _this.setState(_this.state.goods);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditGoodsForm, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      if (this.props.shipmentId && this.props.goodsIndex) {
        this.props.getShipmentById(this.props.shipmentId).then(function (shipment) {
          var goods = (0, _extends3.default)({}, shipment.data.goods[_this2.props.goodsIndex], { startDate: (0, _dateformatter.formatDateWithMinutes)(shipment.data.goods[_this2.props.goodsIndex].startDate), endDate: (0, _dateformatter.formatDateWithMinutes)(shipment.data.goods[_this2.props.goodsIndex].endDate) });
          _this2.setState({ goods: goods, goodsIndex: _this2.props.goodsIndex });
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: 'l-two-col__form' },
          _react2.default.createElement(
            'h2',
            null,
            'Redigera gods'
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Godstyp'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'typeOfGoods',
              inputType: 'text',
              value: this.state.goods.typeOfGoods,
              onChange: function onChange(e) {
                return _this3.setField('typeOfGoods', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Datum start'
            ),
            _react2.default.createElement(_TSForm.DatePicker, {
              name: 'startDate',
              value: this.state.goods.startDate,
              onChange: function onChange(e) {
                return _this3.setDateField('startDate', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Datum slut'
            ),
            _react2.default.createElement(_TSForm.DatePicker, {
              name: 'endDate',
              value: this.state.goods.endDate,
              onChange: function onChange(e) {
                return _this3.setDateField('endDate', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Antal'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'quantity',
              inputType: 'text',
              value: this.state.goods.quantity,
              onChange: function onChange(e) {
                return _this3.setFieldAndReplace('quantity', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Enhet'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'fromAddress',
              inputType: 'text',
              value: this.state.goods.unit,
              onChange: function onChange(e) {
                return _this3.setField('unit', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Enhetspris'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'unitPrice',
              inputType: 'text',
              value: this.state.goods.unitPrice,
              onChange: function onChange(e) {
                return _this3.setFieldAndReplace('unitPrice', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              '\xD6vertid'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: this.state.goods.overtimeHours,
              onChange: function onChange(e) {
                return _this3.setFieldAndReplace('overtimeHours', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Beskrivning'
            ),
            _react2.default.createElement(_TSForm.TextArea, {
              name: 'description',
              inputType: 'text',
              value: this.state.goods.description,
              rows: '5',
              onChange: function onChange(e) {
                return _this3.setField('description', e);
              }
            })
          ),
          _react2.default.createElement(_Button2.default, {
            type: _Button.TYPES.PRIMARY,
            text: 'Uppdatera gods',
            onClick: function onClick() {
              return _this3.updateButton(_this3.state.goods, _this3.state.goodsIndex);
            }
          })
        )
      );
    }
  }]);
  return EditGoodsForm;
}(_react.Component);

exports.default = EditGoodsForm;
module.exports = exports['default'];