'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _Toaster = require('./components/ui/Toaster');

var _Routes = require('./Routes');

var _Routes2 = _interopRequireDefault(_Routes);

var _Auth = require('./Auth');

var _Auth2 = _interopRequireDefault(_Auth);

var _Header = require('./components/Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _reactRouterDom.HashRouter,
    null,
    _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_Toaster.Toaster, null),
      _react2.default.createElement(_Auth2.default, null),
      _react2.default.createElement(_Routes2.default, props)
    )
  );
};

module.exports = exports['default'];