'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendVerificationEmail = exports.updateUser = exports.setAuthUser = exports.getUser = exports.signUp = exports.signOut = exports.resetPassword = exports.signIn = exports.verifyAccount = exports.checkVerificationCode = exports.CHECK_CODE_ERROR = exports.CHECK_CODE_SUCCESS = exports.VERIFY_ACCOUNT_ERROR = exports.VERIFY_ACCOUNT_SUCCESS = exports.SIGN_UP_ERROR = exports.RESET_PASSWORD_ERROR = exports.RESET_PASSWORD_SENT = exports.UPDATE_USER = exports.SET_AUTH_USER = exports.SET_USER = exports.VERIFICATION_EMAIL_SENT = exports.SIGN_OUT = exports.NOT_VERIFIED = exports.SIGN_IN_ERROR = exports.SIGN_IN = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _firestore = require('../store/firestore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SIGN_IN = exports.SIGN_IN = 'SIGN_IN';
var SIGN_IN_ERROR = exports.SIGN_IN_ERROR = 'SIGN_IN_ERROR';
var NOT_VERIFIED = exports.NOT_VERIFIED = 'NOT_VERIFIED';
var SIGN_OUT = exports.SIGN_OUT = 'SIGN_OUT';
var VERIFICATION_EMAIL_SENT = exports.VERIFICATION_EMAIL_SENT = 'VERIFICATION_EMAIL_SENT';
var SET_USER = exports.SET_USER = 'SET_USER';
var SET_AUTH_USER = exports.SET_AUTH_USER = 'SET_AUTH_USER';
var UPDATE_USER = exports.UPDATE_USER = 'UPDATE_USER';
var RESET_PASSWORD_SENT = exports.RESET_PASSWORD_SENT = 'RESET_PASSWORD_SENT';
var RESET_PASSWORD_ERROR = exports.RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
var SIGN_UP_ERROR = exports.SIGN_UP_ERROR = 'SIGN_UP_ERROR';
var VERIFY_ACCOUNT_SUCCESS = exports.VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
var VERIFY_ACCOUNT_ERROR = exports.VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';
var CHECK_CODE_SUCCESS = exports.CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS';
var CHECK_CODE_ERROR = exports.CHECK_CODE_ERROR = 'CHECK_CODE_ERROR';

var checkVerificationCode = exports.checkVerificationCode = function checkVerificationCode(oobCode) {
  return (0, _firestore.auth)().checkActionCode(oobCode).then(function (result) {
    return {
      type: CHECK_CODE_SUCCESS,
      data: result
    };
  }).catch(function (error) {
    return {
      type: CHECK_CODE_ERROR,
      data: error
    };
  });
};

var verifyAccount = exports.verifyAccount = function verifyAccount(oobCode) {
  return (0, _firestore.auth)().applyActionCode(oobCode).then(function (user) {
    return {
      type: VERIFY_ACCOUNT_SUCCESS,
      data: user
    };
  }).catch(function (error) {
    return {
      type: VERIFY_ACCOUNT_ERROR,
      data: error
    };
  });
};

var signIn = exports.signIn = function signIn(email, password) {
  return (0, _firestore.auth)().signInWithEmailAndPassword(email, password).then(function (user) {
    return {
      type: user.emailVerified ? SIGN_IN : NOT_VERIFIED,
      data: user
    };
  }).catch(function (error) {
    return { type: SIGN_IN_ERROR, data: error };
  });
};

var resetPassword = exports.resetPassword = function resetPassword(email) {
  return (0, _firestore.auth)().sendPasswordResetEmail(email).then(function () {
    return {
      type: RESET_PASSWORD_SENT
    };
  }).catch(function (error) {
    return {
      type: RESET_PASSWORD_ERROR,
      data: error
    };
  });
};

var signOut = exports.signOut = function signOut(dispatch) {
  _firestore.subscriptions.unsubscribeAll();
  return (0, _firestore.auth)().signOut().then(function (signOut) {
    return dispatch({ type: SIGN_OUT, data: null });
  });
};

var signUp = exports.signUp = function signUp(email, password, firstName, lastName, phone) {
  return (0, _firestore.auth)().createUserWithEmailAndPassword(email, password).then(function (_ref) {
    var user = _ref.user;

    return user.sendEmailVerification().then(function (res) {
      return {
        type: NOT_VERIFIED,
        data: {}
      };
    }).catch(function (error) {
      return { type: SIGN_UP_ERROR, data: error };
    });
  }).catch(function (error) {
    return { type: SIGN_UP_ERROR, data: error };
  });
};

var getUser = exports.getUser = function getUser(authUser) {
  return _firestore.db.collection('users').doc(authUser.uid).get().then(function (user) {
    if (user.exists) {
      return {
        type: SET_USER,
        data: (0, _extends3.default)({}, authUser, user.data())
      };
    }
    return updateUser(authUser);
  }).catch(function (err) {
    return console.log(err);
  });
};

var setAuthUser = exports.setAuthUser = function setAuthUser(user, dispatch) {
  return dispatch({ type: SET_AUTH_USER, data: user });
};

var updateUser = exports.updateUser = function updateUser(user) {
  var uid = user.uid || user.uuid;
  user = {
    uid: uid,
    email: user.email,
    linkedCompanies: user.linkedCompanies || [],
    firstName: user.firstName || null,
    lastName: user.lastName || null,
    phone: user.phone || null
  };
  return _firestore.db.collection('users').doc(uid).set(user).then(function () {
    return {
      type: UPDATE_USER,
      data: user
    };
  });
};

var sendVerificationEmail = exports.sendVerificationEmail = function sendVerificationEmail(dispatch) {
  (0, _firestore.auth)().currentUser.sendEmailVerification();
  return dispatch({
    type: VERIFICATION_EMAIL_SENT,
    data: {}
  });
};