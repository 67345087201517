'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var createUid = exports.createUid = function createUid() {
    var buf = [];
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charlen = chars.length;
    var length = charlen || 32;
    for (var i = 0; i < length; i++) {
        buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join('');
};