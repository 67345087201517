'use strict';

require('whatwg-fetch');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactRedux = require('react-redux');

var _history = require('history');

var _Root = require('./Root');

var _Root2 = _interopRequireDefault(_Root);

var _store = require('./store');

var _store2 = _interopRequireDefault(_store);

require('react-toastify/scss/main.scss');

require('./scss/main.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var history = (0, _history.createHashHistory)();

var store = (0, _store2.default)({}, history);

(0, _reactDom.render)(_react2.default.createElement(
  _reactRedux.Provider,
  { store: store },
  _react2.default.createElement(_Root2.default, { store: store, history: history })
), document.getElementById('root'));