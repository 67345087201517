'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _reactRouterDom = require('react-router-dom');

var _reactRedux = require('react-redux');

var _SignIn = require('../auth/SignIn');

var _SignIn2 = _interopRequireDefault(_SignIn);

var _SignUp = require('../auth/SignUp');

var _SignUp2 = _interopRequireDefault(_SignUp);

var _Toaster = require('../ui/Toaster');

var _ResetPassword = require('../auth/ResetPassword');

var _ResetPassword2 = _interopRequireDefault(_ResetPassword);

var _authError = require('../../utils/auth-error');

var _queryString = require('query-string');

var _queryString2 = _interopRequireDefault(_queryString);

var _user = require('../../actions/user');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user;
  return {
    user: user
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    signUp: function signUp(email, password) {
      return (0, _user.signUp)(email, password).then(dispatch);
    },
    sendVerificationEmail: function sendVerificationEmail() {
      return (0, _user.sendVerificationEmail)(dispatch);
    }
  };
};

var RegisterInvite = function (_Component) {
  (0, _inherits3.default)(RegisterInvite, _Component);

  function RegisterInvite() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, RegisterInvite);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = RegisterInvite.__proto__ || Object.getPrototypeOf(RegisterInvite)).call.apply(_ref2, [this].concat(args))), _this), _this.params = {
      props: _queryString2.default.parse(_this.props.location.search)
    }, _this.state = (0, _defineProperty3.default)({
      firstName: _this.params.props.firstname ? _this.params.props.firstname : '',
      lastName: _this.params.props.lastname ? _this.params.props.lastname : '',
      phone: _this.params.props.phone ? _this.params.props.phone : '',
      email: _this.params.props.email ? _this.params.props.email : '',
      password: '',
      repeatPassword: '',
      loading: false,
      error: null,
      complete: false
    }, 'error', _this.props.error || null), _this.handleSubmit = function (e) {
      e.preventDefault();
      _this.signUp();
    }, _this.handleBackButton = function () {
      _this.props.history.push('/');
    }, _this.handleAlreadyHaveAccount = function () {
      _this.props.history.push('/main');
    }, _this.handleTextFieldChange = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state[prop] = target.value;
      _this.setState(_this.state);
    }, _this.signUp = function () {
      var _this$state2 = _this.state,
          email = _this$state2.email,
          password = _this$state2.password,
          firstName = _this$state2.firstName,
          lastName = _this$state2.lastName,
          phone = _this$state2.phone;

      _this.setState({ loading: true, error: null });
      _this.props.signUp(email, password).then(function (_ref4) {
        var type = _ref4.type;

        if (type === _user.NOT_VERIFIED) {
          (0, _Toaster.showSuccess)('Registrering lyckad. Verifiera din epostadress.', {
            onClose: function onClose() {
              return _this.props.history.push('/shipments');
            },
            autoClose: 2000
          });
        } else if (type === _user.SIGN_UP_ERROR) {
          var error = _this.props.user.error;

          (0, _Toaster.showError)((0, _authError.getErrorMessage)(error), {
            autoClose: 2000
          });
        }
        _this.setState({ loading: false, password: '' });
      }).catch(function () {
        (0, _Toaster.showError)('Oj, någonting gick snett. Försök igen.');
        _this.setState({ loading: false, password: '' });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(RegisterInvite, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: 'background-img' },
          _react2.default.createElement(
            'nav',
            { className: 'nav-back' },
            _react2.default.createElement(
              'a',
              { onClick: this.handleBackButton },
              '\xC5terg\xE5'
            )
          ),
          _react2.default.createElement(
            'main',
            null,
            _react2.default.createElement(
              'div',
              { className: 'l-one-col' },
              _react2.default.createElement('img', { className: 'l-one-col__logo', src: 'images/transportsedeln-logo-green.svg', alt: 'Logo Transportsedeln' }),
              _react2.default.createElement(
                'h1',
                null,
                'Skapa nytt konto'
              ),
              _react2.default.createElement(
                'div',
                { className: 'form-row' },
                this.form
              )
            )
          )
        )
      );
    }
  }, {
    key: 'verificationInfo',
    get: function get() {
      var _props$user = this.props.user,
          emailVerified = _props$user.emailVerified,
          email = _props$user.email,
          emailSent = _props$user.emailSent;

      if (emailSent && email) {
        return _react2.default.createElement(
          'p',
          { className: 'hint' },
          'Verifieringsmail skickat.'
        );
      } else if (emailVerified === false && email) {
        return _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'p',
            { className: 'hint' },
            'Obs. Ett verifieringsmail har skickats till ',
            email,
            '.',
            _react2.default.createElement(
              'a',
              { onClick: this.props.sendVerificationEmail },
              'Skicka igen'
            )
          )
        );
      }
      return null;
    }
  }, {
    key: 'error',
    get: function get() {
      var _props$user2 = this.props.user,
          error = _props$user2.error,
          emailVerified = _props$user2.emailVerified;

      if (emailVerified === false && error === null) {
        error = { code: 'auth/user-not-verified' };
      }
      return _react2.default.createElement(
        'p',
        { className: 'error' },
        (0, _authError.getErrorMessage)(error)
      );
    }
  }, {
    key: 'form',
    get: function get() {
      var _props = this.props,
          loading = _props.loading,
          email = _props.email,
          password = _props.password,
          repeatPassword = _props.repeatPassword,
          firstName = _props.firstName,
          lastName = _props.lastName,
          phone = _props.phone;


      return _react2.default.createElement(
        'form',
        { noValidate: true,
          method: 'post',
          className: 'form form--modal',
          onSubmit: this.handleSubmit },
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'F\xF6rnamn'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            value: this.state.firstName,
            name: 'firstname',
            onChange: this.handleTextFieldChange.bind(null, 'firstName')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'Efternamn'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            value: this.state.lastName,
            name: 'lastname',
            onChange: this.handleTextFieldChange.bind(null, 'lastName')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'Telefon'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            name: 'phone',
            value: this.state.phone,
            onChange: this.handleTextFieldChange.bind(null, 'phone')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'E-postadress'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            name: 'email',
            value: this.params.props.email
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'L\xF6senord'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'password',
            name: 'password',
            value: this.state.password,
            onChange: this.handleTextFieldChange.bind(null, 'password')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'Repetera l\xF6senord'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'password',
            name: 'repeatpassword',
            value: this.state.repeatPassword,
            onChange: this.handleTextFieldChange.bind(null, 'repeatPassword')
          })
        ),
        _react2.default.createElement(_Button2.default, {
          disabled: loading,
          type: _Button.TYPES.PRIMARY,
          text: 'Registrera' }),
        _react2.default.createElement(
          'p',
          null,
          'Har du redan ett konto?',
          _react2.default.createElement(
            'a',
            { onClick: this.handleAlreadyHaveAccount },
            'Logga in'
          )
        )
      );
    }
  }]);
  return RegisterInvite;
}(_react.Component);

exports.default = (0, _reactRouterDom.withRouter)((0, _reactRedux.connect)(stateToProps, dispatchToProps)(RegisterInvite));
module.exports = exports['default'];