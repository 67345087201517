'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showSuccess = exports.showError = exports.Toaster = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactToastify = require('react-toastify');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Toaster = exports.Toaster = function Toaster() {
  return _react2.default.createElement(_reactToastify.ToastContainer, { className: 'toaster' });
};

var showError = exports.showError = function showError(message, opts) {
  return _reactToastify.toast.error(message, opts);
};
var showSuccess = exports.showSuccess = function showSuccess(message, opts) {
  return _reactToastify.toast.success(message, opts);
};