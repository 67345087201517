'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShipmentListHeader = function ShipmentListHeader() {
  return _react2.default.createElement(
    'li',
    { key: 'header',
      className: 'shipment-list__header' },
    _react2.default.createElement(
      'span',
      null,
      'Sedel'
    ),
    _react2.default.createElement(
      'span',
      null,
      'Status'
    ),
    _react2.default.createElement(
      'span',
      null,
      '\xC5keri'
    ),
    _react2.default.createElement(
      'span',
      null,
      'F\xF6rare'
    ),
    _react2.default.createElement(
      'span',
      null,
      'Kund'
    ),
    _react2.default.createElement(
      'span',
      null,
      'Skapad'
    )
  );
};

exports.default = ShipmentListHeader;
module.exports = exports['default'];