'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Contact = function Contact() {
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(_Header2.default, undefined.props),
    _react2.default.createElement(
      'div',
      { id: 'start' },
      _react2.default.createElement(
        'div',
        { id: 'home' },
        _react2.default.createElement(
          'section',
          { className: 'home' },
          _react2.default.createElement(
            'div',
            { className: 'privacy-box' },
            _react2.default.createElement(
              'h1',
              null,
              'Kontakta oss'
            ),
            _react2.default.createElement('p', null),
            _react2.default.createElement(
              'h2',
              null,
              'Hur anv\xE4nder Transportsedeln cookies?'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Har du fr\xE5gor ber vi dig kontakta oss via ',
              _react2.default.createElement(
                'a',
                { href: 'mailto:info@transportsedeln.se' },
                'info@transportsedeln.se'
              ),
              _react2.default.createElement('br', null),
              'Hj\xE4lp oss bli b\xE4ttre genom att dela med dig av dina synpunkter och dina upplevelser.'
            ),
            _react2.default.createElement(
              'p',
              null,
              'Sidan uppdaterades 2019-05-20.'
            )
          )
        )
      )
    )
  );
};
exports.default = Contact;
module.exports = exports['default'];