'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Input = function Input(_ref) {
  var inputType = _ref.inputType,
      error = _ref.error,
      value = _ref.value,
      hidden = _ref.hidden,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['inputType', 'error', 'value', 'hidden']);
  return _react2.default.createElement(
    'div',
    { className: 'input-wrapper' },
    _react2.default.createElement('input', (0, _extends3.default)({
      className: [error === true ? 'invalid' : '', hidden === true ? 'hidden' : ''].join(' '),
      type: inputType }, rest, {
      value: value || '',
      onChange: rest.onChange || function () {}
    })),
    error ? _react2.default.createElement(
      'span',
      { className: 'input-error' },
      error
    ) : null
  );
};

exports.default = Input;
module.exports = exports['default'];