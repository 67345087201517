'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _SignIn = require('../auth/SignIn');

var _SignIn2 = _interopRequireDefault(_SignIn);

var _SignUp = require('../auth/SignUp');

var _SignUp2 = _interopRequireDefault(_SignUp);

var _Toaster = require('../ui/Toaster');

var _reactRouterDom = require('react-router-dom');

var _ResetPassword = require('../auth/ResetPassword');

var _ResetPassword2 = _interopRequireDefault(_ResetPassword);

var _authError = require('../../utils/auth-error');

var _user = require('../../actions/user');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user;
  return {
    user: user
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    signIn: function signIn(email, password) {
      return (0, _user.signIn)(email, password).then(dispatch);
    },
    signUp: function signUp(email, password) {
      return (0, _user.signUp)(email, password).then(dispatch);
    },
    signOut: function signOut() {
      return (0, _user.signOut)(dispatch);
    },
    sendVerificationEmail: function sendVerificationEmail() {
      return (0, _user.sendVerificationEmail)(dispatch);
    },
    resetPassword: function resetPassword(email) {
      return (0, _user.resetPassword)(email).then(dispatch);
    }
  };
};

var Main = function (_Component) {
  (0, _inherits3.default)(Main, _Component);

  function Main() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Main);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Main.__proto__ || Object.getPrototypeOf(Main)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      form: 'sign-in',
      email: '',
      password: '',
      loading: false,
      error: null
    }, _this.componentDidMount = function () {
      var search = _this.props.history.location.search;
      if (search.includes('reset-password')) {
        _this.setState({ form: 'reset-password' });
      } else if (search.includes('sign-up')) {
        _this.setState({ form: 'sign-up' });
      } else {
        _this.setState({ form: 'sign-in' });
      }

      // so we know if shipmentlist is refresehd or not after sign in
      localStorage.setItem('pageIsRefreshed', 'false');
    }, _this.handleTextFieldChange = function (prop, _ref3) {
      var target = _ref3.target;

      _this.state[prop] = target.value;
      _this.setState(_this.state);
    }, _this.changeForm = function (form) {
      _this.setState({ form: form });
    }, _this.signUp = function () {
      var _this$state = _this.state,
          email = _this$state.email,
          password = _this$state.password,
          firstName = _this$state.firstName,
          lastName = _this$state.lastName,
          phone = _this$state.phone;

      _this.setState({ loading: true, error: null });
      _this.props.signUp(email, password).then(function (_ref4) {
        var type = _ref4.type;

        if (type === _user.NOT_VERIFIED) {
          (0, _Toaster.showSuccess)('Registrering lyckad. Verifiera din epostadress.', {
            onClose: function onClose() {
              return _this.props.history.push('/shipments');
            },
            autoClose: 2000
          });
        } else if (type === _user.SIGN_UP_ERROR) {
          var error = _this.props.user.error;

          (0, _Toaster.showError)((0, _authError.getErrorMessage)(error), {
            autoClose: 2000
          });
        }
        _this.setState({ loading: false, password: '' });
      }).catch(function () {
        (0, _Toaster.showError)('Oj, någonting gick snett. Försök igen.');
        _this.setState({ loading: false, password: '' });
      });
    }, _this.backButton = function () {
      var form = _this.state.form;


      switch (form) {
        case 'sign-in':
          _this.props.history.push('/');
          break;
        case 'sign-up':
          _this.changeForm('sign-in');
          break;
        case 'reset-password':
          _this.changeForm('sign-in');
          break;
      }
    }, _this.signIn = function () {
      _this.props.signIn(_this.state.email, _this.state.password).then(function (_ref5) {
        var type = _ref5.type;

        _this.setState({ loading: false });
      }).catch(function () {
        (0, _Toaster.showError)('Oj, någonting gick snett. Försök igen senare.');
        _this.setState({ loading: false });
      });
    }, _this.resetPassword = function () {
      _this.setState({ loading: true, error: null });
      _this.props.resetPassword(_this.state.email).then(function (_ref6) {
        var type = _ref6.type;

        if (type === _user.RESET_PASSWORD_SENT) {
          (0, _Toaster.showSuccess)('Epost har skickats', {
            autoClose: 1000
          });
        } else {
          var error = _this.props.user.error;

          (0, _Toaster.showError)((0, _authError.getErrorMessage)(error), {
            autoClose: 2000
          });
        }
        _this.setState({ loading: false, email: '' });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Main, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { onClick: this.backButton },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'main',
          null,
          _react2.default.createElement(
            'div',
            { className: 'l-one-col' },
            _react2.default.createElement('img', { className: 'l-one-col__logo', src: 'images/transportsedeln-logo-green.svg', alt: 'Logo Transportsedeln' }),
            this.form
          )
        )
      );
    }
  }, {
    key: 'form',
    get: function get() {
      var form = this.state.form;


      if (this.props.user.emailVerified === true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      switch (form) {
        case 'sign-up':
          return _react2.default.createElement(_SignUp2.default, (0, _extends3.default)({}, this.state, this.props, {
            handleSubmit: this.signUp,
            backButton: this.backButton,
            changeForm: this.changeForm,
            setField: this.handleTextFieldChange
          }));
        case 'reset-password':
          return _react2.default.createElement(_ResetPassword2.default, (0, _extends3.default)({}, this.state, this.props, {
            handleSubmit: this.resetPassword,
            backButton: this.backButton,
            changeForm: this.changeForm,
            setField: this.handleTextFieldChange
          }));
        default:
          return _react2.default.createElement(_SignIn2.default, (0, _extends3.default)({}, this.state, this.props, {
            handleSubmit: this.signIn,
            backButton: this.backButton,
            changeForm: this.changeForm,
            setField: this.handleTextFieldChange
          }));
      }
    }
  }]);
  return Main;
}(_react.Component);

exports.default = (0, _reactRouterDom.withRouter)((0, _reactRedux.connect)(stateToProps, dispatchToProps)(Main));
module.exports = exports['default'];