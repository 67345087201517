'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _dateformatter = require('../utils/dateformatter');

var _firestore = require('../../store/firestore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShipmentItem = function (_Component) {
  (0, _inherits3.default)(ShipmentItem, _Component);

  function ShipmentItem() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, ShipmentItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = ShipmentItem.__proto__ || Object.getPrototypeOf(ShipmentItem)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      companyName: 'N/A'
    }, _this.edit = function (e) {
      e.stopPropagation();
      _this.props.history.push('/shipment/' + _this.props.data.uid + '/?filter=' + _this.props.showState);
    }, _this.getDriverName = function (email) {
      if (email === _this.props.user.email) {
        return 'Mig själv';
      }

      if (_this.props.company != null && _this.props.company.companies[0] != null) {
        var emp = _this.props.company.companies[0].employees.find(function (e) {
          return e.email === email;
        });
        if (emp) {
          return emp.firstName + ' ' + emp.lastName;
        }
      }

      return email;
    }, _this.getEmployeeCompanyName = function (email) {
      if (_this.props.company != null && _this.props.company.companies[0] != null) {
        var emp = _this.props.company.companies[0].employees.find(function (e) {
          return e.email === email;
        });

        return emp !== undefined && emp.companyName !== undefined ? emp.companyName : _this.props.company.companies[0].companyName;
      }
      // todo: hämta companyname mha companyid
      return 'N/A';
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(ShipmentItem, [{
    key: 'render',
    value: function render() {
      var _props$data = this.props.data,
          state = _props$data.state,
          customer = _props$data.customer,
          assignedTo = _props$data.assignedTo,
          createdAt = _props$data.createdAt,
          tag = _props$data.tag;

      var driver = this.getDriverName(assignedTo.email);
      var searchTag = tag ? tag.toLowerCase() : '';
      var displayState = '';
      switch (state) {
        case 'approved':
          displayState = 'Godkänd';
          break;
        case 'signed':
          displayState = 'Signerad';
          break;
        case 'invoiced':
          displayState = 'Fakturerad';
          break;
        default:
          displayState = 'Skapad';
          break;
      }

      var displayDate = (0, _dateformatter.formatDate)(createdAt);
      var companyName = this.getEmployeeCompanyName(assignedTo.email);
      var isVisible = searchTag.includes(this.props.filter) || companyName && companyName.toLowerCase().includes(this.props.filter) || driver && driver.toLowerCase().includes(this.props.filter) || customer && customer.toLowerCase().includes(this.props.filter) || displayDate.includes(this.props.filter);

      if (this.props.showState !== 'all' && state !== this.props.showState && this.props.showState !== 'assigned') {
        isVisible = false;
      }

      if (this.props.showState === 'assigned' && assignedTo.email !== this.props.user.email) {
        isVisible = false;
      }

      if (isVisible) {
        return _react2.default.createElement(
          'li',
          { className: 'shipment-list__links' },
          _react2.default.createElement(
            'a',
            { onClick: this.edit },
            _react2.default.createElement(
              'span',
              { className: 'shipment-list__item' },
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                'Sedel:'
              ),
              tag
            ),
            _react2.default.createElement(
              'span',
              null,
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                'Status:'
              ),
              displayState
            ),
            _react2.default.createElement(
              'span',
              { className: 'shipment-list__item' },
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                '\xC5keri:'
              ),
              companyName
            ),
            _react2.default.createElement(
              'span',
              { className: 'shipment-list__item' },
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                'F\xF6rare:'
              ),
              driver
            ),
            _react2.default.createElement(
              'span',
              { className: 'shipment-list__item' },
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                'Kund:'
              ),
              customer
            ),
            _react2.default.createElement(
              'span',
              null,
              _react2.default.createElement(
                'span',
                { className: 'shipment-list__item-header' },
                'Skapad:'
              ),
              displayDate
            )
          )
        );
      } else {
        return null;
      }
    }
  }]);
  return ShipmentItem;
}(_react.Component);

exports.default = ShipmentItem;
module.exports = exports['default'];