'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Toaster = require('../ui/Toaster');

var _reactRouterDom = require('react-router-dom');

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditCompanyForm = function (_Component) {
  (0, _inherits3.default)(EditCompanyForm, _Component);

  function EditCompanyForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, EditCompanyForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = EditCompanyForm.__proto__ || Object.getPrototypeOf(EditCompanyForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      company: {
        streetAdress: '',
        zipcode: '',
        city: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        overtimePrice: ''
      },
      redirectToSettings: false
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      if (prop && prop === 'email') {
        _this.state.company[prop] = target.value.toLowerCase();
      } else if (prop && prop === 'overtimePrice') {
        _this.state.company[prop] = target.value.replace(',', '.');
      } else {
        _this.state.company[prop] = target.value;
      }
      _this.setState(_this.state);
    }, _this.goToSettings = function () {
      _this.setState({ redirectToSettings: true });
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      _this.props.updateCompany(_this.props.user.uid, _this.state.company).then(function () {
        (0, _Toaster.showSuccess)('Uppdatering lyckad.');
        _this.goToSettings();
      }).catch(function () {
        return (0, _Toaster.showError)('Uppdateringen kunde inte genomföras.');
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(EditCompanyForm, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.company.companies[0]) {
        this.setState({ company: this.props.company.companies[0] });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      if (this.state.redirectToSettings === true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/settings' });
      }

      var _state$company = this.state.company,
          companyName = _state$company.companyName,
          orgNumber = _state$company.orgNumber,
          streetAdress = _state$company.streetAdress,
          zipcode = _state$company.zipcode,
          city = _state$company.city,
          firstName = _state$company.firstName,
          lastName = _state$company.lastName,
          phone = _state$company.phone,
          email = _state$company.email,
          overtimePrice = _state$company.overtimePrice;


      return _react2.default.createElement(
        'form',
        { className: 'form form--settings',
          onSubmit: this.handleSubmit
        },
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Adress'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: streetAdress,
              onChange: function onChange(e) {
                return _this2.setField('streetAdress', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Postnummer'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: zipcode,
              onChange: function onChange(e) {
                return _this2.setField('zipcode', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Ort'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: city,
              onChange: function onChange(e) {
                return _this2.setField('city', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              '\xD6vertidspris'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: overtimePrice,
              onChange: function onChange(e) {
                return _this2.setField('overtimePrice', e);
              }
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'Kontaktperson'
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'F\xF6rnamn'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: firstName,
              onChange: function onChange(e) {
                return _this2.setField('firstName', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Efternamn'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: lastName,
              onChange: function onChange(e) {
                return _this2.setField('lastName', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'Telefon'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: phone,
              onChange: function onChange(e) {
                return _this2.setField('phone', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'E-post'
            ),
            _react2.default.createElement(_Input2.default, {
              inputType: 'text',
              value: email,
              onChange: function onChange(e) {
                return _this2.setField('email', e);
              }
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'l-two-col__btns' },
          _react2.default.createElement(_Button2.default, {
            text: 'Avbryt',
            type: _Button.TYPES.SECONDARY,
            onClick: this.goToSettings
          }),
          _react2.default.createElement(_Button2.default, {
            text: 'Uppdatera',
            type: _Button.TYPES.PRIMARY,
            onClick: this.handleSubmit
          })
        )
      );
    }
  }]);
  return EditCompanyForm;
}(_react.Component);

exports.default = EditCompanyForm;
module.exports = exports['default'];