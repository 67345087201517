'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _authError = require('../../utils/auth-error');

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SignIn = function (_Component) {
  (0, _inherits3.default)(SignIn, _Component);

  function SignIn() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SignIn);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SignIn.__proto__ || Object.getPrototypeOf(SignIn)).call.apply(_ref, [this].concat(args))), _this), _this.handleSubmit = function (e) {
      e.preventDefault();
      _this.props.handleSubmit();
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SignIn, [{
    key: 'refresh',
    value: function refresh() {
      window.location.reload();
    }
  }, {
    key: 'render',
    value: function render() {
      var _props$user = this.props.user,
          error = _props$user.error,
          emailVerified = _props$user.emailVerified;


      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          'h2',
          null,
          'Logga in '
        ),
        this.error,
        this.verificationInfo,
        emailVerified === false && error === null ? this.reloadButton : this.form
      );
    }
  }, {
    key: 'verificationInfo',
    get: function get() {
      var _props$user2 = this.props.user,
          emailVerified = _props$user2.emailVerified,
          email = _props$user2.email,
          emailSent = _props$user2.emailSent;

      if (emailSent && email) {
        return _react2.default.createElement(
          'p',
          { className: 'hint' },
          'Verifieringsmail skickat.'
        );
      } else if (emailVerified === false && email) {
        return _react2.default.createElement(
          _react.Fragment,
          null,
          _react2.default.createElement(
            'p',
            { className: 'hint' },
            'Obs. Ett verifieringsmail har skickats till ',
            email,
            '.',
            _react2.default.createElement(
              'a',
              { onClick: this.props.sendVerificationEmail },
              'Skicka igen'
            )
          )
        );
      }
      return null;
    }
  }, {
    key: 'error',
    get: function get() {
      var _props$user3 = this.props.user,
          error = _props$user3.error,
          emailVerified = _props$user3.emailVerified;

      if (emailVerified === false && error === null) {
        error = { code: 'auth/user-not-verified' };
      }
      return _react2.default.createElement(
        'p',
        { className: 'error' },
        (0, _authError.getErrorMessage)(error)
      );
    }
  }, {
    key: 'reloadButton',
    get: function get() {
      return _react2.default.createElement(
        'div',
        { className: 'form-item form-text' },
        _react2.default.createElement(
          'p',
          null,
          'N\xE4r du verifierat ditt konto klickar du p\xE5 forts\xE4tt.'
        ),
        _react2.default.createElement(_Button2.default, {
          type: _Button.TYPES.PRIMARY,
          size: _Button.SIZES.LARGE,
          text: 'Forts\xE4tt',
          onClick: this.refresh.bind()
        })
      );
    }
  }, {
    key: 'form',
    get: function get() {
      var _props = this.props,
          loading = _props.loading,
          email = _props.email,
          password = _props.password,
          setField = _props.setField;


      return _react2.default.createElement(
        'form',
        { noValidate: true,
          method: 'post',
          className: 'form form--modal',
          onSubmit: this.handleSubmit },
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'E-postadress'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'text',
            placeholder: 'Epostadress',
            name: 'email',
            value: email,
            onChange: setField.bind(null, 'email')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item form-text' },
          _react2.default.createElement(
            'label',
            null,
            'L\xF6senord'
          ),
          _react2.default.createElement(
            'a',
            { className: 'right link', onClick: this.props.changeForm.bind(null, 'reset-password') },
            '\xC5terst\xE4ll l\xF6senord'
          ),
          _react2.default.createElement(_Input2.default, {
            type: 'password',
            name: 'password',
            icon: 'stroke-lock',
            placeholder: 'L\xF6senord',
            value: password,
            onChange: setField.bind(this, 'password')
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-item text-center' },
          _react2.default.createElement(_Button2.default, {
            disabled: loading,
            type: _Button.TYPES.PRIMARY,
            size: _Button.SIZES.LARGE,
            text: 'Logga in'
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'text-center' },
          _react2.default.createElement(
            'a',
            { className: 'link', onClick: this.props.changeForm.bind(null, 'sign-up') },
            'Registrera konto'
          )
        )
      );
    }
  }]);
  return SignIn;
}(_react.Component);

exports.default = SignIn;
module.exports = exports['default'];