'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _firestore = require('../../store/firestore');

var _ModalBox = require('../ui/ModalBox');

var _ModalBox2 = _interopRequireDefault(_ModalBox);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactRouterDom = require('react-router-dom');

var _company = require('../../actions/company');

var _invite = require('../../actions/invite');

var _user = require('../../actions/user');

var _CompanyInfo = require('../company/CompanyInfo');

var _CompanyInfo2 = _interopRequireDefault(_CompanyInfo);

var _CompanyConnections = require('../company/CompanyConnections');

var _CompanyConnections2 = _interopRequireDefault(_CompanyConnections);

var _Header = require('../Header');

var _Header2 = _interopRequireDefault(_Header);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Toaster = require('../ui/Toaster');

var _array = require('../utils/array');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.locale('sv');

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user,
      company = _ref.company;
  return {
    user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    signOut: function signOut() {
      return (0, _user.signOut)(dispatch);
    },
    updateUser: function updateUser(user) {
      return (0, _user.updateUser)(user).then(dispatch);
    },
    acceptInvite: function acceptInvite(user, invite) {
      return (0, _invite.acceptInvite)(user, invite).then(dispatch);
    },
    acceptEmployeeInvite: function acceptEmployeeInvite(user, invite) {
      return (0, _invite.acceptEmployeeInvite)(user, invite).then(dispatch);
    },
    rejectInvite: function rejectInvite(user, invite) {
      return (0, _invite.rejectInvite)(user, invite).then(dispatch);
    },
    cancelInvite: function cancelInvite(user, invite) {
      return (0, _invite.cancelInvite)(user, invite).then(dispatch);
    },
    getLinkedCompanies: function getLinkedCompanies(companies) {
      return (0, _company.getLinkedCompanies)(companies).then(dispatch);
    },
    createCompany: function createCompany(uid, data) {
      return (0, _company.createCompany)(uid, data).then(dispatch);
    },
    updateCompany: function updateCompany(uid, data) {
      return (0, _company.updateCompany)(uid, data).then(dispatch);
    },
    createInvite: function createInvite(company, email, isContractor) {
      return (0, _invite.createInvite)(company, email, isContractor).then(dispatch).catch(dispatch);
    },
    removeInvite: function removeInvite(invite) {
      return (0, _invite.removeInvite)(invite).then(dispatch);
    }
  };
};

var Settings = function (_Component) {
  (0, _inherits3.default)(Settings, _Component);

  function Settings() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Settings);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Settings.__proto__ || Object.getPrototypeOf(Settings)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      view: undefined,
      numberOfEmployees: 0,
      updateSettingsModal: false,
      informationModal: false,
      data: {
        firstName: '',
        lastName: '',
        phone: '',
        inviteEmail: ''
      },
      linkedCompanies: [],
      acceptedInvites: [],
      closedInvitesCurrentMonth: 0,
      pendingInvites: [],
      acceptedCompanyInvites: [],
      pendingCompanyInvites: [],
      acceptedInvitesFromMyCompany: [],
      pendingInvitesFromMyCompany: [],
      acceptedEmployeeInvites: [],
      pendingEmployeeInvites: [],
      companyNames: [],
      inviteVisible: false,
      employees: []
    }, _this.setEmployees = function () {
      if (_this.props.company.companies[0] === undefined) {
        return;
      }

      var emps = (0, _array.flatten)(_this.props.company.companies.map(function (c) {
        return c.employees;
      }));
      _this.setState({ employees: emps });
    }, _this.createCompany = function () {
      _this.props.history.push('/company');
    }, _this.editCompanyInfo = function () {
      _this.props.history.push('/editcompany');
    }, _this.toggleModalWindow = function () {
      if (_this.props.user.email) {
        _this.state.data.firstName = _this.props.user.firstName;
        _this.state.data.lastName = _this.props.user.lastName;
        _this.state.data.phone = _this.props.user.phone;
        _this.setState(_this.state);
        _this.setState({ updateSettingsModal: !_this.state.updateSettingsModal });
      }
    }, _this.toggleInformationModalWindow = function () {
      _this.setState({ informationModal: !_this.state.informationModal });
    }, _this.submitUpdateUser = function (e) {
      e.preventDefault();

      if (_this.state.data.firstName) {
        _this.props.user.firstName = _this.state.data.firstName;
      } else {
        return (0, _Toaster.showError)('Du måste ange att förnamn');
      }
      if (_this.state.data.lastName) {
        _this.props.user.lastName = _this.state.data.lastName;
      } else {
        return (0, _Toaster.showError)('Du måste ange att efternamn');
      }
      if (_this.state.data.phone) {
        _this.props.user.phone = _this.state.data.phone;
      } else {
        return (0, _Toaster.showError)('Du måste ange ett telefonnummer');
      }

      if (_this.props.user.firstName && _this.props.user.lastName && _this.props.user.phone) {
        _this.props.updateUser(_this.props.user).then(function () {
          (0, _Toaster.showSuccess)('Användarinformationen har uppdaterats!');
          _this.setState({ updateSettingsModal: !_this.state.updateSettingsModal });
        }).catch(function () {
          (0, _Toaster.showError)('Ett fel uppstod vid uppdatering av användarinformation');
        });
      }
    }, _this.setField = function (prop, _ref3) {
      var target = _ref3.target;

      if (prop === 'inviteEmail') {
        _this.state.data[prop] = target.value.toLowerCase();
      } else {
        _this.state.data[prop] = target.value;
      }
      _this.setState(_this.state);
    }, _this.sendMail = function () {
      var bodyText = 'Hej<br /><br />Du har blivit inbjuden till transportsedeln som kontrakt\xF6r av ' + _this.props.company.companies[0].companyName + '.';
      _firestore.db.collection('mail').add({
        to: _this.state.data.inviteEmail,
        message: {
          subject: 'Inbjudan till Transportsedeln',
          html: bodyText
        }
      }).then(function () {
        return console.log('Queued email for delivery!');
      });
    }, _this.toggleSendInvitation = function (e) {
      e.preventDefault();
      if (_this.state.inviteVisible === false) {
        _this.setState({
          inviteVisible: true
        });
      } else {
        _this.setState({
          inviteVisible: false
        });
        _this.props.history.push('/settings');
      }
    }, _this.sendInvitation = function (e) {
      e.preventDefault();
      _this.props.createInvite(_this.props.company.companies[0], _this.state.data.inviteEmail, true).then(function () {
        (0, _Toaster.showSuccess)('En inbjudan har skickats!');
        _this.sendMail();
        _this.state.data.inviteEmail = '';
        _this.setState(_this.state);
        _this.setState({ inviteSucceeded: true });
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Settings, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.user.emailVerified !== true) {
        return;
      }

      this.getInvites();
      this.getCompanyInvites();
      this.getInvitesFromMyCompany();
      this.getEmployeeInvites();
      this.setEmployees();
    }
  }, {
    key: 'getInvites',
    value: function getInvites() {
      var _this2 = this;

      if (this.props.company.companies[0] === undefined) {
        return;
      }

      // get invites where receiver is a employee (contractor is false)
      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('contractor', '==', false).where('status', '==', 'closed').where('closedAt', '>', Date.parse(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01')).get().then(function (result) {
        if (!result.empty) {
          var closedInvites = result.docs.map(function (s) {
            return (0, _extends3.default)({}, s.data());
          });
          _this2.setState({
            closedInvitesCurrentMonth: closedInvites.length
          });
        }
      });

      // get invites where receiver is a employee (contractor is false)
      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('contractor', '==', false).where('status', '==', 'accepted').get().then(function (result) {
        if (!result.empty) {
          _this2.setState({
            acceptedInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });

      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('contractor', '==', false).where('status', '==', 'pending').get().then(function (result) {
        if (!result.empty) {
          _this2.setState({
            pendingInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });
    }
  }, {
    key: 'getEmployeeInvites',
    value: function getEmployeeInvites() {
      var _this3 = this;

      if (this.props.company.companies[0] !== undefined) {
        return;
      }

      // get invites where receiver is current user (contractor is false)
      _firestore.db.collection('invites').where('receiver', '==', this.props.user.email).where('contractor', '==', false).where('status', '==', 'accepted').get().then(function (result) {
        if (!result.empty) {
          _this3.setState({
            acceptedEmployeeInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });

      _firestore.db.collection('invites').where('receiver', '==', this.props.user.email).where('contractor', '==', false).where('status', '==', 'pending').get().then(function (result) {
        if (!result.empty) {
          _this3.setState({
            pendingEmployeeInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });
    }
  }, {
    key: 'getCompanyInvites',
    value: function getCompanyInvites() {
      var _this4 = this;

      // get invites where receiver is a contractor

      _firestore.db.collection('invites').where('receiver', '==', this.props.user.email).where('contractor', '==', true).where('status', '==', 'accepted').get().then(function (result) {
        if (!result.empty) {
          _this4.setState({
            acceptedCompanyInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });

      _firestore.db.collection('invites').where('receiver', '==', this.props.user.email).where('contractor', '==', true).where('status', '==', 'pending').get().then(function (result) {
        if (!result.empty) {
          _this4.setState({
            pendingCompanyInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });
    }
  }, {
    key: 'getInvitesFromMyCompany',
    value: function getInvitesFromMyCompany() {
      var _this5 = this;

      if (this.props.company.companies[0] === undefined) {
        return;
      }

      // get the accepted invites
      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('contractor', '==', true).where('status', '==', 'accepted').get().then(function (resultInvite) {
        if (!resultInvite.empty) {
          _this5.setState({
            acceptedInvitesFromMyCompany: resultInvite.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });

          // TODO refaktorera... exakt samma kod finns på fler ställen
          resultInvite.docs.map(function (s) {
            return (0, _extends3.default)({}, s.data());
          }).forEach(function (r) {
            var _this6 = this;

            // and then get the user
            _firestore.db.collection('users').where('email', '==', r.receiver).get().then(function (resultUser) {
              if (!resultUser.empty) {
                var user = (0, _array.flatten)(resultUser.docs.map(function (s) {
                  return (0, _extends3.default)({}, s.data());
                }));
                // and at last the users company
                _firestore.db.collection('users/' + user.uid + '/companies').get().then(function (resultCompany) {
                  if (!resultCompany.empty) {
                    var comps = resultCompany.docs.map(function (s) {
                      return (0, _extends3.default)({}, s.data());
                    });
                    _this6.setState(function (prevState) {
                      return {
                        companyNames: [].concat((0, _toConsumableArray3.default)(prevState.companyNames), [{ companyName: comps[0].companyName, owner: user.uid, email: user.email }])
                      };
                    });
                  }
                });
              }
            });
          }.bind(_this5));
        }
      });

      // get the pending invites
      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('contractor', '==', true).where('status', '==', 'pending').get().then(function (resultInvite) {
        if (!resultInvite.empty) {
          _this5.setState({
            pendingInvitesFromMyCompany: resultInvite.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }

        // TODO refaktorera... exakt samam kod finns på fler ställen
        resultInvite.docs.map(function (s) {
          return (0, _extends3.default)({}, s.data());
        }).forEach(function (r) {
          var _this7 = this;

          // and then get the user
          _firestore.db.collection('users').where('email', '==', r.receiver).get().then(function (resultUser) {
            if (!resultUser.empty) {
              var user = (0, _array.flatten)(resultUser.docs.map(function (s) {
                return (0, _extends3.default)({}, s.data());
              }));
              // and at last the users company
              _firestore.db.collection('users/' + user.uid + '/companies').get().then(function (resultCompany) {
                if (!resultCompany.empty) {
                  var comps = resultCompany.docs.map(function (s) {
                    return (0, _extends3.default)({}, s.data());
                  });
                  _this7.setState(function (prevState) {
                    return {
                      companyNames: [].concat((0, _toConsumableArray3.default)(prevState.companyNames), [{ companyName: comps[0].companyName, owner: user.uid, email: user.email }])
                    };
                  });
                }
              });
            }
          });
        }.bind(_this5));
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this8 = this;

      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }
      var company = this.props.company.companies[0];

      // Hide invite if user have no company
      var inviteLink = void 0;
      var modalContent = _react2.default.createElement(
        'span',
        null,
        _react2.default.createElement(
          'p',
          null,
          'H\xE4r ser du vilket f\xF6retag du \xE4r kopplad till.'
        )
      );

      if (company !== undefined) {
        inviteLink = _react2.default.createElement(
          'a',
          { onClick: this.toggleSendInvitation, className: 'link right', style: { padding: '0' } },
          'Bjud in'
        );

        modalContent = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(
            'p',
            null,
            'F\xF6retagsanslutningar visar era kopplingar till samarbetspartners, det vill s\xE4ga era kunder och kontrakt\xF6rer.'
          ),
          _react2.default.createElement(
            'p',
            null,
            'De externa f\xF6retag du bjuder in visas med status Kontrakt\xF6r. Vilka du kan distribuera ut transportsedlar till.'
          ),
          _react2.default.createElement(
            'p',
            null,
            'De f\xF6retag du accepterat en inbjudan ifr\xE5n visas i listan med status Kund. Dessa f\xF6retag kan distribuera transportsedlar till er.'
          )
        );
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_Header2.default, (0, _extends3.default)({}, this.props, { active: 'settings' })),
        _react2.default.createElement(
          'main',
          null,
          _react2.default.createElement(
            'div',
            { className: 'container' },
            _react2.default.createElement(
              'div',
              { className: 'card-container' },
              _react2.default.createElement(
                'div',
                { className: 'card' },
                _react2.default.createElement(
                  'a',
                  { className: 'link right', onClick: this.toggleModalWindow },
                  '\xC4ndra'
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  'Inst\xE4llningar'
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'form-row' },
                  _react2.default.createElement(
                    'h3',
                    null,
                    'Kontouppgifter'
                  ),
                  _react2.default.createElement(
                    'ul',
                    { className: 'list list--color' },
                    _react2.default.createElement(
                      'li',
                      null,
                      _react2.default.createElement(
                        'span',
                        null,
                        'F\xF6rnamn'
                      ),
                      _react2.default.createElement(
                        'span',
                        null,
                        this.props.user.firstName
                      )
                    ),
                    _react2.default.createElement(
                      'li',
                      null,
                      _react2.default.createElement(
                        'span',
                        null,
                        'Efternamn'
                      ),
                      _react2.default.createElement(
                        'span',
                        null,
                        this.props.user.lastName
                      )
                    ),
                    _react2.default.createElement(
                      'li',
                      null,
                      _react2.default.createElement(
                        'span',
                        null,
                        'Telefon'
                      ),
                      _react2.default.createElement(
                        'span',
                        null,
                        this.props.user.phone
                      )
                    ),
                    _react2.default.createElement(
                      'li',
                      null,
                      _react2.default.createElement(
                        'span',
                        null,
                        'E-post'
                      ),
                      _react2.default.createElement(
                        'span',
                        null,
                        this.props.user.email
                      )
                    )
                  )
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'form-row' },
                  _react2.default.createElement(
                    'span',
                    { className: 'form-row__header' },
                    _react2.default.createElement(
                      'h3',
                      null,
                      'F\xF6retagsanslutningar ',
                      _react2.default.createElement(
                        'a',
                        { href: '/#/settings', className: 'open-modal', onClick: this.toggleInformationModalWindow },
                        '?'
                      )
                    ),
                    inviteLink
                  ),
                  _react2.default.createElement(_CompanyConnections2.default, (0, _extends3.default)({}, this.props, {
                    linkedCompanies: this.props.user.linkedCompanies,
                    getLinkedCompanies: this.props.getLinkedCompanies,
                    company: company,
                    acceptInvite: this.props.acceptInvite,
                    acceptEmployeeInvite: this.props.acceptEmployeeInvite,
                    rejectInvite: this.props.rejectInvite,
                    cancelInvite: this.props.cancelInvite,
                    user: this.props.user,
                    acceptedInvitesFromMyCompany: this.state.acceptedInvitesFromMyCompany,
                    pendingInvitesFromMyCompany: this.state.pendingInvitesFromMyCompany,
                    acceptedCompanyInvites: this.state.acceptedCompanyInvites,
                    pendingCompanyInvites: this.state.pendingCompanyInvites,
                    acceptedEmployeeInvites: this.state.acceptedEmployeeInvites,
                    pendingEmployeeInvites: this.state.pendingEmployeeInvites,
                    companyNames: this.state.companyNames
                  }))
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'form-row' },
                  _react2.default.createElement(_Button2.default, {
                    type: _Button.TYPES.SECONDARY,
                    text: 'Logga ut',
                    onClick: this.props.signOut
                  })
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'card' },
                company ? _react2.default.createElement(
                  'a',
                  { className: 'link right', onClick: this.editCompanyInfo },
                  '\xC4ndra'
                ) : '',
                _react2.default.createElement(
                  'h2',
                  null,
                  'F\xF6retag'
                ),
                company ? _react2.default.createElement(_CompanyInfo2.default, (0, _extends3.default)({}, this.props, {
                  company: company,
                  user: this.props.user,
                  acceptedInvites: this.state.acceptedInvites,
                  noOfEmployees: this.state.acceptedInvites.length + this.state.closedInvitesCurrentMonth,
                  pendingInvites: this.state.pendingInvites,
                  createInvite: this.props.createInvite,
                  rejectInvite: this.props.rejectInvite,
                  cancelInvite: this.props.cancelInvite,
                  employees: this.state.employees
                })) : _react2.default.createElement(
                  'div',
                  null,
                  _react2.default.createElement(
                    'p',
                    null,
                    'Med ett f\xF6retag kan du tilldela medarbetare transportsedlar som enkelt hanteras i v\xE5ra appar hela v\xE4gen till signering.'
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    '\u2022 Enkel administration av transportsedlar',
                    _react2.default.createElement('br', null),
                    '\u2022 Ger tillg\xE5ng att arbeta i grupp om flera anst\xE4llda',
                    _react2.default.createElement('br', null),
                    '\u2022 Samarbeta med andra f\xF6retag'
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    'F\xF6retagskontot har en grundkostnad p\xE5 150 kr per p\xE5b\xF6rjad m\xE5nad. D\xE4rtill debiteras 99 kr f\xF6r respektive kopplad medarbetare per p\xE5b\xF6rjad m\xE5nad. Du kan n\xE4r som helst koppla bort en medarbetare utan n\xE5gra upps\xE4gningstider.'
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    'Fakturering sker vid varje m\xE5nadsslut med 20 dagars betalningsvillkor.'
                  ),
                  _react2.default.createElement(_Button2.default, {
                    type: _Button.TYPES.SECONDARY,
                    text: 'Skapa f\xF6retag',
                    onClick: this.createCompany,
                    disabled: this.props.user.linkedCompanies && this.props.user.linkedCompanies.length > 0
                  })
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: '\xC4ndra kontouppgifter',
            visibility: this.state.updateSettingsModal,
            toggle: this.toggleModalWindow },
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__form' },
            _react2.default.createElement(
              'div',
              { className: 'form-item form-text' },
              _react2.default.createElement(
                'label',
                null,
                'F\xF6rnamn'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'firstname',
                inputType: 'text',
                value: this.state.data.firstName,
                onChange: function onChange(e) {
                  return _this8.setField('firstName', e);
                }
              }),
              _react2.default.createElement(
                'label',
                null,
                'Efternamn'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'lastname',
                inputType: 'text',
                value: this.state.data.lastName,
                onChange: function onChange(e) {
                  return _this8.setField('lastName', e);
                }
              }),
              _react2.default.createElement(
                'label',
                null,
                'Telefon'
              ),
              _react2.default.createElement(_Input2.default, {
                name: 'phone',
                inputType: 'text',
                value: this.state.data.phone,
                onChange: function onChange(e) {
                  return _this8.setField('phone', e);
                }
              })
            ),
            _react2.default.createElement(
              'div',
              { className: 'modal__btn-right' },
              _react2.default.createElement(_Button2.default, {
                text: 'Avbryt',
                type: _Button.TYPES.SECONDARY,
                onClick: this.toggleModalWindow
              }),
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.PRIMARY,
                text: 'Uppdatera',
                onClick: this.submitUpdateUser
              })
            )
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'F\xF6retagsanslutningar',
            visibility: this.state.informationModal,
            toggle: this.toggleInformationModalWindow },
          modalContent
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Bjud in kontrakt\xF6r',
            visibility: this.state.inviteVisible,
            toggle: this.toggleSendInvitation },
          _react2.default.createElement(
            'form',
            { onSubmit: this.sendInvitation },
            _react2.default.createElement(
              'div',
              { className: 'form-row' },
              _react2.default.createElement(
                'div',
                { className: 'form-item form-text' },
                _react2.default.createElement(
                  'label',
                  { htmlFor: 'email' },
                  'E-post'
                ),
                _react2.default.createElement(_Input2.default, {
                  style: { width: '103%' },
                  name: 'email',
                  id: 'email',
                  inputType: 'text',
                  value: this.state.data.inviteEmail,
                  onChange: function onChange(e) {
                    return _this8.setField('inviteEmail', e);
                  }
                })
              )
            ),
            _react2.default.createElement(
              'div',
              { className: 'modal__btn-right' },
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.SECONDARY,
                text: 'Avbryt',
                onClick: this.toggleSendInvitation
              }),
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.PRIMARY,
                text: 'Skicka inbjudan',
                onClick: this.sendInvitation
              })
            )
          )
        )
      );
    }
  }]);
  return Settings;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(Settings);
module.exports = exports['default'];