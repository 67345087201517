'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// TODO remove inline styling

var ModalBox = function (_Component) {
  (0, _inherits3.default)(ModalBox, _Component);

  function ModalBox() {
    (0, _classCallCheck3.default)(this, ModalBox);
    return (0, _possibleConstructorReturn3.default)(this, (ModalBox.__proto__ || Object.getPrototypeOf(ModalBox)).apply(this, arguments));
  }

  (0, _createClass3.default)(ModalBox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          visibility = _props.visibility,
          children = _props.children,
          toggle = _props.toggle;


      return _react2.default.createElement(
        'div',
        { className: 'dialog \n        ' + (visibility === true ? ' dialog--visible' : '') + ' '
        },
        _react2.default.createElement(
          'div',
          { className: 'modal' },
          _react2.default.createElement('a', { className: 'cancel', href: '#' }),
          _react2.default.createElement(
            'div',
            { className: 'card-container' },
            _react2.default.createElement(
              'section',
              { className: 'card' },
              _react2.default.createElement(
                'div',
                { className: 'link right', onClick: toggle },
                _react2.default.createElement(
                  'svg',
                  { width: '24', height: '24', viewBox: '0 0 24 24' },
                  _react2.default.createElement('use', { xlinkHref: '/images/defs.xml#icon-stroke-close-cross' })
                )
              ),
              _react2.default.createElement(
                'h2',
                { style: { marginBottom: 15 } },
                '  ',
                title
              ),
              _react2.default.createElement(
                'div',
                { style: { marginRight: 34 } },
                children
              )
            )
          )
        )
      );
    }
  }]);
  return ModalBox;
}(_react.Component);

exports.default = ModalBox;
module.exports = exports['default'];