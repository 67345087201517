'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _filehandling = require('../../api/filehandling');

var _Toaster = require('../ui/Toaster');

var _Dropdown = require('../ui/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _dateformatter = require('../utils/dateformatter');

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _webpackVariables = require('webpack-variables');

var _GoodsList = require('./goods/GoodsList');

var _GoodsList2 = _interopRequireDefault(_GoodsList);

var _ModalBox = require('../ui/ModalBox');

var _ModalBox2 = _interopRequireDefault(_ModalBox);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShipmentForm = function (_Component) {
  (0, _inherits3.default)(ShipmentForm, _Component);

  function ShipmentForm() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, ShipmentForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = ShipmentForm.__proto__ || Object.getPrototypeOf(ShipmentForm)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: _this.props.data || {},
      forCompany: false,
      loading: false,
      removeIsLoading: false,
      acceptShipmentIsLoading: false,
      invoiceShipmentIsLoading: false,
      sendShipmentIsLoading: false,
      hasContractor: false,
      acceptedCompanyInvites: [],
      company: _this.props.company || {},
      user: _this.props.user || {},
      removeShipmentDialogVisible: false,
      sendShipmentPdfDialogVisible: false
    }, _this.goToChangeShipment = function () {
      _this.props.history.push('/editshipment/' + _this.props.match.id);
    }, _this.setContractor = function (item) {
      var data = _this.state.data;

      data.assignedTo = {
        uid: item.value.uid,
        email: item.value.email
      };
      data.contractorCompany = item.value.companyRef;
      _this.setState({ data: data });
      _this.props.update(_this.state.data).then(function () {
        return (0, _Toaster.showSuccess)('Kontraktören har kopplats till sedeln!');
      }).catch(function () {
        return (0, _Toaster.showError)('Ett fel uppstod vid kopplingen av kontraktören.');
      });
    }, _this.setAssignedTo = function (item) {
      var data = _this.state.data;

      if (item.value === null) {
        data.assignedTo = {
          uid: _this.props.user.uid,
          email: _this.props.user.email
        };
      } else {
        data.assignedTo = {
          uid: item.value.uid || item.value.uuid,
          email: item.value.email
        };
      }

      if (data.companyId.includes(_this.state.user.uid)) {
        data.contractorCompany = null;
      }
      _this.setState({ data: data });
      _this.props.update(_this.state.data).then(function () {
        return (0, _Toaster.showSuccess)('Chauffören har kopplats till sedeln!');
      }).catch(function () {
        return (0, _Toaster.showError)('Ett fel uppstod vid kopplingen av chauffören.');
      });
    }, _this.addGoods = function (newGoods) {
      var data = _this.state.data;

      if (data.goods) {
        data.goods.push(newGoods);
      } else {
        data.goods = [newGoods];
      }
      _this.setState({ data: data });
    }, _this.removeGoods = function (index) {
      var data = _this.state.data;

      data.goods.splice(index, 1);
      _this.setState({ data: data });
      _this.props.update(_this.state.data).then(function () {
        return (0, _Toaster.showSuccess)('Godset har blivit borttaget!');
      }).catch(function () {
        return (0, _Toaster.showError)('Ett fel uppstod vid borttagandet av gods.');
      });
    }, _this.setGoods = function (goods) {
      var data = _this.state.data;

      data.goods = goods;
      _this.setState({ data: data });
    }, _this.removeShipment = function (e) {
      e.preventDefault();
      _this.setState({ removeIsLoading: true });
      _this.props.remove(_this.props.data.uid).then(function (data) {
        (0, _Toaster.showSuccess)('Sedel har tagits bort');
        _this.setState({ removeIsLoading: false, removeDialogVisible: false });
        _this.props.history.push('/shipments');
      }).catch(function () {
        (0, _Toaster.showError)('Fel uppstod. Gick ej att ta bort sedel');
        _this.setState({ removeIsLoading: false });
      });
    }, _this.toggleRemoveDialog = function (e) {
      e.preventDefault();
      _this.setState({
        removeShipmentDialogVisible: !_this.state.removeShipmentDialogVisible
      });
    }, _this.toggleSendShipmentPdfDialog = function (e) {
      e.preventDefault();
      _this.setState({
        sendShipmentPdfDialogVisible: !_this.state.sendShipmentPdfDialogVisible
      });
      if (_this.state.sendShipmentPdfDialogVisible !== true) {
        _this.state.data['email'] = '';
      }
    }, _this.sendShipmentPdf = function (e) {
      e.preventDefault();
      if (/^.+@.+\..+$/.test(_this.state.data.email) === false) {
        (0, _Toaster.showError)('E-postaddress ogiltig');
        return;
      }
      _this.props.sendShipment(_this.props.data.uid, _this.state.data.email).then(function (data) {
        if (data.type === 'SEND_SHIPMENT_PDF_ERROR') {
          (0, _Toaster.showError)('Fel uppstod. Gick ej att skicka sedeln');
        } else {
          (0, _Toaster.showSuccess)('Sedel har skickats till ' + _this.state.data.email);
          _this.setState({
            sendShipmentPdfDialogVisible: false
          });
        }
      });
    }, _this.acceptShipment = function (e) {
      e.preventDefault();
      _this.setState({ acceptShipmentIsLoading: true });
      _this.props.acceptShipment(_this.props.data.uid).then(function (data) {
        (0, _Toaster.showSuccess)('Sedel har godkänts');
        _this.setState({ acceptShipmentIsLoading: false, acceptShipmentDialogVisible: false });
        _this.props.history.push('/shipments');
      }).catch(function () {
        (0, _Toaster.showError)('Fel uppstod. Gick ej att godkänna sedel');
        _this.setState({ acceptShipmentIsLoading: false });
      });
    }, _this.toggleAcceptShipmentDialog = function (e) {
      e.preventDefault();
      _this.setState({
        acceptShipmentDialogVisible: !_this.state.acceptShipmentDialogVisible
      });
    }, _this.invoiceShipment = function (e) {
      e.preventDefault();
      _this.setState({ invoiceShipmentIsLoading: true });
      _this.props.invoiceShipment(_this.props.data.uid).then(function (data) {
        (0, _Toaster.showSuccess)('Sedel har markerats som fakturerad');
        _this.setState({ invoiceShipmentIsLoading: false, invoiceShipmentDialogVisible: false });
        _this.props.history.push('/shipments');
      }).catch(function () {
        (0, _Toaster.showError)('Fel uppstod. Gick ej att markera sedel som fakrurerad.');
        _this.setState({ invoiceShipmentIsLoading: false });
      });
    }, _this.toggleInvoiceShipmentDialog = function (e) {
      e.preventDefault();
      _this.setState({
        invoiceShipmentDialogVisible: !_this.state.invoiceShipmentDialogVisible
      });
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      _this.state.data[prop] = target.value;
      _this.setState(_this.state);
    }, _this.setImages = function (index, images) {
      var data = _this.state.data;

      data.goods[index].images = images;
      _this.setState({ data: data });
    }, _this.handleSubmit = function (e) {
      e.preventDefault();
      var data = _this.state.data;

      var isNew = !data.createdAt;

      if (!data.assignedTo || data.assignedTo === null) {
        data.assignedTo = {
          uid: _this.props.user.uid,
          email: _this.props.user.email
        };
      }

      data.companyId = _this.state.forCompany ? 'users/' + _this.props.user.uid + '/companies/' + _this.props.company.companies[0].uid : _this.props.user.uid;

      _this.setState({ data: data, loading: true });

      var tasks = [];

      data.goods = data.goods || [];
      data.goods.forEach(function (g) {
        g.images = g.images || [];
        g.images.forEach(function (file) {
          if (typeof file !== 'string' && Object.keys(file).length > 0) {
            tasks.push((0, _filehandling.upload)(file).then(function (url) {
              g.images.push(url);
            }));
          }
        });
      });
      if (tasks.length === 0) {
        _this.props.update(data).then(function (data) {
          (0, _Toaster.showSuccess)('Sedel ' + (isNew ? 'skapad.' : 'uppdaterad.'));
          _this.setState({ loading: false });
        }).catch(function () {
          _this.setState({ loading: false });
        });
      } else {
        Promise.all(tasks).then(function () {
          var goods = data.goods.filter(function (g) {
            return (typeof g === 'undefined' ? 'undefined' : (0, _typeof3.default)(g)) === 'object';
          }).map(function (goods) {
            goods.images = goods.images.filter(function (image) {
              return typeof image === 'string';
            });
            return goods;
          });
          data.goods = goods;
          _this.props.update(data).then(function (action) {
            (0, _Toaster.showSuccess)('Sedel ' + (isNew ? 'skapad.' : 'uppdaterad.'));
            _this.setState({ loading: false, data: action.data });
          }).catch(function () {
            _this.setState({ loading: false });
          });
        });
      }
    }, _this.getEmployeeName = function (list, email) {
      var employee = list.find(function (e) {
        return e.email === email;
      });

      var displayName = email;
      if (email === _this.props.user.email) {
        displayName = 'Mig själv';
      } else if (employee && (employee.firstName || employee.lastName)) {
        displayName = employee.firstName + ' ' + employee.lastName;
      }

      return displayName;
    }, _this.getEmployeeCompanyName = function (list, email) {
      var employee = list.find(function (e) {
        return e.email === email;
      });

      return employee ? employee.companyName + ' (' + employee.firstName + ' ' + employee.lastName + ')' : email;
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(ShipmentForm, [{
    key: 'filterInvites',
    value: function filterInvites(invites, isContractor) {
      var filtered = invites.filter(function (item) {
        return item.contractor === isContractor;
      });

      return filtered;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state$data = this.state.data,
          workplace = _state$data.workplace,
          createdAt = _state$data.createdAt,
          vehicleType = _state$data.vehicleType,
          licenseNumber = _state$data.licenseNumber,
          customer = _state$data.customer,
          customerPhone = _state$data.customerPhone,
          overtimePrice = _state$data.overtimePrice,
          littra = _state$data.littra,
          reference = _state$data.reference,
          fees = _state$data.fees,
          fromAddress = _state$data.fromAddress,
          toAddress = _state$data.toAddress,
          goods = _state$data.goods,
          creator = _state$data.creator,
          signature = _state$data.signature,
          description = _state$data.description,
          state = _state$data.state;
      var _props$data = this.props.data,
          assignedTo = _props$data.assignedTo,
          companyId = _props$data.companyId,
          contractorCompany = _props$data.contractorCompany,
          mailedAt = _props$data.mailedAt,
          mailedTo = _props$data.mailedTo;

      var isForCompany = (assignedTo !== undefined ? assignedTo.uid : undefined) !== companyId;

      if (this.state.forCompany === false && isForCompany === true) {
        this.setState({ forCompany: true });
      }

      var userHasCompany = contractorCompany && contractorCompany.includes(this.props.user.uid) || companyId && companyId.includes(this.props.user.uid);
      var iAmTheContractor = contractorCompany && contractorCompany.includes(this.props.user.uid) ? true : false;
      var showRemoveButton = companyId && companyId.includes(this.props.user.uid);
      var selectedContractor = contractorCompany && contractorCompany.length > 0 && iAmTheContractor === false;
      var mailInformation = mailedAt ? 'Senast skickad ' + (0, _dateformatter.formatDateWithSeconds)(mailedAt) + ' till ' + mailedTo : 'Sedeln har ej mailats ut.';
      var download = _react2.default.createElement(
        'p',
        null,
        _react2.default.createElement(
          'a',
          { target: 'top', href: 'https://europe-west1-' + _webpackVariables.FS_PROJECT_ID + '.cloudfunctions.net/generateShipmentPdf?id=' + this.props.data.uid },
          'Ladda ner sedel'
        )
      );
      var assignedToTitle = '';
      if (!selectedContractor && assignedTo && assignedTo.email) {
        assignedToTitle = assignedTo.uid !== this.props.user.uid ? this.getEmployeeName(this.props.extendedEmployeeList, assignedTo.email) : 'Mig själv';
      }
      var contractorTitle = selectedContractor && assignedTo && assignedTo.email && assignedTo.uid !== this.props.user.uid ? this.getEmployeeCompanyName(this.props.extendedEmployeeList, assignedTo.email) : '';
      var hasContractors = this.filterInvites(this.props.extendedEmployeeList, true).length > 0;

      var signedContent = void 0;
      if (this.props.data.state === 'invoiced') {
        signedContent = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(
            'p',
            null,
            mailInformation
          ),
          download,
          _react2.default.createElement(_Button2.default, {
            text: 'Skicka via e-post',
            type: _Button.TYPES.SECONDARY,
            onClick: this.toggleSendPdfModal })
        );
      } else if (signature && signature.signedAt) {
        signedContent = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(
            'p',
            null,
            mailInformation
          ),
          download,
          _react2.default.createElement(_Button2.default, {
            text: 'Markera som fakturerad',
            type: _Button.TYPES.SECONDARY,
            onClick: this.toggleInvoiceShipmentDialog }),
          _react2.default.createElement(_Button2.default, {
            text: 'Skicka via e-post',
            type: _Button.TYPES.SECONDARY,
            onClick: this.toggleSendShipmentPdfDialog })
        );
      } else {
        signedContent = _react2.default.createElement(
          'span',
          null,
          download,
          _react2.default.createElement(
            'p',
            null,
            'Sedeln beh\xF6ver godk\xE4nnas med eller utan signering innan den g\xE5r att skickas till en e-postadress.'
          ),
          _react2.default.createElement(_Button2.default, {
            text: 'Godk\xE4nn utan signering',
            type: _Button.TYPES.SECONDARY,
            onClick: this.toggleAcceptShipmentDialog })
        );
      }

      var removeShipment = void 0;
      if (showRemoveButton) {
        removeShipment = _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(_Button2.default, {
            text: this.state.removeIsLoading ? 'Raderar sedel...' : 'Radera sedel',
            type: _Button.TYPES.TERTIARY,
            onClick: this.toggleRemoveDialog })
        );
      }
      // TODO inlinestyles
      var assignedToStyles = assignedTo && !selectedContractor ? { backgroundColor: '#F3F7F5' } : {};
      var showLinks = state === 'created' && userHasCompany;
      var showEditLinks = state === 'created' || state === 'approved';
      var connectDriver = void 0;
      if (showLinks) {
        connectDriver = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(
            'h3',
            null,
            'Kopplingar'
          ),
          _react2.default.createElement('br', null),
          'Koppla chauff\xF6r',
          _react2.default.createElement(
            'div',
            { className: 'form-row', style: assignedToStyles },
            _react2.default.createElement(_Dropdown2.default, {
              title: assignedToTitle,
              onSelect: this.setAssignedTo,
              onChange: function onChange(e) {
                return _this2.title('assignedTo', e);
              },
              items: [].concat((0, _toConsumableArray3.default)(this.filterInvites(this.props.extendedEmployeeList, false).map(function (e) {
                return { label: _this2.getEmployeeName(_this2.props.extendedEmployeeList, e.email), value: e };
              }))) })
          )
        );
      }
      var contractorStyles = assignedTo && selectedContractor ? { backgroundColor: '#F3F7F5' } : {};
      var connectContractor = void 0;
      if (userHasCompany && hasContractors && iAmTheContractor === false && state === 'created') {
        connectContractor = _react2.default.createElement(
          'span',
          null,
          'Koppla kontrakt\xF6r',
          _react2.default.createElement(
            'div',
            { className: 'form-row', style: contractorStyles },
            _react2.default.createElement(_Dropdown2.default, {
              title: contractorTitle,
              onSelect: this.setContractor,
              onChange: function onChange(e) {
                return _this2.title('contractorCompany', e);
              },
              items: [].concat((0, _toConsumableArray3.default)(this.filterInvites(this.props.extendedEmployeeList, true).map(function (e) {
                return { label: _this2.getEmployeeCompanyName(_this2.props.extendedEmployeeList, e.email), value: e };
              }))) })
          )
        );
      }

      var editLink = void 0;
      if (showEditLinks) {
        editLink = _react2.default.createElement(
          'a',
          { className: 'link right', onClick: this.props.goToChangeShipment },
          '\xC4ndra'
        );
      }
      return _react2.default.createElement(
        'main',
        null,
        _react2.default.createElement(
          'div',
          { className: 'card-container' },
          _react2.default.createElement(
            'div',
            { className: 'card' },
            editLink,
            _react2.default.createElement(
              'h2',
              null,
              'Sedel ',
              this.props.data.tag !== undefined ? '#' + this.props.data.tag : ''
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-row' },
              _react2.default.createElement(
                'h3',
                null,
                'Sedeluppgifter'
              ),
              _react2.default.createElement(
                'ul',
                { className: 'list list--color' },
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Skapad'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    (0, _dateformatter.formatDateWithMinutes)(createdAt)
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Utf\xE4rdad av'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    creator
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Arbetsplats'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    workplace
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Fr\xE5n adress'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    fromAddress
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Till adress'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    toAddress
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Fordonstyp'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    vehicleType
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Reg-nummer'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    licenseNumber
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Kund'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    customer
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Kund telefonnummer'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    customerPhone
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Referens'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    reference
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    '\xD6vertidspris'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    overtimePrice
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Littera'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    littra
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Avgift'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    fees
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'span',
                    null,
                    'Beskrivning'
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    description
                  )
                )
              )
            ),
            _react2.default.createElement(
              'form',
              null,
              connectDriver,
              connectContractor
            ),
            _react2.default.createElement(
              'div',
              { className: 'form-row' },
              _react2.default.createElement(
                'h3',
                null,
                'Signering och fakturering'
              ),
              signedContent
            ),
            removeShipment
          ),
          _react2.default.createElement(
            'div',
            { className: 'card' },
            _react2.default.createElement(_GoodsList2.default, { goods: goods,
              history: this.props.history,
              onAdd: this.addGoods,
              onRemove: this.removeGoods,
              onChange: this.setGoods,
              setImages: this.setImages,
              removeGoodsImage: this.props.removeGoodsImage,
              shipment: this.props.data,
              overtimePrice: overtimePrice,
              showEditLinks: showEditLinks,
              fees: fees
            })
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Skicka sedel via e-post.',
            visibility: this.state.sendShipmentPdfDialogVisible,
            toggle: this.toggleSendShipmentPdfDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Ange den e-post du vill skicka sedel #',
            this.props.data.tag,
            ' till.'
          ),
          _react2.default.createElement(
            'div',
            { className: 'form-item form-text' },
            _react2.default.createElement(
              'label',
              null,
              'E-post'
            ),
            _react2.default.createElement(_Input2.default, {
              name: 'email',
              inputType: 'text',
              value: this.state.data.email,
              onChange: function onChange(e) {
                return _this2.setField('email', e);
              }
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Avbryt',
              onClick: this.toggleSendShipmentPdfDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Skicka',
              onClick: this.sendShipmentPdf
            })
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Godk\xE4nn sedel',
            visibility: this.state.acceptShipmentDialogVisible,
            toggle: this.toggleAcceptShipmentDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Vill du godk\xE4nna sedel #',
            this.props.data.tag,
            ' utan signering?'
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Nej',
              onClick: this.toggleAcceptShipmentDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Ja, godk\xE4nn utan signering',
              onClick: this.acceptShipment
            })
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Markera som fakturerad',
            visibility: this.state.invoiceShipmentDialogVisible,
            toggle: this.toggleInvoiceShipmentDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Vill du markera sedel #',
            this.props.data.tag,
            ' som fakturerad?'
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Nej',
              onClick: this.toggleInvoiceShipmentDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Ja, markera som fakturerad',
              onClick: this.invoiceShipment
            })
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Radera sedel',
            visibility: this.state.removeShipmentDialogVisible,
            toggle: this.toggleRemoveDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Vill du radera sedel #',
            this.props.data.tag,
            '?'
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Nej',
              onClick: this.toggleRemoveDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Ja, radera sedeln',
              onClick: this.removeShipment
            })
          )
        )
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(props, state) {
      // NOTE: This is an antipattern. state.data should not exist here.
      // It should be handled in a redux store. Then we will get rid of
      // a lot of problems.
      return {
        data: props.data,
        hasContractor: !!props.data.contractorCompany
      };
    }
  }]);
  return ShipmentForm;
}(_react.Component);

exports.default = ShipmentForm;
module.exports = exports['default'];