'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _EmployeeList = require('./EmployeeList');

var _EmployeeList2 = _interopRequireDefault(_EmployeeList);

var _TSForm = require('../ui/TSForm');

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _Modal = require('../ui/Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Dialog = require('../ui/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Toaster = require('../ui/Toaster');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Employee = function (_Component) {
  (0, _inherits3.default)(Employee, _Component);

  function Employee() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Employee);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Employee.__proto__ || Object.getPrototypeOf(Employee)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      modal: false,
      action: '',
      callback: function callback() {},
      loading: false,
      notificationDialogVisible: false
    }, _this.setEmail = function (_ref2) {
      var target = _ref2.target;

      _this.setState({
        email: target.value
      });
    }, _this.setFirstName = function (_ref3) {
      var target = _ref3.target;

      _this.setState({
        firstName: target.value
      });
    }, _this.setLastName = function (_ref4) {
      var target = _ref4.target;

      _this.setState({
        lastName: target.value
      });
    }, _this.setPhone = function (_ref5) {
      var target = _ref5.target;

      _this.setState({
        phone: target.value
      });
    }, _this.toggleNotificationDialog = function (e) {
      e.preventDefault();
      _this.setState({
        notificationDialogVisible: !_this.state.notificationDialogVisible,
        email: ''
      });
    }, _this.sendInvite = function (e) {
      e.preventDefault();
      var email = _this.state.email;
      var company = _this.props.company;

      _this.setState({ loading: true });
      return _this.props.createInvite(company, email).then(function () {
        (0, _Toaster.showSuccess)('Inbjudan skickad!');
        _this.setState({
          loading: false,
          notificationDialogVisible: true });
      }).catch(function () {
        _this.setState({ loading: false });
      });
    }, _this.triggerModal = function (open, action, callback) {
      _this.setState({
        modal: open,
        action: action,
        callback: callback
      });
    }, _this.doAction = function (message, cb) {
      _this.setState({ modal: false });
      return cb().then(function () {
        (0, _Toaster.showSuccess)(message);
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Employee, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          invites = _props.invites,
          company = _props.company,
          employees = _props.employees,
          removeInvite = _props.removeInvite,
          removeEmployee = _props.removeEmployee;
      var modal = this.state.modal;


      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _Modal2.default,
          { show: modal },
          this.modalContent
        ),
        _react2.default.createElement(
          'h2',
          null,
          'Medarbetare'
        ),
        _react2.default.createElement(
          'form',
          { onSubmit: this.sendInvite,
            className: 'input-group--with-button' },
          _react2.default.createElement(_Input2.default, {
            onChange: this.setEmail,
            value: this.state.email,
            placeholder: 'Bjud in medarbetare'
          }),
          _react2.default.createElement(_Input2.default, {
            onChange: this.setFirstName,
            value: this.state.firstName,
            placeholder: 'F\xF6rnamn'
          }),
          _react2.default.createElement(_Input2.default, {
            onChange: this.setLastName,
            value: this.state.lastName,
            placeholder: 'Efternamn'
          }),
          _react2.default.createElement(_Input2.default, {
            onChange: this.phone,
            value: this.state.phone,
            placeholder: 'Telefon'
          }),
          _react2.default.createElement(_TSForm.ButtonPrimary, { type: 'submit',
            disabled: false,
            text: 'Bjud in'
          })
        ),
        _react2.default.createElement('div', { className: 'divider' }),
        _react2.default.createElement(_EmployeeList2.default, { type: 'invite',
          data: invites.filter(function (i) {
            return i.status === 'active' || i.status === 'pending';
          }),
          company: company,
          triggerModal: this.triggerModal,
          remove: removeInvite
        }),
        _react2.default.createElement(_EmployeeList2.default, { type: 'employee',
          data: employees,
          company: company,
          triggerModal: this.triggerModal,
          remove: removeEmployee
        }),
        _react2.default.createElement(
          _Dialog2.default,
          { title: 'Notifiera anv\xE4ndare om inbjudan',
            visible: this.state.notificationDialogVisible,
            close: this.toggleNotificationDialog },
          _react2.default.createElement(
            'p',
            null,
            'En inbjudan har nu skapats. Anv\xE4nd l\xE4nken nedan f\xF6r att notifiera anv\xE4ndaren om att en inbjudan till f\xF6retaget nu finns tillg\xE4nglig.'
          ),
          _react2.default.createElement(
            'p',
            null,
            _react2.default.createElement(
              'a',
              { href: 'mailto:' + this.state.email + '\n            ?subject=Inbjudan till f\xF6retag p\xE5 Transportsedeln&body=Hej,%0A%0ADu har blivit inbjuden att g\xE5 med i ett f\xF6retag p\xE5 Transportsedeln. F\xF6r att se din inbjudan v\xE4nligen logga in eller skapa nytt konto p\xE5 www.transportsedeln.se/register&firstName=' + this.state.firstName + '&lastName=' + this.state.lastName + '&phone=' + this.state.phone + '&email=' + this.state.email + '.%0A%0AMvh' },
              'Skicka en inbjudan'
            )
          )
        )
      );
    }
  }, {
    key: 'modalContent',
    get: function get() {
      var _this2 = this;

      var _state = this.state,
          action = _state.action,
          callback = _state.callback;

      switch (action) {
        case 'delete-invite':
          {
            var receiver = callback.data.receiver;

            return _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(
                'h3',
                null,
                '\xC4r du s\xE4ker p\xE5 att du vill ta bort inbjudan till ',
                receiver,
                '?'
              ),
              _react2.default.createElement(
                'div',
                { className: 'modal__footer' },
                _react2.default.createElement(_TSForm.ButtonPrimary, {
                  onClick: function onClick() {
                    return _this2.setState({ modal: false });
                  },
                  type: 'button',
                  text: 'Nej'
                }),
                _react2.default.createElement(_TSForm.ButtonError, {
                  type: 'button',
                  text: 'Ja',
                  onClick: this.doAction.bind(this, 'Inbjudan borttagen.', callback)
                })
              )
            );
          }
        case 'remove-employee':
          {
            return _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(
                'h3',
                null,
                '\xC4r du s\xE4ker p\xE5 att du vill ta bort medarbetaren med epostadress ',
                callback.data.email,
                '?'
              ),
              _react2.default.createElement(
                'div',
                { className: 'modal__footer' },
                _react2.default.createElement(_TSForm.ButtonError, {
                  onClick: function onClick() {
                    return _this2.setState({ modal: false });
                  },
                  type: 'button',
                  text: 'Nej'
                }),
                _react2.default.createElement(_TSForm.ButtonPrimary, {
                  type: 'button',
                  text: 'Ja',
                  onClick: this.doAction.bind(this, 'Medarbetare borttagen.', callback)
                })
              )
            );
          }
        default:
          return null;
      }
    }
  }]);
  return Employee;
}(_react.Component);

exports.default = Employee;
module.exports = exports['default'];