'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _AddShipmentForm = require('../shipments/AddShipmentForm');

var _AddShipmentForm2 = _interopRequireDefault(_AddShipmentForm);

var _shipment = require('../../actions/shipment');

var _company = require('../../actions/company');

var _reactRouterDom = require('react-router-dom');

var _array = require('../utils/array');

var _firestore = require('../../store/firestore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var stateToProps = function stateToProps(_ref) {
  var shipment = _ref.shipment,
      user = _ref.user,
      company = _ref.company;
  return {
    shipment: shipment, user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    updateShipment: function updateShipment(data) {
      return (0, _shipment.updateShipment)(data).then(dispatch);
    },
    setAsRemoved: function setAsRemoved(id) {
      return (0, _shipment.setAsRemoved)(id).then(dispatch).catch(dispatch);
    },
    getShipmentById: function getShipmentById(uid) {
      return (0, _shipment.getShipmentById)(uid).then(dispatch);
    },
    getEmployeeUsers: function getEmployeeUsers(company) {
      return (0, _company.getEmployeeUsers)(company).then(dispatch);
    },
    setSelectedShipment: function setSelectedShipment(shipment) {
      return (0, _shipment.setSelectedShipment)(shipment, dispatch);
    },
    removeGoodsImage: function removeGoodsImage(ref, goods) {
      return (0, _shipment.removeGoodsImage)(ref, goods).then(dispatch);
    },
    acceptShipment: function acceptShipment(ref) {
      return (0, _shipment.acceptShipment)(ref).then(dispatch);
    },
    sendShipment: function sendShipment(id, email) {
      return (0, _shipment.sendShipment)(id, email).then(dispatch);
    }
  };
};

var NewShipment = function (_Component) {
  (0, _inherits3.default)(NewShipment, _Component);

  function NewShipment() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, NewShipment);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = NewShipment.__proto__ || Object.getPrototypeOf(NewShipment)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      edit: false,
      employees: [],
      acceptedInvites: [],
      employeeCompanies: []
    }, _this.setEmployees = function () {
      if (_this.props.company.companies && _this.props.company.companies.length === 0) {
        return;
      }

      var emps = (0, _array.flatten)(_this.props.company.companies.map(function (c) {
        return c.employees;
      }));
      _this.setState({ employees: emps });
    }, _this.setInvites = function () {
      if (_this.props.company.companies && _this.props.company.companies.length === 0) {
        return;
      }

      _firestore.db.collection('invites').where('company.uid', '==', _this.props.company.companies[0].uid).where('status', '==', 'accepted').get().then(function (result) {
        if (!result.empty) {
          _this.setState({
            acceptedInvites: result.docs.map(function (s) {
              return (0, _extends3.default)({}, s.data());
            })
          });
        }
      });
    }, _this.setEmployeeCompany = function () {
      if (_this.props.company.companies && _this.props.company.companies.length === 0) {
        return;
      }

      _this.props.company.companies[0].employees.forEach(function (r) {
        var _this2 = this;

        _firestore.db.collection('users/' + r.uid + '/companies').get().then(function (result) {
          if (!result.empty) {
            _this2.setState({
              employeeCompanies: result.docs.map(function (s) {
                return (0, _extends3.default)({ uid: s.id }, s.data());
              })
            });
          }
        });
      }.bind(_this));
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(NewShipment, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var params = this.props.match.params;

      if (params.id) {
        this.setState({ edit: true });
        this.props.getShipmentById(params.id);
      }

      this.setInvites();
      this.setEmployees();
      this.setEmployeeCompany();
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      var edit = this.state.edit;
      var _props = this.props,
          selected = _props.shipment.selected,
          updateShipment = _props.updateShipment,
          getEmployeeUsers = _props.getEmployeeUsers,
          history = _props.history,
          user = _props.user,
          company = _props.company,
          setAsRemoved = _props.setAsRemoved,
          getAcceptedInvites = _props.getAcceptedInvites,
          acceptShipment = _props.acceptShipment,
          sendShipment = _props.sendShipment;

      // create list of employees with contractor flag and contractorcompany
      // input
      // - list of employees
      // - invites to get contractor flag
      // - employees companyId

      var extendedEmployeeList = [].concat((0, _toConsumableArray3.default)(this.state.employees));
      if (this.state.acceptedInvites !== undefined) {
        extendedEmployeeList.forEach(function (r) {
          r.contractor = this.state.acceptedInvites.find(function (e) {
            return e.receiver === r.email && e.contractor === true;
          }) ? true : false;
        }.bind(this));
      }

      extendedEmployeeList.forEach(function (r) {
        var company = this.state.employeeCompanies.find(function (e) {
          return e.owner === r.uid;
        });
        if (company !== undefined) {
          r.companyRef = 'users/' + company.owner + '/companies/' + company.uid;
          r.companyName = company.companyName;
        }
      }.bind(this));

      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { href: '/#/' },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'container l-two-col' },
          _react2.default.createElement(_AddShipmentForm2.default, {
            extendedEmployeeList: extendedEmployeeList,
            getAcceptedInvites: getAcceptedInvites,
            getEmployees: getEmployeeUsers,
            update: updateShipment,
            remove: setAsRemoved,
            acceptShipment: acceptShipment,
            sendShipment: sendShipment,
            history: history,
            user: user,
            company: company,
            data: edit ? selected : {},
            removeGoodsImage: this.removeGoodsImage
          }),
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__img' },
            _react2.default.createElement('img', { src: 'images/illustration-shipments2.svg', alt: 'Shipmentbills' })
          )
        )
      );
    }
  }]);
  return NewShipment;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(NewShipment);
module.exports = exports['default'];