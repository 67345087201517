'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _TabList = require('../ui/tabs/TabList');

var _TabList2 = _interopRequireDefault(_TabList);

var _reactRouterDom = require('react-router-dom');

var _firestore = require('../../store/firestore');

var _company = require('../../actions/company');

var _invite = require('../../actions/invite');

var _Company = require('../company/Company');

var _Company2 = _interopRequireDefault(_Company);

var _CompanyForm = require('../company/CompanyForm');

var _CompanyForm2 = _interopRequireDefault(_CompanyForm);

var _Employee = require('../employee/Employee');

var _Employee2 = _interopRequireDefault(_Employee);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.locale('sv');

var stateToProps = function stateToProps(_ref) {
  var user = _ref.user,
      company = _ref.company;
  return {
    user: user, company: company
  };
};

var dispatchToProps = function dispatchToProps(dispatch) {
  return {
    createCompany: function createCompany(uid, data) {
      return (0, _company.createCompany)(uid, data).then(dispatch);
    },
    updateCompany: function updateCompany(uid, data) {
      return (0, _company.updateCompany)(uid, data).then(dispatch);
    },
    createInviteWithStateAccepted: function createInviteWithStateAccepted(company, email) {
      return (0, _invite.createInviteWithStateAccepted)(company, email).then(dispatch).catch(dispatch);
    },
    removeInvite: function removeInvite(invite) {
      return (0, _invite.removeInvite)(invite).then(dispatch);
    },
    removeEmployee: function removeEmployee(user, company) {
      return (0, _company.removeEmployee)(user, company).then(dispatch);
    }
  };
};

var CompanyView = function (_Component) {
  (0, _inherits3.default)(CompanyView, _Component);

  function CompanyView() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompanyView);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = CompanyView.__proto__ || Object.getPrototypeOf(CompanyView)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      view: undefined,
      numberOfEmployees: 0,
      renderLinkedCompanyComponent: false
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CompanyView, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var params = this.props.match.params;

      this.countEmployees();
    }
  }, {
    key: 'countEmployees',
    value: function countEmployees() {
      var _this2 = this;

      if (this.props.company.companies[0] === undefined) {
        return;
      }

      var timeStamp = (0, _moment2.default)((0, _moment2.default)().format('YYYY-MM') + '-01').format('x');

      var empsAccept = 0;
      var empsClosed = 0;
      _firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('createdAt', '>', parseInt(timeStamp, 10)).where('status', '==', 'accepted').get().then(function (result) {
        if (!result.empty) {
          empsAccept = result.docs.map(function (s) {
            return (0, _extends3.default)({}, s.data());
          }).length;

          _this2.setState({
            numberOfEmployees: _this2.state.numberOfEmployees + empsAccept
          });
        }
      }).then(_firestore.db.collection('invites').where('company.uid', '==', this.props.company.companies[0].uid).where('createdAt', '>', parseInt(timeStamp, 10)).where('status', '==', 'closed').get().then(function (result) {
        if (!result.empty) {
          empsClosed = result.docs.map(function (s) {
            return (0, _extends3.default)({}, s.data());
          }).length;

          _this2.setState({
            numberOfEmployees: _this2.state.numberOfEmployees + empsClosed
          });
        }
      }));
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.props.user.emailVerified !== true) {
        return _react2.default.createElement(_reactRouterDom.Redirect, { to: '/shipments' });
      }

      var _props = this.props,
          user = _props.user,
          companies = _props.company.companies,
          createCompany = _props.createCompany;
      var view = this.state.view;


      return _react2.default.createElement(
        'div',
        { className: 'background-img' },
        _react2.default.createElement(
          'nav',
          { className: 'nav-back' },
          _react2.default.createElement(
            'a',
            { href: '/#/settings' },
            '\xC5terg\xE5'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'container l-two-col' },
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__form' },
            _react2.default.createElement(
              'h1',
              null,
              'Skapa f\xF6retag'
            ),
            _react2.default.createElement(_CompanyForm2.default, { user: user,
              history: this.props.history,
              createCompany: createCompany,
              createInviteWithStateAccepted: _invite.createInviteWithStateAccepted
            })
          ),
          _react2.default.createElement(
            'div',
            { className: 'l-two-col__img' },
            _react2.default.createElement('img', { src: 'images/illustration-newcompany.svg', alt: 'Shipmentbills' })
          )
        )
      );
    }
  }, {
    key: 'tabs',
    get: function get() {
      var _props2 = this.props,
          user = _props2.user,
          updateCompany = _props2.updateCompany,
          getInvites = _props2.getInvites,
          createInvite = _props2.createInvite,
          removeInvite = _props2.removeInvite,
          getEmployeeUsers = _props2.getEmployeeUsers,
          removeEmployee = _props2.removeEmployee,
          company = _props2.company;


      var c = company.companies.length > 0 ? company.companies[0] : {};
      var tabs = [{
        title: 'Företagsinfo',
        component: function component() {
          return _react2.default.createElement(_Company2.default, { formType: 'profile',
            handleSubmit: updateCompany,
            user: user,
            data: c
          });
        }
      }, {
        title: 'Inställningar',
        component: function component() {
          return _react2.default.createElement(_Company2.default, { formType: 'settings',
            handleSubmit: updateCompany,
            user: user,
            data: c
          });
        }
      }, {
        title: 'Medarbetare',
        component: function component() {
          return _react2.default.createElement(_Employee2.default, {
            user: user,
            company: c,
            getInvites: getInvites,
            createInvite: createInvite,
            removeInvite: removeInvite,
            removeEmployee: removeEmployee,
            employees: c.employees || [],
            invites: company.userInvites.filter(function (i) {
              return i.company.id === c.id;
            })
          });
        }
      }];
      return tabs;
    }
  }]);
  return CompanyView;
}(_react.Component);

exports.default = (0, _reactRedux.connect)(stateToProps, dispatchToProps)(CompanyView);
module.exports = exports['default'];