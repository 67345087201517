'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _Start = require('./components/views/Start');

var _Start2 = _interopRequireDefault(_Start);

var _Main = require('./components/views/Main');

var _Main2 = _interopRequireDefault(_Main);

var _Shipments = require('./components/views/Shipments');

var _Shipments2 = _interopRequireDefault(_Shipments);

var _Shipment = require('./components/views/Shipment');

var _Shipment2 = _interopRequireDefault(_Shipment);

var _NewShipment = require('./components/views/NewShipment');

var _NewShipment2 = _interopRequireDefault(_NewShipment);

var _EditGoods = require('./components/views/EditGoods');

var _EditGoods2 = _interopRequireDefault(_EditGoods);

var _NewGoods = require('./components/views/NewGoods');

var _NewGoods2 = _interopRequireDefault(_NewGoods);

var _Settings = require('./components/views/Settings');

var _Settings2 = _interopRequireDefault(_Settings);

var _Company = require('./components/views/Company');

var _Company2 = _interopRequireDefault(_Company);

var _EditCompany = require('./components/views/EditCompany');

var _EditCompany2 = _interopRequireDefault(_EditCompany);

var _Privacy = require('./components/views/Privacy');

var _Privacy2 = _interopRequireDefault(_Privacy);

var _Cookies = require('./components/views/Cookies');

var _Cookies2 = _interopRequireDefault(_Cookies);

var _Contact = require('./components/views/Contact');

var _Contact2 = _interopRequireDefault(_Contact);

var _StyleGuide = require('./components/views/StyleGuide');

var _StyleGuide2 = _interopRequireDefault(_StyleGuide);

var _VerifyAccount = require('./components/views/VerifyAccount');

var _VerifyAccount2 = _interopRequireDefault(_VerifyAccount);

var _RegisterInvite = require('./components/views/RegisterInvite');

var _RegisterInvite2 = _interopRequireDefault(_RegisterInvite);

var _EditShipment = require('./components/views/EditShipment');

var _EditShipment2 = _interopRequireDefault(_EditShipment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (props) {
  return _react2.default.createElement(
    _reactRouterDom.Switch,
    null,
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/',
      component: function component(rest) {
        return _react2.default.createElement(_Start2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/privacy',
      component: function component(rest) {
        return _react2.default.createElement(_Privacy2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/cookies',
      component: function component(rest) {
        return _react2.default.createElement(_Cookies2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/newgoods/:id?',
      component: function component(rest) {
        return _react2.default.createElement(_NewGoods2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/editgoods/:id?/:index?',
      component: function component(rest) {
        return _react2.default.createElement(_EditGoods2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/contact',
      component: function component(rest) {
        return _react2.default.createElement(_Contact2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/main',
      component: function component(rest) {
        return _react2.default.createElement(_Main2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/register/:companyId?',
      component: function component(rest) {
        return _react2.default.createElement(_RegisterInvite2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/verify-account',
      component: function component() {
        return _react2.default.createElement(_VerifyAccount2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/shipments/:filter?',
      component: function component(rest) {
        return _react2.default.createElement(_Shipments2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/shipments',
      component: function component() {
        return _react2.default.createElement(_Shipments2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/shipment/:id?',
      component: function component(rest) {
        return _react2.default.createElement(_Shipment2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/shipment/:id?/:filter?',
      component: function component(rest) {
        return _react2.default.createElement(_Shipment2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/editshipment/:id?',
      component: function component(rest) {
        return _react2.default.createElement(_EditShipment2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/new-shipment',
      component: function component(rest) {
        return _react2.default.createElement(_NewShipment2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/settings',
      component: function component(rest) {
        return _react2.default.createElement(_Settings2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/styleguide',
      component: function component() {
        return _react2.default.createElement(_StyleGuide2.default, props);
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/company/:companyId?',
      component: function component(rest) {
        return _react2.default.createElement(_Company2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/editcompany',
      component: function component(rest) {
        return _react2.default.createElement(_EditCompany2.default, (0, _extends3.default)({}, props, rest));
      }
    }),
    _react2.default.createElement(_reactRouterDom.Route, { path: '*',
      component: _Start2.default
    })
  );
};

module.exports = exports['default'];