'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ModalBox = require('../ui/ModalBox');

var _ModalBox2 = _interopRequireDefault(_ModalBox);

var _Button = require('../ui/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Input = require('../ui/Input');

var _Input2 = _interopRequireDefault(_Input);

var _firestore = require('../../store/firestore');

var _Toaster = require('../ui/Toaster');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// TODO remove inline styles
var CompanyInfo = function (_Component) {
  (0, _inherits3.default)(CompanyInfo, _Component);

  function CompanyInfo() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, CompanyInfo);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = CompanyInfo.__proto__ || Object.getPrototypeOf(CompanyInfo)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      current: 0,
      inviteCoworkerVisible: false,
      removeInviteDialogVisible: false,
      inviteEmail: '',
      inviteFirstName: '',
      inviteLastName: '',
      invitePhone: '',
      inviteToBeRemoved: [],
      inviteIsAccepted: false
    }, _this.sendMail = function () {
      var link = window.location.protocol + '//' + window.location.host + '/#/register?firstname=' + _this.state.inviteFirstName + '&lastname=' + _this.state.inviteLastName + '&phone=' + _this.state.invitePhone + '&email=' + _this.state.inviteEmail;
      var bodyText = 'Hej ' + _this.state.inviteFirstName + ' ' + _this.state.inviteLastName + '!<br /><br /> Du har blivit inbjuden till transportsedeln av ' + _this.props.company.companyName + '. <br />Om du inte har ett konto klickar du p\xE5 denna l\xE4nk f\xF6r att registrera ett nytt konto:<br /><a href=\'' + link + '\'>' + link + '</a>';
      _firestore.db.collection('mail').add({
        to: _this.state.inviteEmail,
        message: {
          subject: 'Inbjudan till Transportsedeln',
          html: bodyText
        }
      }).then(function () {
        return console.log('Queued email for delivery!');
      });
    }, _this.select = function (index) {
      _this.setState({ current: index });
    }, _this.setField = function (prop, _ref2) {
      var target = _ref2.target;

      if (prop === 'inviteEmail') {
        _this.state[prop] = target.value.toLowerCase();
      } else {
        _this.state[prop] = target.value;
      }
      _this.setState(_this.state);
    }, _this.toggleSendInvitation = function (e) {
      e.preventDefault();
      if (_this.state.inviteCoworkerVisible === false) {
        _this.setState({
          inviteCoworkerVisible: true
        });
      } else {
        _this.setState({
          inviteCoworkerVisible: false
        });
        _this.props.history.push('/settings');
      }
    }, _this.toggleRemoveDialog = function (invite, isAccepted) {
      _this.setState({
        removeInviteDialogVisible: !_this.state.removeInviteDialogVisible
      });
      _this.setState({
        inviteToBeRemoved: invite,
        inviteIsAccepted: isAccepted
      });
    }, _this.rejectInvite = function (invite) {
      _this.props.rejectInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har tagits bort!');
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade när inbjudan skulle tas bort');
      });
    }, _this.cancelInvite = function (invite) {
      _this.props.cancelInvite(invite).then(function () {
        _this.props.history.push('/settings');
        (0, _Toaster.showSuccess)('Inbjudan har blivit avböjd!');
      }).catch(function () {
        (0, _Toaster.showError)('Ett fel inträffade vid avböjning av inbjudan');
      });
    }, _this.sendCoworkerInvitation = function (e) {
      e.preventDefault();
      _this.props.createInvite(_this.props.company, _this.state.inviteEmail, false).then(function () {
        _this.sendMail();
        (0, _Toaster.showSuccess)('En inbjudan har skickats!');
        _this.setState({
          inviteSucceeded: true,
          inviteEmail: '',
          inviteFirstName: '',
          inviteLastName: '',
          invitePhone: ''
        });
      });
    }, _this.getEmployeeName = function (email) {
      if (_this.props.employees === undefined) return email;

      var employee = _this.props.employees.find(function (e) {
        return e.email === email;
      });

      return employee && (employee.firstName || employee.lastName) ? employee.firstName + ' ' + employee.lastName : email;
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(CompanyInfo, [{
    key: 'filterInvites',
    value: function filterInvites(invites) {
      var _this2 = this;

      var filtered = invites.filter(function (item) {
        return item.receiver !== _this2.props.user.email;
      });

      return filtered;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          company = _props.company,
          getInvites = _props.getInvites,
          employees = _props.employees;
      // TODO inline styles

      var removeButtonStyles = { backgroundColor: '#4A5551', color: '#ffffff', float: 'right' };

      var employeeCost;
      if (this.props.noOfEmployees > 1) {
        employeeCost = _react2.default.createElement(
          'span',
          null,
          _react2.default.createElement(
            'li',
            null,
            _react2.default.createElement(
              'span',
              null,
              this.props.noOfEmployees - 1,
              ' x 99 kr'
            ),
            _react2.default.createElement(
              'span',
              null,
              (this.props.noOfEmployees - 1) * 99,
              ' kr/m\xE5n '
            )
          ),
          _react2.default.createElement(
            'li',
            null,
            _react2.default.createElement(
              'span',
              null,
              'Totalt'
            ),
            _react2.default.createElement(
              'span',
              null,
              150 + (this.props.noOfEmployees - 1) * 99,
              ' kr/m\xE5n '
            )
          )
        );
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'F\xF6retagsuppgifter'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'list list--color' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'F\xF6retagsnamn'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.companyName
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Org. nummer'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.orgNumber
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Adress'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.streetAdress
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                { style: { wordBreak: 'normal' } },
                'Postnummer/Ort'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.zipcode
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'Kontaktperson'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'list list--color' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'F\xF6rnamn'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.firstName
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Efternamn'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.lastName
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'Telefon'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.phone
              )
            ),
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                'E-post'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.email
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'Fasta v\xE4rden'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'list list--color' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                '\xD6vertidspris (SEK)'
              ),
              _react2.default.createElement(
                'span',
                null,
                company.overtimePrice ? company.overtimePrice : 0,
                ' kr'
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'a',
            { onClick: this.toggleSendInvitation, className: 'link right' },
            'Bjud in'
          ),
          _react2.default.createElement(
            'h3',
            null,
            'Medarbetare'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'accordion' },
            this.filterInvites(this.props.acceptedInvites) ? this.filterInvites(this.props.acceptedInvites).map(function (item, key) {
              return _react2.default.createElement(
                'li',
                { className: 'accordion__item', key: key },
                _react2.default.createElement('input', { type: 'checkbox', name: 'panel', id: key + 'a' }),
                _react2.default.createElement(
                  'label',
                  { htmlFor: key + 'a' },
                  _react2.default.createElement(
                    'span',
                    null,
                    _this3.getEmployeeName(item.receiver)
                  ),
                  _react2.default.createElement(
                    'span',
                    { className: 'statusFirst' },
                    'Aktiv'
                  )
                ),
                _react2.default.createElement(
                  'span',
                  { className: 'statusSecond' },
                  'Aktiv'
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'accordion__content' },
                  _react2.default.createElement(
                    'p',
                    null,
                    'E-post: ',
                    item.receiver
                  ),
                  _react2.default.createElement(
                    'span',
                    null,
                    _react2.default.createElement(
                      'a',
                      { style: removeButtonStyles, onClick: function onClick() {
                          return _this3.toggleRemoveDialog(item, true);
                        } },
                      'x'
                    )
                  )
                )
              );
            }) : '',
            this.props.pendingInvites ? this.props.pendingInvites.map(function (item, key) {
              return _react2.default.createElement(
                'li',
                { className: 'accordion__item', key: key },
                _react2.default.createElement('input', { type: 'checkbox', name: 'panel', id: key + 'b' }),
                _react2.default.createElement(
                  'label',
                  { htmlFor: key + 'b' },
                  _react2.default.createElement(
                    'span',
                    null,
                    item.receiver
                  ),
                  _react2.default.createElement(
                    'span',
                    { className: 'statusFirst' },
                    'V\xE4ntande'
                  )
                ),
                _react2.default.createElement(
                  'span',
                  { className: 'statusSecond' },
                  'V\xE4ntande'
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'accordion__content' },
                  _react2.default.createElement(
                    'span',
                    null,
                    _react2.default.createElement(
                      'a',
                      { style: removeButtonStyles, onClick: function onClick() {
                          return _this3.toggleRemoveDialog(item, false);
                        } },
                      'x'
                    )
                  )
                )
              );
            }) : ''
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'form-row' },
          _react2.default.createElement(
            'h3',
            null,
            'Priss\xE4ttning'
          ),
          _react2.default.createElement(
            'ul',
            { className: 'list list--color' },
            _react2.default.createElement(
              'li',
              null,
              _react2.default.createElement(
                'span',
                null,
                '1 x 150 kr'
              ),
              _react2.default.createElement(
                'span',
                null,
                '150 kr/m\xE5n '
              )
            ),
            employeeCost
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Bjud in medarbetare',
            visibility: this.state.inviteCoworkerVisible,
            toggle: this.toggleSendInvitation },
          _react2.default.createElement(
            'form',
            { onSubmit: this.sendInvitation },
            _react2.default.createElement(
              'div',
              { className: 'form-row' },
              _react2.default.createElement(
                'div',
                { className: 'form-item form-text' },
                _react2.default.createElement(
                  'label',
                  { htmlFor: 'email' },
                  'E-post'
                ),
                _react2.default.createElement(_Input2.default, {
                  style: { width: '103%' },
                  name: 'email',
                  id: 'email',
                  inputType: 'text',
                  value: this.state.inviteEmail,
                  onChange: function onChange(e) {
                    return _this3.setField('inviteEmail', e);
                  }
                })
              )
            ),
            _react2.default.createElement(
              'div',
              { className: 'modal__btn-right' },
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.SECONDARY,
                text: 'Avbryt',
                onClick: this.toggleSendInvitation
              }),
              _react2.default.createElement(_Button2.default, {
                type: _Button.TYPES.PRIMARY,
                text: 'Skicka inbjudan',
                onClick: this.sendCoworkerInvitation
              })
            )
          )
        ),
        _react2.default.createElement(
          _ModalBox2.default,
          {
            title: 'Radera inbjudan',
            visibility: this.state.removeInviteDialogVisible,
            toggle: this.toggleRemoveDialog
          },
          _react2.default.createElement(
            'p',
            null,
            'Vill du radera inbjudan?'
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal__btn-right' },
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.SECONDARY,
              text: 'Nej',
              onClick: this.toggleRemoveDialog
            }),
            _react2.default.createElement(_Button2.default, {
              type: _Button.TYPES.PRIMARY,
              text: 'Ja, radera inbjudan',
              onClick: function onClick() {
                return _this3.state.inviteIsAccepted ? _this3.cancelInvite(_this3.state.inviteToBeRemoved) : _this3.rejectInvite(_this3.state.inviteToBeRemoved);
              }
            })
          )
        )
      );
    }
  }]);
  return CompanyInfo;
}(_react.Component);

exports.default = CompanyInfo;
module.exports = exports['default'];